import React from "react";
import ReactDOM from "react-dom";
import * as turf from '@turf/turf';
import { getAllShops, getDirection } from "./../api/apiService";
import { removeVieTones, removeSpecName, removeSpecAddress } from "./utils/strConverter";
import { addSpecAddressRule, onlyUnique, aggregateKeyList, aggregateKey } from "./utils/strConverter";
import LoadingSpinner from "./LoadingSpinner";
import Scanner from "./Scanner";
import mapRadarBlue from "./../img/map-radar-blue.png";
import "./index.css";

class ScannerCtrl extends React.Component {
    constructor(props) {
        super(props);        
        this.onClick = this.onClick.bind(this);
        this.initSpinner = this.initSpinner.bind(this);
        this.initGeoLoc = this.initGeoLoc.bind(this);
        this.initMarker = this.initMarker.bind(this);        
        this.handleDirection = this.handleDirection.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
    }

    onClick(e) {
        console.log('clicked');
    }

    initGeoLoc() {
        const map = this.props.map;
        const geo = this.props.geo;
        //const mapResult = this.props.mapResult;
        //const mapSource = this.props.mapSource;
        try {
            map.removeControl(geo);
        }
        catch { }

        map.addControl(geo, 'top-right');

        //try {
        //    map.removeControl(mapResult);
        //}
        //catch { }
        //map.addControl(mapResult, "top-left");

        //this.setState({ isDirectMode: true, });

        // Set an event listener that fires when a geolocate event occurs.       
        //geo.on('geolocate', (e) => {
        //    let lng = e.coords.longitude;
        //    let lat = e.coords.latitude;
        //    //let updatedValue = {};
        //    //updatedValue = { lng: lng, lat: lat };
        //    //this.setState({
        //    //    currentCoords: updatedValue,
        //    //    currentLng: lng,
        //    //    currentLat: lat,
        //    //});

        //    //if (this.state.isDirectMode) {
        //    map.setZoom(13.4);
        //        const srcMarker = this.props.marker;
        //        srcMarker.current.remove();
        //        srcMarker.current.setLngLat([lng, lat]).addTo(map);
        //    //}

        //    var from = [lng, lat];
        //    //var to = [this.state.destLng, this.state.destLat];
        //    //var distance = this.getDistance(from, to);
        //    //console.log(`Distance: ${distance} km`);
        //    var searchRadius = this.makeRadius(from, 1500);
        //    //map.getSource('search-radius').setData(searchRadius);
        //    //var featuresInBuffer = this.spatialJoin(this.state.hightlightResults, searchRadius);
        //    map.getSource('confirmed-points-data').setData(searchRadius);
           
        //});

        //const srcMarker = this.props.marker;
        //const { lng, lat } = map.getCenter();
        //srcMarker.current.remove();
        //srcMarker.current.setLngLat([lng, lat]).addTo(map);
    }

    initMarker(arrLngLat) {
        //const srcMarker = this.props.srcMarkerScan;
        //if (srcMarker)
        //    srcMarker.current.setLngLat([lng, lat]);
        const map = this.props.map;
        const srcMarker = this.props.srcMarker;
        const srcMarkerScan = this.props.srcMarkerScan;
        const selectMarker = this.props.selectMarker;
        if (!arrLngLat) {            
            if (selectMarker)
                selectMarker.remove();
            if (srcMarker)
                srcMarker.remove();

            const { lng, lat } = map.getCenter();
            srcMarkerScan.remove();
            srcMarkerScan.setLngLat([lng, lat]).addTo(map);
        }
        else {
            if (selectMarker)
                selectMarker.remove();
            if (srcMarker)
                srcMarker.remove();

            //TODO: recheck
            srcMarkerScan.remove();
            srcMarkerScan.setLngLat(arrLngLat).addTo(map);

            //Reset Map Layer & Source
            if (map.getLayer("route")) {
                map.removeLayer("route");
            }
            if (map.getSource("route-data")) {
                map.removeSource("route-data");
            }
            map.addSource('route-data', {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': []
                    }
                }
            });
            map.addLayer({
                'id': 'route',
                'type': 'line',
                'source': 'route-data',
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                'paint': {
                    'line-color': '#0A95FF',
                    'line-width': 5,
                    'line-opacity': 0.75
                }
            });
        }
    }

    initSpinner(isDisplayed) {
        const map = this.props.map;
        const spinner = this.props.spinner;
        const { lng, lat } = map.getCenter();
        if (isDisplayed) {
            //const spinnerNode = document.createElement('div');
            //spinnerNode.style.cssText = 'width:0px; height:0px; margin: 0px 0px 0px 0px;border-radius:10px; border:0px solid #ddd; box-shadow: 0px 0px 5px 4px rgba(67,60,67,.16)';
            //spinnerNode.style.zIndex = 1005;
            //ReactDOM.render(<LoadingSpinner style={{
            //    zIndex: 5000, borderRadius: 50, width: 200
            //}} />, spinnerNode);
            //spinner.setLngLat([lng, lat]).setDOMContent(spinnerNode).addTo(map);
            spinner.setLngLat([lng, lat]).addTo(map);
        } else {
            spinner.remove();
        }
    }


    //makeRadius(lngLatArray, radiusInMeters) {
    //    var point = turf.point(lngLatArray);
    //    var options = { units: 'meters' };
    //    var buffered = turf.buffer(point, radiusInMeters, options);
    //    return buffered;
    //}

    //spatialJoin(sourceGeoJSON, filterFeature) {
    //    // Loop through all the features in the source geojson and return the ones that 
    //    // are inside the filter feature (buffered radius) and are confirmed landing sites
    //    //console.log(sourceGeoJSON);
    //    var joined = sourceGeoJSON.features.filter(function (feature) {
    //        //return turf.booleanPointInPolygon(feature, filterFeature) && feature.properties.isConfirmed === 'yes';          
    //        return turf.booleanPointInPolygon(feature, filterFeature);
    //    });
    //    return joined;
    //}

    //handleScanner(name) {
    //    this.fetchShops();
    //    //this.initDirections('');
    //    const gaEventTracker = this.props.useGAEventTracker('Map');
    //    //gaEventTracker(`Shop [${name}]`);
    //    gaEventTracker(`Shop Scanner`);
    //}

    handleSelection(arrLngLat) {
        const map = this.props.map;
        const destMarkerScan = this.props.destMarkerScan;
        destMarkerScan.remove();
        destMarkerScan.setLngLat(arrLngLat).addTo(map);
    }

    handleDirection(isStart, lng, lat) {
        const map = this.props.map;
        // Revert
        const selectMarker = this.props.selectMarker;
        const srcMarker = this.props.srcMarker;
        const srcMarkerScan = this.props.srcMarkerScan;
        const destMarkerScan = this.props.destMarkerScan;
        if (lng === 0 || lat === 0) {
            selectMarker.remove();
            srcMarker.remove();
            srcMarkerScan.remove();
            destMarkerScan.remove();
            return;
        }

        if (!isStart) {
            srcMarker.remove();
            //srcMarkerScan.remove();
            // Remove previous markers        
            //mapMarkers.forEach((marker) => marker.remove());
            //mapMarkers = [];
            selectMarker.remove();
            selectMarker.setLngLat([lng, lat]).addTo(map);

            //if (map.getLayer("search-radius")) {
            //    map.removeLayer("search-radius");
            //}
            //// Reset Map Source
            //if (map.getSource("radius-points-data")) {
            //    map.removeSource("radius-points-data");
            //}

            //map.addSource("radius-points-data", {
            //    type: "geojson",
            //    data: {
            //        type: "FeatureCollection",
            //        features: [],
            //    },
            //});

            //map.addLayer({
            //    id: 'search-radius',
            //    source: "radius-points-data",
            //    //source: {
            //    //    type: 'geojson',
            //    //    data: { "type": "FeatureCollection", "features": [] }
            //    //},
            //    type: 'fill',
            //    paint: {
            //        'fill-color': '#D33B37',
            //        'fill-opacity': 0.1
            //    }
            //});
        }
        else {
            srcMarker.remove();
            srcMarkerScan.remove();
            srcMarker.setLngLat([lng, lat]).addTo(map);
            selectMarker.remove();
        }
    }
    onAdd() {
        //const scannerNode = document.createElement("div");
        this.container = document.createElement("div");
        //this.container.className = 'custom-control';
        //this.map = map;
        ReactDOM.render(<Scanner map={this.props.map} geo={this.props.geo} geoScan={this.props.geoScan} selectMarker={this.props.selectMarker} srcMarker={this.props.srcMarker} srcMarkerScan={this.props.srcMarkerScan} initGeoLoc={this.initGeoLoc} initMarker={this.initMarker} initSpinner={this.initSpinner} curCoords={this.props.currentCoords} spinner={this.props.spinner} popup={this.props.popup} handleRadar={this.props.handleRadar} directionModeRef={this.props.directionModeRef} marker={this.props.currentMarkerRef} geoLocate={this.props.geoLocateRef} handleZoom={this.props.handleZoom} resetSource={this.resetSource} handleDirection={this.handleDirection} handleSelection={this.handleSelection}  handleToggler={this.props.handleToggler} useGAEventTracker={this.props.useGAEventTracker} />, this.container);
        //const resulteNode = document.createElement("div");
        //ReactDOM.render(<ResultCtrl map={this.props.map} geo={this.props.geo} selectMarker={this.props.selectMarker} srcMarker={this.props.srcMarker} initGeoLoc={this.initGeoLoc} initMarker={this.initMarker} initSpinner={this.initSpinner} curCoords={this.props.currentCoords} popup={this.props.popUpRef} handleRadar={this.props.handleRadar} spinner={this.props.spinnerRef} directionModeRef={this.props.directionModeRef} marker={this.props.currentMarkerRef} geoLocate={this.props.geoLocateRef} handleZoom={this.props.handleZoom} resetSource={this.resetSource} handleToggler={this.props.handleToggler} useGAEventTracker={this.props.useGAEventTracker} />, scannerNode);
        return this.container;
    }

    onRemove() {
        this.container.parentNode.removeChild(this.container);
        //this.map = undefined;
    }

    //resetSource(name, data) {
    //    const map = this.props.map;
    //    map.getSource(name).setData(data);
    //}

    //async fetchShops() {
    //    const map = this.props.map;
    //    const spinner = this.props.spinner;
    //    //spinner.current
    //    //    .setLngLat([map.getCenter().lng.toFixed(4), map.getCenter().lat.toFixed(4)])
    //    //    .addTo(map);

    //    //this.setState({
    //    //    isLoading: true
    //    //});

    //    await getAllShops("shops?q=" + 'ha noi')
    //        .then((item) => {
    //            //this.setState({
    //            //    results: item.data,
    //            //    firstLoad: false
    //            //});

    //            //this.drawMap(this.state.query);
    //            this.drawMap(item.data);
    //            //console.log(item.data);
    //            //this.setState({
    //            //    isLoading: false,
    //            //    firstLoad: false
    //            //});

    //            spinner.current.remove();
    //        })
    //        .catch(() => {
    //            //setErrorMessage("Unable to fetch list");
    //            //this.setState({
    //            //    isLoading: false,
    //            //    firstLoad: false
    //            //});
    //            spinner.current.remove();
    //        });
    //}

    //drawMap(data) {
    //    //console.log("Data: " + data);
    //    //this.setState({
    //    //    isLoading: true
    //    //});
    //    const map = this.props.map;
    //    const popup = this.props.popup;
    //    const marker = this.props.marker;
    //    //const spinner = this.props.spinner;
    //    //const results = this.state.results;
    //    const results = data;
    //    //const directions = this.state.directions;
    //    var highlightMarkers, usualgeoJSON, highlightgeoJSON;
    //    popup.current.remove();
    //    marker.current.remove();
    //    //if (popup.current.isOpen()) popup.current.remove();   
    //    //console.log("Data: " + results);
    //    const jsonMarkers = JSON.stringify(data);
    //    //console.log("Data: " + jsonMarkers);

    //    //const jsonDirections = JSON.stringify(directions.routes[0].geometry);
    //    //console.log("Directions: " + jsonDirections);

    //    //if (vendor && vendor.trim() !== "") {
    //    //if (data) {
    //    //    //highlightMarkers = this.getFilterCentersSuggestions(jsonMarkers, vendor);
    //    //    highlightMarkers = this.getSuggestions(jsonMarkers, 'ha noi');
    //    //    //console.log("Return Count: " + highlightMarkers.length);
    //    //    //this.setState({
    //    //    //    query: vendor,
    //    //    //    //results: res.body,              
    //    //    //    firstLoad: false,
    //    //    //    filteredList: highlightMarkers,
    //    //    //});
    //    //    //console.log("Filtered Data: " + filteredData);

    //    //    // highlightMarkers = markers.List.filter(
    //    //    //   (m) =>
    //    //    //     vendor &&
    //    //    //     m?.Address.trim().toLowerCase().includes(vendor.trim().toLowerCase())
    //    //    // );
    //    //    // usualMarkers = markers.filter(
    //    //    //   (m) => m.ShopName.toLowerCase() !== vendor.toLowerCase()
    //    //    //);

    //    //    //usualMarkers = markers;
    //    //    //console.log(usualMarkers);
    //    //} else {
    //    //    //usualMarkers = markers;
    //    //    //console.log(usualMarkers);            
    //    //}

    //    usualgeoJSON = this.genFeatureListFromGeoData(jsonMarkers);
    //    const jsonFilteredMarkers = JSON.stringify(highlightMarkers);
    //    highlightgeoJSON = this.genFeatureListFromGeoList(jsonFilteredMarkers);
    //    //highlightgeoJSON = this.genFeatureListFromGeoData(highlightMarkers);

    //    map.getSource("custom-points-data").setData(usualgeoJSON);
    //    // map.getSource("usual-points-data").setData(usualgeoJSON);
    //    map.getSource("hightlight-points-data").setData(highlightgeoJSON);
    //    //const routeFeature = {
    //    //    'type': 'Feature',
    //    //    'properties': {},
    //    //    'geometry': directions.routes[0].geometry,
    //    //};
    //    //map.getSource("route-data").setData(routeFeature);

    //    map.getSource("route-data").setData({
    //        'type': 'LineString',
    //        'coordinates': []
    //    });

    //    // // clearing layers
    //    // if (map.getLayer("shops")) {
    //    //   map.removeLayer("shops");
    //    // }
    //    // if (map.getSource("shops")) {
    //    //   map.removeSource("shops");
    //    // }
    //    // if (map.getLayer("shops-highlight")) {
    //    //   map.removeLayer("shopss-highlight");
    //    // }
    //    // if (map.getSource("shops-highlight")) {
    //    //   map.removeSource("shops-highlight");
    //    // }

    //    // map
    //    //   .addSource("shops", {
    //    //     type: "geojson",
    //    //     data: usualgeoJSON,
    //    //   })
    //    //   .addLayer({
    //    //     id: "shops",
    //    //     type: "circle",
    //    //     interactive: true,
    //    //     source: "trucks",
    //    //     paint: {
    //    //       "circle-radius": 8,
    //    //       "circle-color": "rgba(253,237,57,1)",
    //    //     },
    //    //   });

    //    // if (highlightMarkers) {
    //    //   map
    //    //     .addSource("shops-highlight", {
    //    //       type: "geojson",
    //    //       data: highlightgeoJSON,
    //    //     })
    //    //     .addLayer({
    //    //       id: "shops-highlight",
    //    //       type: "circle",
    //    //       interactive: true,
    //    //       source: "shops-highlight",
    //    //       paint: {
    //    //         "circle-radius": 8,
    //    //         "circle-color": "rgba(164,65,99,1)",
    //    //       },
    //    //     });
    //    // }

    //    //this.setState({
    //    //    isLoading: false
    //    //});
    //}

    //getSuggestions(jsonMarkers, termsString) {
    //    const markers = JSON.parse(jsonMarkers);
    //    termsString = removeSpecName(termsString.trim().toLowerCase());
    //    var key = addSpecAddressRule(termsString.trim().toLowerCase());
    //    const terms = removeVieTones(key).split(" ");
    //    const aggrFilterList = aggregateKeyList(terms);
    //    //for (let i = 0; i < aggrFilterList.length; i++) {
    //    //    console.log(aggrFilterList[i]);
    //    //}

    //    var data = markers.filter((p) =>
    //        aggrFilterList.every((term) => [
    //            //removeVieTones(p.ShopName).toLowerCase().trim(),
    //            //removeVieTones(p.ShopName).split(" "),
    //            //removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim(),
    //            removeVieTones(p.Province).toLowerCase().trim(),
    //            removeVieTones(p.District).toLowerCase().trim(),
    //            removeVieTones(p.Ward).toLowerCase().trim(),
    //            removeVieTones(p.Ward).toLowerCase().trim() + " " + removeVieTones(p.Province).toLowerCase().trim(),
    //            removeVieTones(p.Street).toLowerCase().trim(),
    //            removeVieTones(p.Street).toLowerCase().trim() + " " + removeVieTones(p.Province).toLowerCase().trim(),
    //            removeVieTones(p.Street).toLowerCase().trim() + " " + removeVieTones(p.District).toLowerCase().trim(),
    //            removeVieTones(p.Street).toLowerCase().trim() + " " + removeVieTones(p.Ward).toLowerCase().trim(),
    //            removeVieTones(p.Address).toLowerCase().trim()
    //            //removeVieTones(removeSpecAddress(p.ShopAddress)).toLowerCase().trim()
    //        ]
    //            .some((f) => removeVieTones(f).toLowerCase().trim().match(term))
    //        ));

    //    //const key1 = removeVieTones(removeSpecName(key));
    //    //console.log(key1);
    //    //const terms1 = removeVieTones(key1).split(" ");
    //    //const aggrFilterList1 = aggregateKeyList(terms1);        

    //    var data2 = markers.filter((p) =>
    //        aggrFilterList.every((term) => [
    //            removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim(),
    //            removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim() + " " + removeVieTones(p.Province).toLowerCase().trim(),
    //            removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim() + " " + removeVieTones(p.District).toLowerCase().trim(),
    //            removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim() + " " + removeVieTones(p.Ward).toLowerCase().trim(),
    //            removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim() + " " + removeVieTones(p.Street).toLowerCase().trim(),
    //            //removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim() + " " + removeVieTones(p.Address).toLowerCase().trim()
    //        ]
    //            .some((f) => removeVieTones(f).toLowerCase().trim().includes(term))
    //        ));

    //    var data3 = markers.filter((p) =>
    //        aggrFilterList.every((term) => [
    //            //removeVieTones(p.Name).toLowerCase().trim(),                
    //            //removeVieTones(removeSpecAddress(p.Address)).toLowerCase().trim(),
    //            removeVieTones(removeSpecAddress(p.ShopAddress)).toLowerCase().trim()
    //        ]
    //            .some((f) => removeVieTones(f).toLowerCase().trim().includes(term))
    //        ));


    //    //var filterdShops = [];
    //    ////var shopsByProvince = markers.filter((p) =>
    //    ////    aggrFilterList.every((key) => [              
    //    ////        removeVieTones(p.Province).toLowerCase().trim()]
    //    ////        .some((item) => removeVieTones(item != null && item.trim() != "" ? item.toLowerCase().trim() : "").match(key))
    //    ////    ));

    //    ////var shopsByProvince = markers.filter((p) =>
    //    ////    aggrFilterList.every((key) => removeVieTones(p.Province != null && p.Province.trim() != "" ? p.Province.toLowerCase().trim() : "").toLowerCase().trim().match(key)));

    //    //var shopsByProvince = markers.filter((p) =>
    //    //    aggrFilterList.every((term) => [
    //    //        removeVieTones(p.Province).toLowerCase().trim()
    //    //    ].some((f) => removeVieTones(f).trim().toLowerCase().match(term))
    //    //    ));

    //    //console.log("Total Count: " + markers.length);
    //    //console.log("Province Count: " + shopsByProvince.length);
    //    //var sJsonProvince = JSON.stringify(shopsByProvince);
    //    ////console.log(sJsonProvince);
    //    //var oJsonProvince = JSON.parse(sJsonProvince);
    //    //var shopsByDistrict = markers.filter((p) =>
    //    //    aggrFilterList.every((term) => [
    //    //        removeVieTones(p.District).toLowerCase().trim()
    //    //    ].some((f) => removeVieTones(f).trim().toLowerCase().match(term))
    //    //    ));
    //    //console.log("District Count: " + shopsByDistrict.length);
    //    //var sJsonDistrict = JSON.stringify(shopsByDistrict);
    //    //var oJsonDistrict = JSON.parse(sJsonDistrict);
    //    //var shopsByWard = markers.filter((p) =>
    //    //    aggrFilterList.every((key) =>
    //    //        removeVieTones(p.Ward != null && p.Ward.trim() != "" ? p.Ward.toLowerCase().trim() : "").toLowerCase().trim().match(key)
    //    //    ));
    //    //console.log("Ward Count: " + shopsByWard.length);
    //    //var sJsonWard = JSON.stringify(shopsByWard);
    //    //var oJsonWard = JSON.parse(sJsonWard);
    //    //var shopsByStreet = markers.filter((p) =>
    //    //    aggrFilterList.every((key) =>
    //    //        removeVieTones(p.Street != null && p.Street.trim() != "" ? p.Street.toLowerCase().trim() : "").toLowerCase().trim().match(key)
    //    //    ));
    //    //console.log("Street Count: " + shopsByStreet.length);
    //    ////var shopsByProvince = markers.filter((p) =>
    //    ////    aggrFilterList.every((key) => [              
    //    ////        removeVieTones(p.Province).toLowerCase().trim()]
    //    ////        .some((item) => removeVieTones(item != null && item.trim() != "" ? item.toLowerCase().trim() : "") === key)
    //    ////    ));

    //    ////var shopsByDistrict = data.filter((p) =>
    //    ////    aggrFilterList.every((key) => [
    //    ////        removeVieTones(p.District).toLowerCase().trim()]
    //    ////        .some((item) => removeVieTones(item.trim().toLowerCase()).equals(key))
    //    ////    ));

    //    ////var shopsByWard = data.filter((p) =>
    //    ////    aggrFilterList.every((key) => [
    //    ////        removeVieTones(p.Ward).toLowerCase().trim()]
    //    ////        .some((item) => removeVieTones(item.trim().toLowerCase()).equals(key))
    //    ////    ));

    //    ////var shopsByStreet = data.filter((p) =>
    //    ////    aggrFilterList.every((key) => [
    //    ////        removeVieTones(p.Street).toLowerCase().trim()]
    //    ////        .some((item) => removeVieTones(item.trim().toLowerCase()).equals(key))
    //    ////    ));

    //    //filterdShops = filterdShops.concat(shopsByStreet, shopsByWard, shopsByDistrict, shopsByProvince );
    //    ////filterdShops = filterdShops.concat(shopsByProvince);

    //    ////const data = markers.filter((p) =>
    //    ////    aggrFilterList.every((term) => [
    //    ////        removeVieTones(p.ShopName).toLowerCase().trim(),
    //    ////        removeVieTones(p.Province).toLowerCase().trim(),
    //    ////        removeVieTones(p.District).toLowerCase().trim(),
    //    ////        removeVieTones(p.Ward).toLowerCase().trim(),
    //    ////        removeVieTones(p.Street).toLowerCase().trim(),
    //    ////        //removeVieTones(removeSpecAddress(p.Address)).toLowerCase().trim()
    //    ////    ]
    //    ////        .some((f) => removeVieTones(f.trim().toLowerCase()).equals(term))
    //    ////    ));

    //    ////const data = markers.filter((p) =>
    //    ////    aggrFilterList.every((term) => [
    //    ////        removeVieTones(p.Province).toLowerCase().trim()
    //    ////    ]
    //    ////        .some((f) => removeVieTones(f.trim().toLowerCase()).includes(term))
    //    ////    ));

    //    ////const data = markers;

    //    ////const dataByWard = markers.filter((p) =>
    //    ////    aggrFilterList.every((term) => [ removeVieTones(p.Ward).toLowerCase().trim()]
    //    ////        .some((f) => removeVieTones(f.trim().toLowerCase()).equals(term))
    //    ////    ));

    //    data = data.concat(data2).concat(data3);
    //    //data = data3.concat(data2).concat(data);
    //    data = data.filter(onlyUnique);
    //    return data;
    //    //return sortData(data, "Province", "asc");
    //    //return sortData(filterdShops, "Province", "asc");
    //    //return filterdShops;
    //}

    //async initDirections(name) {
    //    const map = this.props.map;
    //    const spinner = this.props.spinner;
    //    const geoLocate = this.props.geoLocate;
    //    const srcMarker = this.props.marker;
    //    const results = this.state.results;
    //    //const directions = this.state.directions;
    //    var highlightMarkers;
    //    this.props.handleToggler();

    //    spinner.current
    //        .setLngLat([map.getCenter().lng.toFixed(4), map.getCenter().lat.toFixed(4)])
    //        .addTo(map);

    //    try {
    //        map.removeControl(geoLocate.current);
    //    }
    //    catch { }

    //    map.addControl(geoLocate.current, 'top-right');

    //    this.setState({ isDirectMode: true, });

    //    // Set an event listener that fires when a geolocate event occurs.       
    //    geoLocate.current.on('geolocate', (e) => {
    //        let lng = e.coords.longitude;
    //        let lat = e.coords.latitude;
    //        let updatedValue = {};
    //        updatedValue = { lng: lng, lat: lat };
    //        this.setState({
    //            currentCoords: updatedValue,
    //            currentLng: lng,
    //            currentLat: lat,
    //        });

    //        if (this.state.isDirectMode) {
    //            const srcMarker = this.props.marker;
    //            srcMarker.current.remove();
    //            srcMarker.current.setLngLat([lng, lat]).addTo(map);
    //        }
    //    });

    //    //geoLocate.current.trigger();

    //    this.setState({
    //        isLoading: true
    //    });

    //    const jsonMarkers = JSON.stringify(results);
    //    var _destLng, _destLat = "";
    //    if (name && name.trim() !== "") {
    //        highlightMarkers = this.getSuggestions(jsonMarkers, name);

    //        if (highlightMarkers.length === 1) {
    //            //console.log("* Dest Marker: " + JSON.stringify(highlightMarkers));                
    //        } else {
    //            highlightMarkers = this.getSuggestions(jsonMarkers, name + ' ' + this.state.query);
    //        }

    //        var selectLoc = JSON.parse(JSON.stringify(highlightMarkers));

    //        selectLoc.map((p) => {
    //            _destLng = p.Longitude;
    //            _destLat = p.Latitude;
    //            this.setState({
    //                destLng: p.Longitude,
    //                destLat: p.Latitude,
    //                startLng: p.Longitude,
    //                startLat: p.Latitude,
    //            });
    //        });

    //        //await getDirection(`${_destLng},${_destLat}`, `${_destLng},${_destLat}`)
    //        //    .then((item) => {
    //        //        this.setState({
    //        //            directions: item.data,
    //        //            firstLoad: false,
    //        //            isDirectInit: true,
    //        //        });
    //        //        //this.redrawMap(this.state.query);
    //        //        this.redrawDirections(name);
    //        //        this.setState({
    //        //            isLoading: false,
    //        //            firstLoad: false
    //        //        });

    //        //        //const map = this.props.map;
    //        //        //const spinner = this.props.spinner;
    //        //        spinner.current.remove();
    //        //    })
    //        //    .catch(() => {
    //        //        //setErrorMessage("Unable to fetch list");
    //        //        this.setState({
    //        //            isLoading: false,
    //        //            firstLoad: false
    //        //        });
    //        //    });

    //        this.props.handleDirection(true, map.getCenter().lng.toFixed(4), map.getCenter().lat.toFixed(4));
    //        //const srcMarker = this.props.marker;
    //        //Show direct guideline (popup) 

    //        map.on("moveend", async () => {
    //            if (!map) return;
    //            console.log('moveend');
    //            this.setState({ currentZoom: map.getZoom().toFixed(1) });
    //            //console.log("Current zoom: " + map.getZoom().toFixed(1));                
    //            // Get new center coordinates             
    //            const { lng, lat } = map.getCenter();
    //            const srcMarker = this.props.marker;
    //            srcMarker.current.setLngLat([lng, lat]);
    //            this.setState({
    //                startLng: lng,
    //                startLat: lat,
    //                //isDirectInit: (this.state.startLng === this.state.destLng && this.state.startLat === this.state.destLat)?true:false,
    //            });
    //        });

    //        //map.on("load", () => {
    //        //    if (!map) return;
    //        //    this.addRadarButton(map);
    //        //});

    //        //map.on("resize", async () => {
    //        //    map.setSize(13.5);
    //        //    console.log(13.5);
    //        //});

    //        //radarButton.current.getElement().addEventListener('onClick', (e) => {
    //        //    const centerPosition = {
    //        //        center: [map.getCenter().lng.toFixed(4), map.getCenter().lat.toFixed(4)],
    //        //    };
    //        //    map.flyTo(centerPosition);
    //        //});


    //        srcMarker.current.getElement().addEventListener('click', (e) => {
    //            let isDirInit = (this.state.startLng === this.state.destLng && this.state.startLat === this.state.destLat) ? true : false;
    //            if (isDirInit) {
    //                return;
    //            }
    //            this.setState({ isDirectPoint: true });

    //            this.props.handleDirection(false, this.state.startLng, this.state.startLat);

    //            //getDirection(`${this.state.startLng},${this.state.startLat}`, `${this.state.destLng},${this.state.destLat}`)
    //            //    .then((item) => {
    //            //        this.setState({
    //            //            directions: item.data,
    //            //            firstLoad: false,
    //            //        });
    //            //        //this.redrawMap(this.state.query);
    //            //        this.redrawDirections(name);
    //            //        //this.setMapCenter([this.state.startLng, this.state.startLat]);
    //            //        //this.props.handleZoom(this.state.currentZoom);
    //            //        this.setState({
    //            //            isLoading: false,
    //            //            firstLoad: false
    //            //        });

    //            //        //const map = this.props.map;
    //            //        //const spinner = this.props.spinner;
    //            //        spinner.current.remove();
    //            //        //srcMarker.current.setLngLat([map.getCenter().lng.toFixed(4), map.getCenter().lat.toFixed(4)]);
    //            //    })
    //            //    .catch(() => {
    //            //        //setErrorMessage("Unable to fetch list");
    //            //        this.setState({
    //            //            isLoading: false,
    //            //            firstLoad: false,
    //            //        });
    //            //    });

    //            this.setState({
    //                isDirectMode: false,
    //            });
    //        });

    //        this.setState({
    //            isLoading: false,
    //            firstLoad: false
    //        });

    //        //const map = this.props.map;
    //        //const spinner = this.props.spinner;
    //        spinner.current.remove();
    //    } else {
    //        // do nothing
    //    }
    //}

}

export default ScannerCtrl;