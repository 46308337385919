import React from "react";
import zalo from "./../img/zalo-icon-blue.png";
import phone from "./../img/phone-icon-blue.png";
import directions from "./../img/directions-icon-blue.png";
///import banner1 from "./../img/shops-banner-bg12.png";
//import banner2 from "./../img/flower-shop-square.png";
//import banner from "./../img/shops-banner-bg.jpg";

export default class Shop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false,
        };

        this.toggleExpand = this.toggleExpand.bind(this);
    }

    formatItems(items) {
        if (this.state.isExpanded) {
            return items.join(", ");
        }
        const summary = items.join(", ").substr(0, 80);
        if (summary.length > 70) {
            const indexOfLastSpace =
                summary.split("").reverse().join("").indexOf(",") + 1;
            return summary.substr(0, 80 - indexOfLastSpace) + " & more...";
        }
        return summary;
    }

    toggleExpand() {
        this.setState({
            isExpanded: !this.state.isExpanded,
        });
    }

    render() {
        const { ShopName, DisplayName, DisplayAddress, PhoneNumber, Banner } = this.props.data;
        let zaloCall = "https://zalo.me/" + PhoneNumber.split(/\s/).join('');
        let phoneCall = "tel:" + PhoneNumber.split(/\s/).join('');
        let urlBanner = Banner ? "https://vinflower.com/wp-content/uploads/iflower/images/banners/" + Banner : "https://vinflower.com/wp-content/uploads/2023/06/5539763.png";

        return (
            <li style={{ padding: "5px 5px", fontFamily: "Tahoma", color: "#FFF" }}>
                <div id="li-container">
                    <div id="container" onClick={this.props.handleClickedItem.bind(null, ShopName)}>
                        {/*<div style={{ clear: "both", padding: "5px 5px 5px 10px", color: "#0068FF", fontSize: "12pt" }}>{DisplayName} </div>*/}
                        {/*{this.props.data['name'] }*/}
                        <div style={{ alignContent: "center", textAlign: "center", height: "250px" }}>
                            <div style={{ alignContent: "center", textAlign: "center", height: "250px", backgroundColor: "#FFF" }}>                                
                                {/*<img src={banner2} alt="Banner" width="95%" style={{ margin: "8px 0px 5px 0px", alignContent: "center" }} />*/}
                                <img src={urlBanner} alt="Banner" width="95%" style={{ margin: "8px 0px 5px 0px", alignContent: "center" }} />
                            </div>
                        </div>
                        <div className="shopTitleBG" style={{ clear: "both", height: '75px', padding: "0px 5px 0px 0px", backgroundColor: "cornsilk", opacity: '0.8', fontSize: "9pt" }}></div>
                        <div className="shopTitle" style={{ clear: "both", padding: "5px 5px 5px 5px", color: "#514925", fontSize: "11pt", fontWeight: "bold", margin: "0px 0px 5px 0px", float: "left" }}>{DisplayName}</div>                        
                        <div className="shopAddr" style={{ clear: "both", padding: "5px 10px 5px 5px", color: "#514925", fontSize: "9pt" }}>{DisplayAddress}</div>


                        {/*<a href={phoneCall} style={{ clear: "both", padding: "0px 0px 0px 0px", color: "#545454" }}>*/}
                        {/*        <div style={{ clear: "both", padding: "5px 5px 0px 10px", color: "#0068FF", margin: "-32px 0px 5px 25px", fontSize: "10pt", float: "left" }}>Gọi ngay</div>*/}
                        {/*</a>*/}
                        {/*    <div style={{ clear: "both", padding: "10px 45px 0px 0px", color: "#514925" }}> {DisplayAddress}</div>*/}
                        {/*<div style={{ clear: "both", padding: "0px 0px 5px 0px", color: "#545454" }}>*/}
                        {/*    <a>*/}
                        {/*            <img className="iconAct" src={directions} onClick={this.props.handleDirections.bind(null, ShopName)} alt="Directions" style={{ padding: "0px 0px", margin: "-32px 0px 5px 0px", float: "right" }} />*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                    </div>
                    <div id="icon-container">
                        <img className="iconAct" src={directions} onClick={this.props.handleDirections.bind(null, ShopName)} alt="Directions" 
                            style={{ margin: "7px 0px 5px 20px", float: "left" }} />
                        <a href={zaloCall}>
                            <img className="iconAct" src={zalo} alt="Phone" style={{ margin: "7px 20px 5px 0px", float: "right" }} />
                        </a>
                        <a href={phoneCall}>
                            <img className="iconAct" src={phone} alt="Zalo" style={{ margin: "7px 40px 5px 0px", float: "right" }} />
                        </a>
                    </div>
                </div>
            </li>
        );
    }
}
