import React from "react";
import zalo from "./../img/zalo-icon-blue.png";
import phone from "./../img/phone-icon-blue.png";

const Popup = ({ feature }) => {
    const { id, displayName, displayAddress, phoneNumber, banner } = feature.properties;
    let zaloCall = "https://zalo.me/" + phoneNumber.split(/\s/).join('');
    let phoneCall = "tel:" + phoneNumber.split(/\s/).join('');
    let urlBanner = banner ? "https://vinflower.com/wp-content/uploads/iflower/images/banners/" + banner : "https://vinflower.com/wp-content/uploads/2023/06/5539763.png";

    return (
        <li style={{ padding: "5px 5px", fontFamily: "Tahoma", color: "#FFF" }}>
            <div id="li-container">
                <div id="container">
                    <div className="shopTitleBG" style={{ clear: "both", height: '75px', padding: "0px 5px 0px 0px", backgroundColor: "cornsilk", opacity: '0.8', fontSize: "9pt" }}></div>
                    <div className="shopTitle" style={{ clear: "both", padding: "5px 5px 5px 5px", color: "#514925", fontSize: "11pt", fontWeight: "bold", margin: "0px 0px 5px 0px", float: "left" }}>{displayName}</div>
                    <div className="shopAddr" style={{ clear: "both", padding: "5px 10px 5px 5px", color: "#514925", fontSize: "9pt" }}> {displayAddress}</div>
                    <div style={{ alignContent: "center", textAlign: "center", height: "250px" }}>
                        <div style={{ alignContent: "center", textAlign: "center", height: "250px", backgroundColor: "#FFF" }}>
                            {/*<img src={banner2} alt="Banner" width="95%" style={{ margin: "8px 0px 5px 0px", alignContent: "center" }} />*/}
                            <img src={urlBanner} alt="Banner" width="95%" style={{ margin: "8px 0px 5px 0px", alignContent: "center" }} />
                        </div>
                    </div>
                </div>
                <div id="icon-container">
                    {/*<img className="iconAct" src={directions} onClick={this.props.handleDirections.bind(null, name, arrLngLat)} alt="Directions"*/}
                    {/*    style={{ margin: "7px 0px 5px 20px", float: "left" }} />*/}
                    <a href={zaloCall}>
                        <img className="iconAct" src={zalo} alt="Phone" style={{ margin: "7px 20px 5px 0px", float: "right" }} />
                    </a>
                    <a href={phoneCall}>
                        <img className="iconAct" src={phone} alt="Zalo" style={{ margin: "7px 40px 5px 0px", float: "right" }} />
                    </a>
                </div>
            </div>
        </li>

          //OLD
          //<div id={`popup-${id}`} style={{ padding: "5px 5px 5px 10px" }}>  
          //    <div style={{ clear: "both", padding: "5px 5px 5px 0px", color: "#2296F4", fontSize: "13pt", fontWeight: "bold", }}>{displayName} </div>          
          //    <div style={{ clear: "both", padding: "10px 5px 0px 0px", color: "#545454", fontFamily: "Tahoma", fontSize: "10pt", fontWeight: "bold" }}> Liên hệ ngay với Shop</div>
          //    <a href={phoneCall} className="watermark">
          //            <img src={phone} alt="Phone" width="25px" style={{ padding: "0px 0px", margin: "-23px 5px 5px 0px", float: "right" }} />
          //        </a>
          //    <div style={{ clear: "both", padding: "10px 45px 0px 0px", color: "#545454", fontSize: "10.5pt" }}> {displayAddress}</div>
          //        <a href={zaloCall} className="watermark">
          //            <img src={zalo} alt="Zalo logo" width="36px" style={{ padding: "0px 0px", margin: "-40px 5px 5px 5px", float: "right" }} />
          //        </a>
          //</div>
      );
};

export default Popup;
