
import ReactGA from "react-ga";

const useGAEventTracker = (category="Map") => {
  const eventTracker = (action = "Search Action", label = "Search Location") => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}

export default useGAEventTracker;



