import axios from "axios";
import instance from './apiRequestIntance';
import { getToken, refreshToken } from './apiRequestIntance';

//TODO: Remove
const API_URL = "http://localhost:64191/api/";
const API_JWT = "";

export async function callService(endpoint, method) {    
    try {
        return await instance({
            url: endpoint,
            method: method,
            headers: {
                ContentType: 'application/json;charset=utf-8',
                //Authorization: `Bearer ${SIX_APP_API_JWT}`,
                Timeout: 5000,
            },
        })
        //.then((res) => {
        //// handle success
        //console.log(res);
        //})
        //.catch((e) => {
        //    // handle error
        //    console.log("App Message: CallGetApiInstance - " + e);
        //    isSuccessed = false;
        //});
    } catch (e) {
        // handle error      
        console.log("App Message: [401] Access Denied - Refresh Token...");
    }
}

export async function callGetApiInstance(endpoint, method) {
    let SIX_APP_API_JWT = "";
    try {
        SIX_APP_API_JWT = localStorage.getItem('token');
        if (SIX_APP_API_JWT === null || SIX_APP_API_JWT === "") {
            const response = await getToken();
            SIX_APP_API_JWT = "";
            if (response.status === 200) {
                SIX_APP_API_JWT = response.data;
                localStorage.setItem('token', SIX_APP_API_JWT);
            }
            else {
                console.log("App Message: Cannot get Token from server");
                localStorage.removeItem('token', SIX_APP_API_JWT);
            }
        }
    } catch (e) {
        console.log("App Message: Cannot get Token from storage");
        localStorage.removeItem('token', SIX_APP_API_JWT);
    }
    try {        
        return await instance({            
            url: endpoint,
            method: method,
            headers: {
                ContentType: 'application/json;charset=utf-8',
                Authorization: `Bearer ${SIX_APP_API_JWT}`,
                Timeout: 5000,
            },
        })
        //.then((res) => {
            //// handle success
            //console.log(res);
        //})
        //.catch((e) => {
        //    // handle error
        //    console.log("App Message: CallGetApiInstance - " + e);
        //    isSuccessed = false;
        //});
    } catch (e) {        
        // handle error      
        console.log("App Message: [401] Access Denied - Refresh Token...");
        try {
            const response = await refreshToken();
            SIX_APP_API_JWT = "";
            if (response.status === 200) {
                SIX_APP_API_JWT = response.data;
                localStorage.setItem('token', SIX_APP_API_JWT);
            }
            return await instance({
                url: endpoint,
                method: method,
                headers: {
                    ContentType: 'application/json;charset=utf-8',
                    Authorization: `Bearer ${SIX_APP_API_JWT}`,
                    Timeout: 5000,
                },
            })
        }
        catch (e) {
            console.log("App Message: [400] Bad Request - Reget Token...");
            const response = await getToken();
            SIX_APP_API_JWT = "";
            if (response.status === 200) {
                SIX_APP_API_JWT = response.data;
                localStorage.setItem('token', SIX_APP_API_JWT);
            }
            return await instance({
                url: endpoint,
                method: method,
                headers: {
                    ContentType: 'application/json;charset=utf-8',
                    Authorization: `Bearer ${SIX_APP_API_JWT}`,
                    Timeout: 5000,
                },
            })
        }
    }
}

export async function callPayloadApiInstance(endpoint, method, payload) {
    let SIX_APP_API_JWT = "";
    try {
        SIX_APP_API_JWT = localStorage.getItem('token');
        if (SIX_APP_API_JWT === null || SIX_APP_API_JWT === "") {
            const response = await getToken();
            SIX_APP_API_JWT = "";
            if (response.status === 200) {
                SIX_APP_API_JWT = response.data;
                localStorage.setItem('token', SIX_APP_API_JWT);
            }
            else {
                console.log("App Message: Cannot get Token from server");
                localStorage.removeItem('token', SIX_APP_API_JWT);
            }
        }
    } catch (e) {
        console.log("App Message: Cannot get Token from storage");
        localStorage.removeItem('token', SIX_APP_API_JWT);
    }

    try {   
        return await instance({          
            url: endpoint,
            method: method,
            headers: {
                ContentType: 'application/json;charset=utf-8',
                Authorization: `Bearer ${SIX_APP_API_JWT}`,
                Timeout: 5000,
            },
            data: payload,
        })
        //.then((res) => {
            //// handle success
            //console.log(res);
        //})
        //.catch((e) => {
        //    // handle error
        //    console.log("App Message: CallPayloadApiInstance - " + e);
        //});
    } catch (e) {
        // handle error
        console.log("App Message: [401] Access Denied - Refresh Token...");
        try {
            const response = await refreshToken();
            SIX_APP_API_JWT = "";
            if (response.status === 200) {
                SIX_APP_API_JWT = response.data;
                localStorage.setItem('token', SIX_APP_API_JWT);
            }
            return await instance({
                url: endpoint,
                method: method,
                headers: {
                    ContentType: 'application/json;charset=utf-8',
                    Authorization: `Bearer ${SIX_APP_API_JWT}`,
                    Timeout: 5000,
                },
                data: payload,
            })
        }
        catch (e) {
            console.log("App Message: [401] Bad Request - Reget Token...");
            const response = await getToken();
            SIX_APP_API_JWT = "";
            if (response.status === 200) {
                SIX_APP_API_JWT = response.data;
                localStorage.setItem('token', SIX_APP_API_JWT);
            }
            return await instance({
                url: endpoint,
                method: method,
                headers: {
                    ContentType: 'application/json;charset=utf-8',
                    Authorization: `Bearer ${SIX_APP_API_JWT}`,
                    Timeout: 5000,
                },
                data: payload,
            })
        }
    }
}

export async function getAllShops(endpoint) {
    //return callGetApi(endpoint);   
    return await callGetApiInstance(endpoint, "GET");
}

export async function getDirection(start, dest) {   
    //const start = '105.8087552,21.0265046';
    //const start = '105.8087552,21.0265046';
    //return callGetApi(endpoint);   
    //const endpoint = `https://api.mapbox.com/directions/v5/mapbox/driving/105.8087552,21.0265046;105.8087552,21.0265046?access_token=${mapboxgl.accessToken}&geometries=geojson`;
    const endpoint = `${process.env.REACT_APP_MAP_API_URL}${start};${dest}?access_token=${process.env.REACT_APP_MAP_ACCESS_TOKEN}&geometries=geojson`;
    return await callService(endpoint, "GET");
}


export async function getShopsById(endpoint, id) {
    //return callApi(endpoint + "/" + id, "GET");
    //return callGetApi(endpoint + "/" + id);
    return await callGetApiInstance(endpoint + "/" + id, "GET");
}

export async function getShopsByQuery(endpoint, query) {
    //return callApi(endpoint + "?q=" + query, "GET");
    //return callGetApi(endpoint + "?q=" + query);
    return await callGetApiInstance(endpoint + "?q=" + query, "GET");
}

export async function addShops(endpoint, data) {
    //return callApi(endpoint, "POST", data);
    return await callPayloadApiInstance(endpoint, "POST", data);
}

export async function editShop(endpoint, data) {
    //return callApi(endpoint, "PUT", data);
    return await callPayloadApiInstance(endpoint, "PUT", data);
}

export async function deleteShop(endpoint, data) {
    //return callApi(endpoint, "DELETE");
    return await callPayloadApiInstance(endpoint, "DELETE", data);
}

export async function getAllCategories(endpoint) {
    //return callApi(endpoint, "GET");
    //return callGetApi(endpoint, "GET");
    return await callGetApiInstance(endpoint, "GET");
}


//______________________________
export function callApi(endpoint, method = "GET", body) {
    return axios({
        method,
        url: `${API_URL}/${endpoint}`,
        data: body,
        headers: {
            ContentType: 'application/json;charset=utf-8',
            Authorization: `Bearer ${API_JWT}`,
        }
    }).catch((e) => {
        console.log(e);
    });
}

export function callGetApi(endpoint) {
    return axios({
        method: 'get',
        url: `${API_URL}/${endpoint}`,
        headers: {
            ContentType: 'application/json;charset=utf-8',
            Authorization: `Bearer ${API_JWT}`,
        }
    }).catch((e) => {
        console.log(e);
    });
}

export function callPostApi(endpoint, payload) {
    return axios({
        method: 'post',
        url: `${API_URL}/${endpoint}`,
        data: payload,
        headers: {
            ContentType: 'application/json;charset=utf-8',
            Authorization: `Bearer ${API_JWT}`,
        }
    }).catch((e) => {
        console.log(e);
    });
}

export function callPutApi(endpoint, payload) {
    return axios({
        method: 'put',
        url: `${API_URL}/${endpoint}`,
        data: payload,
        headers: {
            ContentType: 'application/json;charset=utf-8',
            Authorization: `Bearer ${API_JWT}`,
        }
    }).catch((e) => {
        console.log(e);
    });
}

export function callDeleteApi(endpoint, payload) {
    return axios({
        method: 'delete',
        url: `${API_URL}/${endpoint}`,
        data: payload,
        headers: {
            ContentType: 'application/json;charset=utf-8',
            Authorization: `Bearer ${API_JWT}`,
        }
    }).catch((e) => {
        console.log(e);
    });
}


//export function getData(endpoint, method = "GET", body) {
//    axios.defaults.baseURL = API_URL;
//    axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
//    //axios.defaults.headers.post['Authorization'] = "Bear abc"
//    //axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
//    axios.get(endpoint, onSuccess, onFailure)
//        .then(resp => {
//            let result = resp.data;
//            return onSuccess(result);
//        })
//        .catch(error => {
//            if (onFailure) {
//                return onFailure(error);
//            }
//        })
//    }
//}

