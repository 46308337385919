
export function removeVieTones(str) {
    if (str === null || str.trim() === "") {        
        return "";
    }
        
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, " ");
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
    return str;
}

export function removeSpecName(str)
{
    if (str === null || str.trim() === "") return "";
    var originalString = str.toLowerCase();
    //var arr = ["Shop hoa", "Tiệm hoa", "Cửa hàng hoa", "Shop", "Tiệm", "Cửa hàng", "Hoa tươi", "Hoa", "Tươi", "Flowers", "Flower"];
    var arr = ["Shop", "Tiệm", "Cửa hàng", "Tiem", "Cua hang", "Hoa tươi", "Hoa tuoi"];
    arr.map(item => {       
        //originalString = originalString.replace(removeVieTones(item).toLowerCase(), '').trim();
        originalString = originalString.replace(item.toLowerCase(), '').trim();
    });
    return originalString;
}

export function removeSpecAddress(str) {
    var originalString = str.toLowerCase();
    var arr = ["Nối dài", "Đường nối", "Điểm nối"];
    arr.map(item => {
        originalString = originalString.replace(removeVieTones(item).toLowerCase(), '');
    });
    return originalString;
}

export function addSpecAddressRule(str) {
    if (str === null || str.trim() === "") return "";
    var originalString = str.toLowerCase();
    if (originalString.includes("hcm") || originalString.includes("hochiminh")) {
        originalString = originalString.replace("hcm", "ho chi minh").replace("hochiminh", "ho chi minh");
    }

    if (originalString.includes("saigon") || originalString.includes("sai gon")) {
        originalString = originalString.replace("saigon", "ho chi minh").replace("sai gon", "ho chi minh");
    }

    if (originalString.includes("hn") || originalString.includes("hanoi")) {
        originalString = originalString.replace("hn", "ha noi").replace("hanoi", "ha noi");
    }   

    if (originalString.includes("tdm")) {
        originalString = originalString.replace("tdm", "thu dau mot");
    }

    //Dist_______________
    if (originalString.includes("q1") || originalString.includes("q 1") || originalString.includes("q.1") || originalString.includes("q. 1")) {
        originalString = originalString.replace("q1", "quan 1").replace("q 1", "quan 1").replace("q.1", "quan 1").replace("q. 1", "quan 1");
    }
    if (originalString.includes("q2") || originalString.includes("q 2") || originalString.includes("q.2") || originalString.includes("q. 2")) {
        originalString = originalString.replace("q2", "quan 2").replace("q 2", "quan 2").replace("q.2", "quan 2").replace("q. 1", "quan 2");
    }
    if (originalString.includes("q3") || originalString.includes("q 3") || originalString.includes("q.3") || originalString.includes("q. 3")) {
        originalString = originalString.replace("q3", "quan 3").replace("q 3", "quan 3").replace("q.3", "quan 3").replace("q. 1", "quan 3");
    }
    if (originalString.includes("q4") || originalString.includes("q 4") || originalString.includes("q.4") || originalString.includes("q. 4")) {
        originalString = originalString.replace("q4", "quan 4").replace("q 4", "quan 4").replace("q.4", "quan 4").replace("q. 4", "quan 4");
    }
    if (originalString.includes("q5") || originalString.includes("q 5") || originalString.includes("q.5") || originalString.includes("q. 5")) {
        originalString = originalString.replace("q5", "quan 5").replace("q 5", "quan 5").replace("q.5", "quan 5").replace("q. 5", "quan 5");
    }
    if (originalString.includes("q6") || originalString.includes("q 6") || originalString.includes("q.6") || originalString.includes("q. 6")) {
        originalString = originalString.replace("q6", "quan 6").replace("q 6", "quan 6").replace("q.6", "quan 6").replace("q. 6", "quan 6");
    }
    if (originalString.includes("q7") || originalString.includes("q 7") || originalString.includes("q.7") || originalString.includes("q. 7")) {
        originalString = originalString.replace("q7", "quan 7").replace("q 7", "quan 7").replace("q.7", "quan 7").replace("q. 7", "quan 7");
    }
    if (originalString.includes("q8") || originalString.includes("q 8") || originalString.includes("q.8") || originalString.includes("q. 8")) {
        originalString = originalString.replace("q8", "quan 8").replace("q 8", "quan 8").replace("q.8", "quan 8").replace("q. 8", "quan 8");
    }
    if (originalString.includes("q9") || originalString.includes("q 9") || originalString.includes("q.9") || originalString.includes("q. 9")) {
        originalString = originalString.replace("q9", "quan 9").replace("q 9", "quan 9").replace("q.9", "quan 9").replace("q. 9", "quan 9");
    }
    if (originalString.includes("q10") || originalString.includes("q 10") || originalString.includes("q.10") || originalString.includes("q. 10")) {
        originalString = originalString.replace("q10", "quan 10").replace("q 10", "quan 10").replace("q.10", "quan 10").replace("q. 10", "quan 10");
    }
    if (originalString.includes("q11") || originalString.includes("q 11") || originalString.includes("q.11") || originalString.includes("q. 11")) {
        originalString = originalString.replace("q11", "quan 11").replace("q 11", "quan 11").replace("q.11", "quan 11").replace("q. 11", "quan 11");
    }
    if (originalString.includes("q12") || originalString.includes("q 12") || originalString.includes("q.12") || originalString.includes("q. 12")) {
        originalString = originalString.replace("q12", "quan 12").replace("q 12", "quan 12").replace("q.12", "quan 12").replace("q. 12", "quan 12");
    }

    //Ward__________
    if (originalString.includes("p1") || originalString.includes("p 1") || originalString.includes("p.1") || originalString.includes("p. 1")) {
        originalString = originalString.replace("p1", "phuong 1").replace("p 1", "phuong 1").replace("p.1", "phuong 1").replace("p. 1", "phuong 1");
    }
    if (originalString.includes("p2") || originalString.includes("p 2") || originalString.includes("p.2") || originalString.includes("p. 2")) {
        originalString = originalString.replace("p2", "phuong 2").replace("p 2", "phuong 2").replace("p.2", "phuong 2").replace("p. 1", "phuong 2");
    }
    if (originalString.includes("p3") || originalString.includes("p 3") || originalString.includes("p.3") || originalString.includes("p. 3")) {
        originalString = originalString.replace("p3", "phuong 3").replace("p 3", "phuong 3").replace("p.3", "phuong 3").replace("p. 1", "phuong 3");
    }
    if (originalString.includes("p4") || originalString.includes("p 4") || originalString.includes("p.4") || originalString.includes("p. 4")) {
        originalString = originalString.replace("p4", "phuong 4").replace("p 4", "phuong 4").replace("p.4", "phuong 4").replace("p. 4", "phuong 4");
    }
    if (originalString.includes("p5") || originalString.includes("p 5") || originalString.includes("p.5") || originalString.includes("p. 5")) {
        originalString = originalString.replace("p5", "phuong 5").replace("p 5", "phuong 5").replace("p.5", "phuong 5").replace("p. 5", "phuong 5");
    }
    if (originalString.includes("p6") || originalString.includes("p 6") || originalString.includes("p.6") || originalString.includes("p. 6")) {
        originalString = originalString.replace("p6", "phuong 6").replace("p 6", "phuong 6").replace("p.6", "phuong 6").replace("p. 6", "phuong 6");
    }
    if (originalString.includes("p7") || originalString.includes("p 7") || originalString.includes("p.7") || originalString.includes("p. 7")) {
        originalString = originalString.replace("p7", "phuong 7").replace("p 7", "phuong 7").replace("p.7", "phuong 7").replace("p. 7", "phuong 7");
    }
    if (originalString.includes("p8") || originalString.includes("p 8") || originalString.includes("p.8") || originalString.includes("p. 8")) {
        originalString = originalString.replace("p8", "phuong 8").replace("p 8", "phuong 8").replace("p.8", "phuong 8").replace("p. 8", "phuong 8");
    }
    if (originalString.includes("p9") || originalString.includes("p 9") || originalString.includes("p.9") || originalString.includes("p. 9")) {
        originalString = originalString.replace("p9", "phuong 9").replace("p 9", "phuong 9").replace("p.9", "phuong 9").replace("p. 9", "phuong 9");
    }
    if (originalString.includes("p10") || originalString.includes("p 10") || originalString.includes("p.10") || originalString.includes("p. 10")) {
        originalString = originalString.replace("p10", "phuong 10").replace("p 10", "phuong 10").replace("p.10", "phuong 10").replace("p. 10", "puan 10");
    }
    if (originalString.includes("p11") || originalString.includes("p 11") || originalString.includes("p.11") || originalString.includes("p. 11")) {
        originalString = originalString.replace("p11", "phuong 11").replace("p 11", "phuong 11").replace("p.11", "phuong 11").replace("p. 11", "phuong 11");
    }
    if (originalString.includes("p12") || originalString.includes("p 12") || originalString.includes("p.12") || originalString.includes("p. 12")) {
        originalString = originalString.replace("p12", "phuong 12").replace("p 12", "phuong 12").replace("p.12", "phuong 12").replace("p. 12", "phuong 12");
    }
    if (originalString.includes("p13") || originalString.includes("p 13") || originalString.includes("p.13") || originalString.includes("p. 13")) {
        originalString = originalString.replace("p13", "phuong 13").replace("p 13", "phuong 13").replace("p.13", "phuong 13").replace("p. 13", "phuong 13");
    }
    if (originalString.includes("p14") || originalString.includes("p 14") || originalString.includes("p.14") || originalString.includes("p. 14")) {
        originalString = originalString.replace("p14", "phuong 14").replace("p 14", "phuong 14").replace("p.14", "phuong 14").replace("p. 14", "phuong 14");
    }
    if (originalString.includes("p15") || originalString.includes("p 15") || originalString.includes("p.15") || originalString.includes("p. 51")) {
        originalString = originalString.replace("p15", "phuong 15").replace("p 1", "phuong 15").replace("p.15", "phuong 15").replace("p. 15", "phuong 15");
    }
    if (originalString.includes("p16") || originalString.includes("p 16") || originalString.includes("p.16") || originalString.includes("p. 16")) {
        originalString = originalString.replace("p16", "phuong 16").replace("p 16", "phuong 16").replace("p.16", "phuong 16").replace("p. 16", "phuong 16");
    }
    if (originalString.includes("p17") || originalString.includes("p 17") || originalString.includes("p.17") || originalString.includes("p. 17")) {
        originalString = originalString.replace("p17", "phuong 17").replace("p 17", "phuong 17").replace("p.17", "phuong 17").replace("p. 1", "phuong 17");
    }
    if (originalString.includes("p18") || originalString.includes("p 18") || originalString.includes("p.18") || originalString.includes("p. 18")) {
        originalString = originalString.replace("p18", "phuong 18").replace("p 18", "phuong 18").replace("p.18", "phuong 18").replace("p. 18", "phuong 18");
    }
    if (originalString.includes("p19") || originalString.includes("p 19") || originalString.includes("p.19") || originalString.includes("p. 19")) {
        originalString = originalString.replace("p19", "phuong 19").replace("p 19", "phuong 19").replace("p.19", "phuong 19").replace("p. 19", "phuong 19");
    }
    return originalString;
}

export function aggregateKeyList(queryList)
{
    //List<string> queryList = new List<string>() { "ABC", "DEF", "GHI" };            
    var aggregateKeyList = [];
    //for (int i = 0; i <= queryList.Count; i++)
    //{
    //    for (int j = 1; j <= queryList.Count - i; j++)
    //    {
    //        var concatRange = string.Join(" ", queryList.GetRange(i, j));
    //        aggregateKeyList.Add(concatRange);
    //    }
    //}

    //for (let i = 0; i < queryList.length; i++) {
    //    //console.log(queryList[i]);
    //    for (let j = 1; j <= queryList.length - i; j++)
    //    {
    //        //var concatRange = string.Join(" ", queryList.GetRange(i, j));
    //        var getRange = queryList.slice(i, j);
    //        //const concatRange = ["ABC", "DEF", "123"].filter(element => element).join(' ');
    //        const concatRange = getRange.filter(element => element).join(' ');
    //        //var concatRange = string.Join(" ", queryList.GetRange(i, j));
    //        aggregateKeyList = aggregateKeyList.concat(concatRange);
    //    }
    //}

    for (let i = 0; i < queryList.length; i++) {
        //console.log(queryList[i]);
        for (let j = 1; j <= queryList.length; j++) {
            //var concatRange = string.Join(" ", queryList.GetRange(i, j));
            var getRange = queryList.slice(i, j);
            //const concatRange = ["ABC", "DEF", "123"].filter(element => element).join(' ');
            const concatRange = getRange.filter(element => element).join(' ');
            //var concatRange = string.Join(" ", queryList.GetRange(i, j));
            aggregateKeyList = aggregateKeyList.concat(concatRange);
        }
    }

    aggregateKeyList = aggregateKeyList.filter(function (e) { return e });
    return aggregateKeyList;
}

export function aggregateKey(query) {
    //List<string> queryList = new List<string>() { "ABC", "DEF", "GHI" };            
    var aggregateKeyList = [];  
    var queryList = query.split(",");
    for (let i = 0; i < queryList.length; i++) {
        //console.log(queryList[i]);
        for (let j = 1; j <= queryList.length; j++) {           
            var getRange = queryList.slice(i, j);
            const concatRange = getRange.filter(element => element).join(' ');
            aggregateKeyList = aggregateKeyList.concat(concatRange);
        }
    }

    aggregateKeyList = aggregateKeyList.filter(function (e) { return e });
    var ret = "";
    if (aggregateKeyList.length > 0) {        
        for (let j1 = 0; j1 < aggregateKeyList.length; j1++) {            
            ret = ret + "," + aggregateKeyList[j1].toLowerCase().trim();
        }
    }    
    return ret;
}

export function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}

export function filterData(rowData, keyword) {
    return rowData.filter((row) => {
        return row.name.toLowerCase().includes(keyword.toLowerCase());
    });
}

export function sortData(rowData, sortingColumn, sortingDirection) {
    if (sortingColumn === null)
        return rowData;

    return rowData.slice().sort((a, b) => {
        const aValue = a[sortingColumn];
        const bValue = b[sortingColumn];

        if (aValue === bValue) {
            return 0;
        }

        if (sortingDirection === "asc") {
            return aValue < bValue ? -1 : 1;
        } else {
            return aValue < bValue ? 1 : -1;
        }
    });
}