import React from "react";
import logo from "./../img/iflower-logo.png";
import bg from "./../img/iflower-intro-bg.png";

const MessageInfo = ({ msg1, msg2 }) => {
   
    return (
        <div className="intro">
            {/*<h2>*/}
            {/*    <a href="https://iflower.vn">iFlower.vn</a> - Nền tảng tìm kiếm địa điểm Shop hoa tươi toàn quốc*/}
            {/*</h2>*/}
            {/*<a href="/" className="watermark">*/}
            {/*    <img src={bg} alt="iFlower" width="315px" style={{ margin: "5px 0px 0px 0px", cursor: "pointer" }} />               */}
            {/*</a>*/}
            <p>
                <b>{msg1}</b> {msg2}
                 {/*Không tìm thấy Shop nào. <br />Di và kéo Map để điều chỉnh Marker (ở trung tâm màn hình) về vị trí cần tìm.*/}
            </p>
        </div>
    );
}
export default MessageInfo;