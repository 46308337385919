import axios from "axios";

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    withCredentials: false,
    //headers: {
    //    ContentType: 'application/json;charset=utf-8',
    //    Authorization: `Bearer ${API_JWT}`,
    //    timeout: 5000,
    //},  
});

export function validateEnv() {
    if (process.env.REACT_APP_API_URL == null)
        throw new Error("App Message: You need to have API_URL (env), see README");
    if (process.env.REACT_APP_SIX_OPEN_ID == null)
        throw new Error("App Message: The Client need to have SIX_OPEN_ID (env), see README");
    if (process.env.REACT_APP_API_URL == null)
        throw new Error("App Message: The Client need to have SIX_SECRET_KEY (env), see README");
}

export async function login(username, password) {
    const response = await axios.post('api/tokens', {username,password});
    if (response.status === 200) {
        const token = response.data;
        sessionStorage.setItem('token', token);
    }
}

export async function getToken() {
    try {
        validateEnv();
        console.log("App Message: Get Access Token");
        return await instance({
                url: "tokens",
                method: "POST",
                headers: {
                    ContentType: 'application/json;charset=utf-8',
                    Timeout: 5000,
                },
                data: { Email: `${process.env.REACT_APP_SIX_OPEN_ID}`, Password: `${process.env.REACT_APP_SIX_SECRET_KEY}` },
            })
            //.then((res) => {
            //// handle success
            //console.log(res);
            //})
            .catch((e) => {
                // handle error
                //console.log(e);
            });
    } catch (e) {
        // handle error
        console.error(e);
    }
}

export async function refreshToken() {
    try {
        validateEnv();
        console.log("App Message: Refreshed Token");
        return await instance({
            url: "tokens/refreshToken",
            method: "GET",
            headers: {
                ContentType: 'application/json;charset=utf-8',
                Timeout: 5000,
            },
            //data: { Email: `${userName}`, RefreshToken: `${refreshToken}` },
        })
        //.then((res) => {
        //// handle success
        //console.log(res);
        //})
        .catch((e) => {
            // handle error
            console.log(e);
        });
    } catch (e) {
        // handle error
        console.error(e);
    }
}

//export const initApiInstace = ({ jwt: token}) => {
//    if (process.env.API_ACCESS_TOKEN == null) {
//        throw new Error("You need to have API_ACCESS_TOKEN (env), see README");
//    }

//    console.log("Initialize API instance");
//    return axios.create({
//        baseURL: API_URL,
//        headers: {
//            ContentType: 'application/json;charset=utf-8',
//            Authorization: `Bearer ${API_JWT}`,
//            timeout: 5000,
//        },
//    });
//};


//const instance = () => {
//    if (process.env.REACT_APP_API_ACCESS_TOKEN == null) {
//        throw new Error("App Message: You need to have API_ACCESS_TOKEN (env), see README");
//    }
//    if (process.env.REACT_APP_API_URL == null) {
//        throw new Error("App Message: You need to have API_URL (env), see README");
//    }

//    API_URL = process.env.REACT_APP_API_URL;
//    API_JWT = process.env.REACT_APP_API_ACCESS_TOKEN;
//    console.log("Initialize API instance");
//    return axios.create({
//        baseURL: API_URL,
//        headers: {
//            ContentType: 'application/json;charset=utf-8',
//            Authorization: `Bearer ${API_JWT}`,
//            timeout: 5000,
//        },
//    });
//};

export default instance;