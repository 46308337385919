import React from "react";
//import ReactDOM from "react-dom";
import * as turf from '@turf/turf';
import { getAllShops, getDirection } from "./../api/apiService";
import { removeVieTones, removeSpecName, removeSpecAddress } from "./utils/strConverter";
import { addSpecAddressRule, onlyUnique, aggregateKeyList, aggregateKey } from "./utils/strConverter";
//import useCheckMobileScreen from "./utils/useCheckMobileScreen";
//import LoadingSpinner from "./LoadingSpinner";
import MessageInfo from "./MessageInfo";
import mapRadarBlue from "./../img/map-radar-blue.png";
import listViewMode from "./../img/show-list-blue.png";
import mapViewMode from "./../img/show-map-blue.png";
//import zalo from "./../img/zalo-icon-blue.png";
//import phone from "./../img/phone-icon-blue.png";
//import directions from "./../img/directions-icon-blue.png";
//import banner1 from "./../img/shops-banner-bg12.png";
//import banner2 from "./../img/flower-shop-square.png";
import "./index.css";
import List from "@mui/material/List";
//import { FcFinePrint } from "react-icons/fc";
import ScannedShop from "./ScannedShop";
//import Shop from "./Shop";
//import Intro from "./Intro";

class Scanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            results: [],
            oriResults: [],
            highlightResults: [],
            joinedHighlightResults: [],
            isLoading: false,
            mapSource: [],
            isCollapsed: false,
            isExpanded: true,
            //directions: [],
            //isDirectMode: false,
            isScanInit: true,
            isScanLocateUsed: false,
            //isDirectPoint: false,
            //currentCoords: { lng: 0, lat: 0 },
            //currentZoom: 12.5,
            //currentLng: 0,
            //currentLat: 0,
            //query: "",
            //firstLoad: true,
            //isLoading: false,
            //filteredList: [],
            //isCollapsed: localStorage.getItem('is-sidebar-collapsed'),
            //isExpanded: localStorage.getItem('is-sidebar-collapsed') ? false : true,
            destLng: 0,
            destLat: 0,
            startLng: 0,
            startLat: 0,
        };
        this.onClick = this.onClick.bind(this);
        this.refreshScan = this.refreshScan.bind(this);
        this.handleScan = this.handleScan.bind(this);
        this.handleClickedItem = this.handleClickedItem.bind(this);
        this.handleDirections = this.handleDirections.bind(this);
        this.handleCalls = this.handleCalls.bind(this);
        this.handleListToggler = this.handleListToggler.bind(this);
    }

    onClick(e) {
        console.log('clicked');
    }

    refreshScan() {
        this.setState({
            startLng: this.state.startLng,
            startLat: this.state.startLat,
            //isDirectInit: (this.state.startLng === this.state.destLng && this.state.startLat === this.state.destLat)?true:false,
        });
        //console.log("Scanning...");
        this.scan([this.state.startLng, this.state.startLat]);

        this.props.handleDirection(false, this.state.startLng, this.state.startLat);
        this.setState({
            isLoading: false
        });
        this.props.initSpinner(false);
    }

    handleScan() {        
        try {
            this.setState({
                isLoading: true
            });
            this.props.initSpinner(true);
            //Fetch data
            this.fetchShops();        
        }
        catch (e) {
            this.setState({
                isLoading: false
            });
            this.props.initSpinner(false);
        }

        //this.initDirections('');
        const gaEventTracker = this.props.useGAEventTracker('Map');
        //gaEventTracker(`Shop [${name}]`);
        gaEventTracker(`Shop Scanner`);      
    }

    scan(from) {
        var map = this.props.map;
        //var from = [this.state.startLng, this.state.startLat];
        var searchRadius = this.makeRadius(from, 2500);
        map.getSource('radius-points-data').setData(searchRadius);

        var featuresInBuffer = this.spatialJoin(this.state.highlightResults, searchRadius);
        var joinedHighlightGeo = turf.featureCollection(featuresInBuffer);
        this.setState({ joinedHighlighResults: joinedHighlightGeo });
        map.getSource('confirmed-points-data').setData(joinedHighlightGeo);
    }

    initScanner() {        
        const map = this.props.map;
        const geo = this.props.geo;
        const geoScan = this.props.geoScan;
        const selectMarker = this.props.selectMarker;
        const srcMarkerScan = this.props.srcMarkerScan;
        
        //map.setZoom(13.4);
        //console.log("Init Scanner...");
        //this.props.initGeoLoc();
        //scanner init

        if (this.state.isScanInit) {
            //console.log(this.state.isScanInit);
            try {
                map.removeControl(geoScan);
            }
            catch { }
            map.addControl(geoScan, 'top-right');
            this.setState({ isScanInit: false });

            const { lng, lat } = map.getCenter();
            /*this.props.initMarker(lng, lat);*/
            this.setState({
                startLng: lng,
                startLat: lat,
                //isDirectInit: (this.state.startLng === this.state.destLng && this.state.startLat === this.state.destLat)?true:false,
            });

            //map.setZoom(13.4);
            this.props.initMarker([lng, lat]);           
            this.scan([lng, lat]);                  
        }

        map.on('sourcedata', (e) => {            
            if (e.sourceId === "confirmed-points-data" && e.isSourceLoaded) {              
                const joinedPoints = map.querySourceFeatures("confirmed-points-data"); 
                let filteredPoints = joinedPoints.filter((ele, idx) => idx === joinedPoints.findIndex(elem => elem.properties.id === ele.properties.id));             
                this.setState({                   
                    mapSource: filteredPoints
                });
            }
        });

        map.on("moveend", async () => {            
            if (!map) return;          
            this.setState({ currentZoom: map.getZoom().toFixed(1) });                    
            // Get new center coordinates             
            const { lng, lat } = map.getCenter();
            //this.props.initMarker([lng, lat]);           
            //srcMarkerScan.setLngLat([lng, lat]);
            this.setState({
                startLng: lng,
                startLat: lat,
            });    
            srcMarkerScan.setLngLat([lng, lat]);
            //this.scan([lng, lat]);      
        });

        srcMarkerScan.getElement().addEventListener('click', (e) => {
            try {
                this.setState({
                    isLoading: true
                });
                this.props.initSpinner(true);
                //refresh data
                this.refreshScan();
            }
            catch (e) {
                this.setState({
                    isLoading: false
                });
                this.props.initSpinner(false);
            }
           
            //this.setState({
            //    startLng: this.state.startLng,
            //    startLat: this.state.startLat,
            //    //isDirectInit: (this.state.startLng === this.state.destLng && this.state.startLat === this.state.destLat)?true:false,
            //});
            ////console.log("Scanning...");
            //this.scan([this.state.startLng, this.state.startLat]);
            
            //this.props.handleDirection(false, this.state.startLng, this.state.startLat);
        });

        geoScan.on('trackuserlocationstart', (e) => {
            this.setState({
                isScanLocateUsed: true
            });
            //console.log("trackuserlocationstart");
            //let lng = this.state.startLng;
            //let lat = this.state.startLat;
            //console.log("CUR_LOC: [" + lng + ", " + lat + "]");
            //this.scan([lng, lat]);
        });

        geoScan.on('trackuserlocationend', (e) => {
            this.setState({
                isScanLocateUsed: false
            });
            //console.log("trackuserlocationend");
        });

        // Set an event listener that fires when a geolocate event occurs.       
        geoScan.on('geolocate', (e) => {
            if (!map) return;
            //map.setZoom(13.4);
            //console.log("geolocate");
            let lng = e.coords.longitude;
            let lat = e.coords.latitude;
            
            //this.props.initMarker([lng, lat]);
            //srcMarkerScan.remove();
            //srcMarkerScan.setLngLat([lng, lat]).addTo(map);
            srcMarkerScan.setLngLat([lng, lat]);

            this.setState({
                startLng: lng,
                startLat: lat,
                //isDirectInit: (this.state.startLng === this.state.destLng && this.state.startLat === this.state.destLat)?true:false,
            });
            if (this.state.isScanLocateUsed) {
                //console.log("isScanLocateUsed");
                this.scan([lng, lat]);
            }
            //map.setZoom(13.4);

            //var from = [lng, lat];
            //var searchRadius = this.makeRadius(from, 2500);
            //map.getSource('radius-points-data').setData(searchRadius);

            //var featuresInBuffer = this.spatialJoin(this.state.highlightResults, searchRadius);
            //var joinedHighlightGeo = turf.featureCollection(featuresInBuffer);
            //this.setState({ joinedHighlightResults: joinedHighlightGeo });
            //map.getSource('confirmed-points-data').setData(turf.featureCollection(joinedHighlightGeo));
        });

        //srcMarkerScan.getElement().addEventListener('click', (e) => {
        //    console.log("Selected");
        //});
    }

    makeRadius(lngLatArray, radiusInMeters) {
        var point = turf.point(lngLatArray);
        var options = { units: 'meters' };
        var buffered = turf.buffer(point, radiusInMeters, options);
        return buffered;
    }

    spatialJoin(sourceGeoJSON, filterFeature) {
        // Loop through all the features in the source geojson and return the ones that 
        // are inside the filter feature (buffered radius) and are confirmed landing sites       
        var joined = sourceGeoJSON.features.filter(function (feature) {
            //return turf.booleanPointInPolygon(feature, filterFeature) && feature.properties.isDeleted === 'false';           
            return turf.booleanPointInPolygon(feature, filterFeature);
        });      
        return joined;
    }

    async fetchShops() {
        //const map = this.props.map;        
        //console.log("[2] Requested");
        await getAllShops("shops?q=")
            .then((item) => {
                this.setState({
                    results: item.data,
                    //firstLoad: false
                });
                //console.log("[2] Returned: " + item.data.length)
                //this.drawMap(this.state.query);
                this.drawMap(item.data);
              
                this.initScanner();

                //console.log(this.state.highlightResults);
                //this.setState({
                //    isLoading: false,
                //    firstLoad: false
                //});
                //spinner.current.remove();                            
                //this.props.initGeoLoc();
                //map.setZoom(13.4);
                //Stop Busy
                this.setState({
                    isLoading: false
                });
                this.props.initSpinner(false);
            })
            .catch(() => {
                //setErrorMessage("Unable to fetch list");
                this.setState({
                    isLoading: false
                });
                this.props.initSpinner(false);
            });
    }

    drawMap(data) {
        //console.log("Data: " + data);
        //this.setState({
        //    isLoading: true
        //});
        const map = this.props.map;
        const popup = this.props.popup;
        const marker = this.props.marker;
        //const spinner = this.props.spinner;
        const results = this.state.results;
        //const results = data;
        //const directions = this.state.directions;
        var highlightMarkers, usualgeoJSON, highlightgeoJSON;
        //popup.current.remove();
        //marker.current.remove();
        //if (popup.current.isOpen()) popup.current.remove();   
        //console.log("Data: " + results);
        const jsonMarkers = JSON.stringify(results);
        //console.log("2 Data: " + jsonMarkers.length);
        //const jsonMarkers1 = JSON.stringify(data);
        //console.log("2 Data: " + jsonMarkers1.length);

        //const jsonDirections = JSON.stringify(directions.routes[0].geometry);
        //console.log("Directions: " + jsonDirections);

        //if (vendor && vendor.trim() !== "") {
        if (results) {
            //highlightMarkers = this.getFilterCentersSuggestions(jsonMarkers, vendor);
            highlightMarkers = this.getSuggestions(jsonMarkers, '');
            console.log("Return Count: " + highlightMarkers.length);
            //this.setState({
            //    query: vendor,
            //    //results: res.body,              
            //    firstLoad: false,
            //    filteredList: highlightMarkers,
            //});
            //console.log("Filtered Data: " + filteredData);

            // highlightMarkers = markers.List.filter(
            //   (m) =>
            //     vendor &&
            //     m?.Address.trim().toLowerCase().includes(vendor.trim().toLowerCase())
            // );
            // usualMarkers = markers.filter(
            //   (m) => m.ShopName.toLowerCase() !== vendor.toLowerCase()
            //);

            //usualMarkers = markers;
            //console.log(usualMarkers);
        } else {
            //usualMarkers = markers;
            //console.log(usualMarkers);            
        }

        usualgeoJSON = this.genFeatureListFromGeoData(jsonMarkers);        
        const jsonFilteredMarkers = JSON.stringify(highlightMarkers);
        highlightgeoJSON = this.genFeatureListFromGeoList(jsonFilteredMarkers);
        //console.log(jsonFilteredMarkers);
        this.setState({ highlightResults: highlightgeoJSON });        
    }

    getSuggestions(jsonMarkers, termsString) {
        const markers = JSON.parse(jsonMarkers);
        termsString = removeSpecName(termsString.trim().toLowerCase());
        var key = addSpecAddressRule(termsString.trim().toLowerCase());
        const terms = removeVieTones(key).split(" ");
        const aggrFilterList = aggregateKeyList(terms);
        //for (let i = 0; i < aggrFilterList.length; i++) {
        //    console.log(aggrFilterList[i]);
        //}

        var data = markers.filter((p) =>
            aggrFilterList.every((term) => [
                //removeVieTones(p.ShopName).toLowerCase().trim(),
                //removeVieTones(p.ShopName).split(" "),
                //removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim(),
                removeVieTones(p.Province).toLowerCase().trim(),
                removeVieTones(p.District).toLowerCase().trim(),
                removeVieTones(p.Ward).toLowerCase().trim(),
                removeVieTones(p.Ward).toLowerCase().trim() + " " + removeVieTones(p.Province).toLowerCase().trim(),
                removeVieTones(p.Street).toLowerCase().trim(),
                removeVieTones(p.Street).toLowerCase().trim() + " " + removeVieTones(p.Province).toLowerCase().trim(),
                removeVieTones(p.Street).toLowerCase().trim() + " " + removeVieTones(p.District).toLowerCase().trim(),
                removeVieTones(p.Street).toLowerCase().trim() + " " + removeVieTones(p.Ward).toLowerCase().trim(),
                removeVieTones(p.Address).toLowerCase().trim()
                //removeVieTones(removeSpecAddress(p.ShopAddress)).toLowerCase().trim()
            ]
                .some((f) => removeVieTones(f).toLowerCase().trim().match(term))
            ));

        //const key1 = removeVieTones(removeSpecName(key));
        //console.log(key1);
        //const terms1 = removeVieTones(key1).split(" ");
        //const aggrFilterList1 = aggregateKeyList(terms1);        

        var data2 = markers.filter((p) =>
            aggrFilterList.every((term) => [
                removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim(),
                removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim() + " " + removeVieTones(p.Province).toLowerCase().trim(),
                removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim() + " " + removeVieTones(p.District).toLowerCase().trim(),
                removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim() + " " + removeVieTones(p.Ward).toLowerCase().trim(),
                removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim() + " " + removeVieTones(p.Street).toLowerCase().trim(),
                //removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim() + " " + removeVieTones(p.Address).toLowerCase().trim()
            ]
                .some((f) => removeVieTones(f).toLowerCase().trim().includes(term))
            ));

        var data3 = markers.filter((p) =>
            aggrFilterList.every((term) => [
                //removeVieTones(p.Name).toLowerCase().trim(),                
                //removeVieTones(removeSpecAddress(p.Address)).toLowerCase().trim(),
                removeVieTones(removeSpecAddress(p.ShopAddress)).toLowerCase().trim()
            ]
                .some((f) => removeVieTones(f).toLowerCase().trim().includes(term))
            ));


        //var filterdShops = [];
        ////var shopsByProvince = markers.filter((p) =>
        ////    aggrFilterList.every((key) => [              
        ////        removeVieTones(p.Province).toLowerCase().trim()]
        ////        .some((item) => removeVieTones(item != null && item.trim() != "" ? item.toLowerCase().trim() : "").match(key))
        ////    ));

        ////var shopsByProvince = markers.filter((p) =>
        ////    aggrFilterList.every((key) => removeVieTones(p.Province != null && p.Province.trim() != "" ? p.Province.toLowerCase().trim() : "").toLowerCase().trim().match(key)));

        //var shopsByProvince = markers.filter((p) =>
        //    aggrFilterList.every((term) => [
        //        removeVieTones(p.Province).toLowerCase().trim()
        //    ].some((f) => removeVieTones(f).trim().toLowerCase().match(term))
        //    ));

        //console.log("Total Count: " + markers.length);
        //console.log("Province Count: " + shopsByProvince.length);
        //var sJsonProvince = JSON.stringify(shopsByProvince);
        ////console.log(sJsonProvince);
        //var oJsonProvince = JSON.parse(sJsonProvince);
        //var shopsByDistrict = markers.filter((p) =>
        //    aggrFilterList.every((term) => [
        //        removeVieTones(p.District).toLowerCase().trim()
        //    ].some((f) => removeVieTones(f).trim().toLowerCase().match(term))
        //    ));
        //console.log("District Count: " + shopsByDistrict.length);
        //var sJsonDistrict = JSON.stringify(shopsByDistrict);
        //var oJsonDistrict = JSON.parse(sJsonDistrict);
        //var shopsByWard = markers.filter((p) =>
        //    aggrFilterList.every((key) =>
        //        removeVieTones(p.Ward != null && p.Ward.trim() != "" ? p.Ward.toLowerCase().trim() : "").toLowerCase().trim().match(key)
        //    ));
        //console.log("Ward Count: " + shopsByWard.length);
        //var sJsonWard = JSON.stringify(shopsByWard);
        //var oJsonWard = JSON.parse(sJsonWard);
        //var shopsByStreet = markers.filter((p) =>
        //    aggrFilterList.every((key) =>
        //        removeVieTones(p.Street != null && p.Street.trim() != "" ? p.Street.toLowerCase().trim() : "").toLowerCase().trim().match(key)
        //    ));
        //console.log("Street Count: " + shopsByStreet.length);
        ////var shopsByProvince = markers.filter((p) =>
        ////    aggrFilterList.every((key) => [              
        ////        removeVieTones(p.Province).toLowerCase().trim()]
        ////        .some((item) => removeVieTones(item != null && item.trim() != "" ? item.toLowerCase().trim() : "") === key)
        ////    ));

        ////var shopsByDistrict = data.filter((p) =>
        ////    aggrFilterList.every((key) => [
        ////        removeVieTones(p.District).toLowerCase().trim()]
        ////        .some((item) => removeVieTones(item.trim().toLowerCase()).equals(key))
        ////    ));

        ////var shopsByWard = data.filter((p) =>
        ////    aggrFilterList.every((key) => [
        ////        removeVieTones(p.Ward).toLowerCase().trim()]
        ////        .some((item) => removeVieTones(item.trim().toLowerCase()).equals(key))
        ////    ));

        ////var shopsByStreet = data.filter((p) =>
        ////    aggrFilterList.every((key) => [
        ////        removeVieTones(p.Street).toLowerCase().trim()]
        ////        .some((item) => removeVieTones(item.trim().toLowerCase()).equals(key))
        ////    ));

        //filterdShops = filterdShops.concat(shopsByStreet, shopsByWard, shopsByDistrict, shopsByProvince );
        ////filterdShops = filterdShops.concat(shopsByProvince);

        ////const data = markers.filter((p) =>
        ////    aggrFilterList.every((term) => [
        ////        removeVieTones(p.ShopName).toLowerCase().trim(),
        ////        removeVieTones(p.Province).toLowerCase().trim(),
        ////        removeVieTones(p.District).toLowerCase().trim(),
        ////        removeVieTones(p.Ward).toLowerCase().trim(),
        ////        removeVieTones(p.Street).toLowerCase().trim(),
        ////        //removeVieTones(removeSpecAddress(p.Address)).toLowerCase().trim()
        ////    ]
        ////        .some((f) => removeVieTones(f.trim().toLowerCase()).equals(term))
        ////    ));

        ////const data = markers.filter((p) =>
        ////    aggrFilterList.every((term) => [
        ////        removeVieTones(p.Province).toLowerCase().trim()
        ////    ]
        ////        .some((f) => removeVieTones(f.trim().toLowerCase()).includes(term))
        ////    ));

        ////const data = markers;

        ////const dataByWard = markers.filter((p) =>
        ////    aggrFilterList.every((term) => [ removeVieTones(p.Ward).toLowerCase().trim()]
        ////        .some((f) => removeVieTones(f.trim().toLowerCase()).equals(term))
        ////    ));

        data = data.concat(data2).concat(data3);
        //data = data3.concat(data2).concat(data);
        data = data.filter(onlyUnique);
        return data;
        //return sortData(data, "Province", "asc");
        //return sortData(filterdShops, "Province", "asc");
        //return filterdShops;
    }

    genFeatureListFromGeoData(json) {
        //const results = null;
        const newFeaturesList = [];
        try {
            //TODO: Optimize          
            const markers = JSON.parse(json);
            markers.map((p) => {
                newFeaturesList.push({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [parseFloat(p.Longitude), parseFloat(p.Latitude)],
                        // coordinates: [
                        //   parseFloat(p.location.longitude),
                        //   parseFloat(p.location.latitude),
                        // ],
                    },
                    properties: {
                        id: p.ShopId,
                        name: p.ShopName,
                        displayName: p.DisplayName,
                        //code: p.shopCode,
                        address: p.Address,
                        displayAddress: p.DisplayAddress,
                        phoneNumber: p.PhoneNumber,
                        banner: p.Banner,
                        longitude: p.Longitude,
                        latitude: p.Latitude,
                        //"point-color": "253,237,57,1",
                    },
                });
                //End push
            });
        } catch (e) {
            //console.log("App Message (Data): " + e);
            return {
                type: "FeatureCollection",
                features: [],
            };
            // expected output: SyntaxError: Unexpected token o in JSON at position 1
        }

        return {
            type: "FeatureCollection",
            features: newFeaturesList,
        };
    }

    genFeatureListFromGeoList(json) {
        //const results = null;
        const newFeaturesList = [];
        try {
            const markers = JSON.parse(json);
            //this.setState({
            //    //results: res.body,
            //    //firstLoad: false,
            //    //filteredList: markers,
            //});

            var isChangedLocation = false;
            //markers.List.map((p) => {            
            markers.map((p) => {
                //////TODO: fly to loc - recheck
                //if (!isChangedLocation && !this.state.isDirectPoint) {
                //    //console.log("App Message: Find by Condition.");
                //    this.setMapCenter([parseFloat(p.Longitude), parseFloat(p.Latitude)]);
                //    isChangedLocation = true;
                //    this.setState({ isDirectPoint: false });
                //}

                newFeaturesList.push({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [parseFloat(p.Longitude), parseFloat(p.Latitude)],
                        // coordinates: [
                        //   parseFloat(p.location.longitude),
                        //   parseFloat(p.location.latitude),
                        // ],
                    },
                    properties: {
                        id: p.ShopId,
                        name: p.ShopName,
                        displayName: p.DisplayName,
                        //code: p.ShopCode,
                        address: p.Address,
                        displayAddress: p.DisplayAddress,
                        phoneNumber: p.PhoneNumber,
                        banner: p.Banner,
                        //"point-color": "253,237,57,1",
                    },
                });
                //End push
            });
        } catch (e) {
            //console.log("App Message: Find All");
            return {
                type: "FeatureCollection",
                features: [],
            };
            // expected output: SyntaxError: Unexpected token o in JSON at position 1
        }

        return {
            type: "FeatureCollection",
            features: newFeaturesList,
        };
    }
    
    redrawMap(vendor) {
        const map = this.props.map;
        //const popup = this.props.popup;
        //const marker = this.props.marker;
        const destMarkerScan = this.props.destMarkerScan;
        const results = this.state.results;
        //const spinner = this.props.spinner;

        //const directions = this.state.directions;
        var highlightMarkers, usualgeoJSON, highlightgeoJSON;

        //popup.remove();
        //marker.remove();      
        //if (popup.isOpen()) popup.remove();  


        this.setState({
            isLoading: true
        });
        this.props.initSpinner(true);

        const jsonMarkers = JSON.stringify(results);
        //const jsonDirections = JSON.stringify(directions.routes[0].geometry);
        //console.log("Directions: " + jsonDirections);
        try {
            if (vendor && vendor.trim() !== "") {
                usualgeoJSON = this.genFeatureListFromGeoData(jsonMarkers);
                //highlightMarkers = this.getFilterCentersSuggestions(jsonMarkers, vendor);
                highlightMarkers = this.getSuggestions(jsonMarkers, vendor);
                //console.log("highlightMarkers Count: " + highlightMarkers.length);
                //console.log("highlightMarkers: " + JSON.stringify(highlightMarkers));
                if (highlightMarkers.length === 1) {
                    this.setState({
                        query: vendor,
                        //results: res.body,              
                        firstLoad: false,
                        filteredList: highlightMarkers,
                    });
                    //usualgeoJSON = this.genFeatureListFromGeoData(jsonMarkers);
                    const jsonFilteredMarkers = JSON.stringify(highlightMarkers);
                    //console.log("selectLoc " + jsonFilteredMarkers);
                    highlightgeoJSON = this.genFeatureListFromGeoList(jsonFilteredMarkers);
                } else {
                    highlightMarkers = this.getSuggestions(jsonMarkers, vendor + ' ' + this.state.query);
                    this.setState({
                        //query: vendor + ' ' + this.state.query,
                        //results: res.body,              
                        firstLoad: false,
                        filteredList: highlightMarkers,
                    });
                    const jsonFilteredMarkers = JSON.stringify(highlightMarkers);
                    //console.log("selectLoc2 " + jsonFilteredMarkers);
                    highlightgeoJSON = this.genFeatureListFromGeoList(jsonFilteredMarkers);
                }
                //console.log("selectLoc0 " + JSON.stringify(highlightMarkers));
                // Get Lng Lat of Dest
                var selectLoc = JSON.parse(JSON.stringify(highlightMarkers));

                selectLoc.map((p) => {                    
                    this.setState({
                        destLng: p.Longitude,
                        destLat: p.Latitude,
                        startLng: p.Longitude,
                        startLat: p.Latitude,
                    });
                    if (destMarkerScan) destMarkerScan.remove();
                    destMarkerScan.setLngLat([p.Longitude, p.Latitude]).addTo(map);
                });

                //const shopMarker = this.props.descMarkerScan
                //TODO: GET Long Latt dest
                //console.log("Filtered Data: " + filteredData);
                // highlightMarkers = markers.List.filter(
                //   (m) =>
                //     vendor &&
                //     m?.Address.trim().toLowerCase().includes(vendor.trim().toLowerCase())
                // );
                // usualMarkers = markers.filter(
                //   (m) => m.ShopName.toLowerCase() !== vendor.toLowerCase()
                //);

                //usualMarkers = markers;
                //console.log(usualMarkers);
            } else {
                //usualMarkers = markers;
                //console.log(usualMarkers);

                usualgeoJSON = this.genFeatureListFromGeoData(jsonMarkers);
                const jsonFilteredMarkers = JSON.stringify(highlightMarkers);
                highlightgeoJSON = this.genFeatureListFromGeoList(jsonFilteredMarkers);
                //highlightgeoJSON = this.genFeatureListFromGeoData(highlightMarkers);
            }

            //NOTE: Not render
            //map.getSource("custom-points-data").setData(usualgeoJSON);           
            //map.getSource("hightlight-points-data").setData(highlightgeoJSON);

            //const routeFeature = {
            //    'type': 'Feature',
            //    'properties': {},
            //    'geometry': directions.routes[0].geometry,
            //};
            map.getSource("route-data").setData({
                'type': 'LineString',
                'coordinates': []
            });

            this.setState({
                isLoading: false
            });
            this.props.initSpinner(false);
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.props.initSpinner(false);
        }

        // // clearing layers
        // if (map.getLayer("shops")) {
        //   map.removeLayer("shops");
        // }
        // if (map.getSource("shops")) {
        //   map.removeSource("shops");
        // }
        // if (map.getLayer("shops-highlight")) {
        //   map.removeLayer("shopss-highlight");
        // }
        // if (map.getSource("shops-highlight")) {
        //   map.removeSource("shops-highlight");
        // }

        // map
        //   .addSource("shops", {
        //     type: "geojson",
        //     data: usualgeoJSON,
        //   })
        //   .addLayer({
        //     id: "shops",
        //     type: "circle",
        //     interactive: true,
        //     source: "trucks",
        //     paint: {
        //       "circle-radius": 8,
        //       "circle-color": "rgba(253,237,57,1)",
        //     },
        //   });

        // if (highlightMarkers) {
        //   map
        //     .addSource("shops-highlight", {
        //       type: "geojson",
        //       data: highlightgeoJSON,
        //     })
        //     .addLayer({
        //       id: "shops-highlight",
        //       type: "circle",
        //       interactive: true,
        //       source: "shops-highlight",
        //       paint: {
        //         "circle-radius": 8,
        //         "circle-color": "rgba(164,65,99,1)",
        //       },
        //     });
        // }
    }

    redrawDirections(vendor, arrLngLat) {
        const map = this.props.map;
        const popup = this.props.popup;
        const spinner = this.props.spinner;
        const results = this.state.results;
        const directions = this.state.directions;
        const currentZoom = this.state.currentZoom;
        var oriHighlightMarkers, highlightMarkers, usualgeoJSON, highlightgeoJSON, oriHighlightgeoJSON;
        //popup.remove();        

        this.setState({
            isLoading: true
        });
        this.props.initSpinner(true);

        //const jsonMarkers = JSON.stringify(results);
        //const jsonDirections = JSON.stringify(directions.routes[0].geometry);
        //console.log("Directions: " + jsonDirections);
        try {
            //if (vendor && vendor.trim() !== "") {
            //    usualgeoJSON = this.genFeatureListFromGeoData(jsonMarkers);
            //    highlightMarkers = this.getSuggestions(jsonMarkers, vendor);
            //    oriHighlightMarkers = highlightMarkers;
            //    if (highlightMarkers.length === 1) {
            //        this.setState({
            //            query: vendor,
            //            //results: res.body,              
            //            firstLoad: false,
            //            filteredList: highlightMarkers,
            //        });
            //        //usualgeoJSON = this.genFeatureListFromGeoData(jsonMarkers);
            //        const jsonFilteredMarkers = JSON.stringify(highlightMarkers);
            //        highlightgeoJSON = this.genFeatureListFromGeoList(jsonFilteredMarkers);
            //    } else {
            //        highlightMarkers = this.getSuggestions(jsonMarkers, vendor + ' ' + this.state.query);
            //        this.setState({
            //            //query: vendor + ' ' + this.state.query,
            //            //results: res.body,              
            //            firstLoad: false,
            //            filteredList: highlightMarkers,
            //        });
            //        const jsonFilteredMarkers = JSON.stringify(highlightMarkers);
            //        highlightgeoJSON = this.genFeatureListFromGeoList(jsonFilteredMarkers);
            //    }

            //    const jsonOriFilteredMarkers = JSON.stringify(oriHighlightMarkers);
            //    oriHighlightgeoJSON = this.genFeatureListFromGeoList(jsonOriFilteredMarkers);

            //    //console.log("Filtered Data: " + filteredData);
            //    // highlightMarkers = markers.List.filter(
            //    //   (m) =>
            //    //     vendor &&
            //    //     m?.Address.trim().toLowerCase().includes(vendor.trim().toLowerCase())
            //    // );
            //    // usualMarkers = markers.filter(
            //    //   (m) => m.ShopName.toLowerCase() !== vendor.toLowerCase()
            //    //);

            //    //usualMarkers = markers;
            //    //console.log(usualMarkers);
            //} else {
            //    //usualMarkers = markers;
            //    //console.log(usualMarkers);

            //    usualgeoJSON = this.genFeatureListFromGeoData(jsonMarkers);
            //    const jsonFilteredMarkers = JSON.stringify(highlightMarkers);
            //    highlightgeoJSON = this.genFeatureListFromGeoList(jsonFilteredMarkers);
            //    oriHighlightgeoJSON = highlightgeoJSON;
            //    //highlightgeoJSON = this.genFeatureListFromGeoData(highlightMarkers);
            //}

            //map.setZoom(currentZoom);

            //map.getSource("custom-points-data").setData(usualgeoJSON);
            //// map.getSource("usual-points-data").setData(usualgeoJSON);
            //map.getSource("hightlight-points-data").setData(highlightgeoJSON);

            const routeFeature = {
                'type': 'Feature',
                'properties': {},
                'geometry': directions.routes[0].geometry,
            };

            map.getSource("route-data").setData(routeFeature);
            //console.log(this.state.startLng + ", " + this.state.startLat + "][" + arrLngLat[0] + ", " + arrLngLat[1]);
            var from = [this.state.startLng, this.state.startLat];
            var to = arrLngLat;
            //var to = [this.state.destLng, this.state.destLat];
            var distance = this.getDistance(from, to);
            console.log(`Distance: ${distance} km`);

            //var searchRadius = this.makeRadius(from, 1500);
            //console.log("SearchRadius: " + searchRadius);
            ////map.getSource('search-radius').setData(searchRadius);
            //var featuresInBuffer = this.spatialJoin(oriHighlightgeoJSON, searchRadius);
            //console.log("FeaturesInBuffer: " + featuresInBuffer);
            //map.getSource('confirmed-points-data').setData(searchRadius);
            ////map.getSource('confirmed-points-data').setData(turf.featureCollection(featuresInBuffer));

            this.setState({
                isLoading: false
            });
            this.props.initSpinner(false);
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.props.initSpinner(false);
        }

        // // clearing layers
        // if (map.getLayer("shops")) {
        //   map.removeLayer("shops");
        // }
        // if (map.getSource("shops")) {
        //   map.removeSource("shops");
        // }
        // if (map.getLayer("shops-highlight")) {
        //   map.removeLayer("shopss-highlight");
        // }
        // if (map.getSource("shops-highlight")) {
        //   map.removeSource("shops-highlight");
        // }

        // map
        //   .addSource("shops", {
        //     type: "geojson",
        //     data: usualgeoJSON,
        //   })
        //   .addLayer({
        //     id: "shops",
        //     type: "circle",
        //     interactive: true,
        //     source: "trucks",
        //     paint: {
        //       "circle-radius": 8,
        //       "circle-color": "rgba(253,237,57,1)",
        //     },
        //   });

        // if (highlightMarkers) {
        //   map
        //     .addSource("shops-highlight", {
        //       type: "geojson",
        //       data: highlightgeoJSON,
        //     })
        //     .addLayer({
        //       id: "shops-highlight",
        //       type: "circle",
        //       interactive: true,
        //       source: "shops-highlight",
        //       paint: {
        //         "circle-radius": 8,
        //         "circle-color": "rgba(164,65,99,1)",
        //       },
        //     });
        // }
    }

    async initDirections(name) {
        const map = this.props.map;
        const spinner = this.props.spinner;
        
        const srcMarkerScan = this.props.marker;
        const selectMarkerScan = this.props.selectMarkerScan;
        const selectMarker = this.props.selectMarker;
        const results = this.state.results;
        //const directions = this.state.directions;
        var highlightMarkers;
        //this.props.handleToggler();

        //spinner.current
        //    .setLngLat([map.getCenter().lng.toFixed(4), map.getCenter().lat.toFixed(4)])
        //    .addTo(map);

        const geoLocate = this.props.geoLocate;
        try {
            map.removeControl(geoLocate.current);
        }
        catch { }

        map.addControl(geoLocate.current, 'top-right');

        this.setState({ isDirectMode: true, });

        // Set an event listener that fires when a geolocate event occurs.       
        geoLocate.current.on('geolocate', (e) => {
            let lng = e.coords.longitude;
            let lat = e.coords.latitude;
            let updatedValue = {};
            updatedValue = { lng: lng, lat: lat };
            this.setState({
                currentCoords: updatedValue,
                currentLng: lng,
                currentLat: lat,
            });

            if (this.state.isDirectMode) {
                const srcMarkerScan = this.props.marker;
                srcMarkerScan.current.remove();
                srcMarkerScan.current.setLngLat([lng, lat]).addTo(map);
            }
        });

        //geoLocate.trigger();

        this.setState({
            isLoading: true
        });

        const jsonMarkers = JSON.stringify(results);
        var _destLng, _destLat = "";
        if (name && name.trim() !== "") {
            highlightMarkers = this.getSuggestions(jsonMarkers, name);

            if (highlightMarkers.length === 1) {
                //console.log("* Dest Marker: " + JSON.stringify(highlightMarkers));                
            } else {
                highlightMarkers = this.getSuggestions(jsonMarkers, name + ' ' + this.state.query);
            }

            var selectLoc = JSON.parse(JSON.stringify(highlightMarkers));

            selectLoc.map((p) => {
                _destLng = p.Longitude;
                _destLat = p.Latitude;
                this.setState({
                    destLng: p.Longitude,
                    destLat: p.Latitude,
                    startLng: p.Longitude,
                    startLat: p.Latitude,
                });
            });

            //await getDirection(`${_destLng},${_destLat}`, `${_destLng},${_destLat}`)
            //    .then((item) => {
            //        this.setState({
            //            directions: item.data,
            //            firstLoad: false,
            //            isDirectInit: true,
            //        });
            //        //this.redrawMap(this.state.query);
            //        this.redrawDirections(name);
            //        this.setState({
            //            isLoading: false,
            //            firstLoad: false
            //        });

            //        //const map = this.props.map;
            //        //const spinner = this.props.spinner;
            //        spinner.current.remove();
            //    })
            //    .catch(() => {
            //        //setErrorMessage("Unable to fetch list");
            //        this.setState({
            //            isLoading: false,
            //            firstLoad: false
            //        });
            //    });

            this.props.handleDirection(true, map.getCenter().lng.toFixed(4), map.getCenter().lat.toFixed(4));
            //const srcMarkerScan = this.props.marker;
            //Show direct guideline (popup) 

            //map.on("load", () => {
            //    if (!map) return;
            //    this.addRadarButton(map);
            //});

            //map.on("resize", async () => {
            //    map.setSize(13.5);
            //    console.log(13.5);
            //});

            //radarButton.current.getElement().addEventListener('onClick', (e) => {
            //    const centerPosition = {
            //        center: [map.getCenter().lng.toFixed(4), map.getCenter().lat.toFixed(4)],
            //    };
            //    map.flyTo(centerPosition);
            //});


            srcMarkerScan.current.getElement().addEventListener('click', (e) => {
                //console.log("srcMarkerScan clicked");
                let isDirInit = (this.state.startLng === this.state.destLng && this.state.startLat === this.state.destLat) ? true : false;
                if (isDirInit) {
                    return;
                }
                this.setState({ isDirectPoint: true });

                this.props.handleDirection(false, this.state.startLng, this.state.startLat);
                //selectMarker.remove();
                //selectMarker.setLngLat([this.state.startLng, this.state.startLat]).addTo(map);

                //getDirection(`${this.state.startLng},${this.state.startLat}`, `${this.state.destLng},${this.state.destLat}`)
                //    .then((item) => {
                //        this.setState({
                //            directions: item.data,
                //            firstLoad: false,
                //        });
                //        //this.redrawMap(this.state.query);
                //        this.redrawDirections(name);
                //        //this.setMapCenter([this.state.startLng, this.state.startLat]);
                //        //this.props.handleZoom(this.state.currentZoom);
                //        this.setState({
                //            isLoading: false,
                //            firstLoad: false
                //        });

                //        //const map = this.props.map;
                //        //const spinner = this.props.spinner;
                //        spinner.current.remove();
                //        //srcMarkerScan.current.setLngLat([map.getCenter().lng.toFixed(4), map.getCenter().lat.toFixed(4)]);
                //    })
                //    .catch(() => {
                //        //setErrorMessage("Unable to fetch list");
                //        this.setState({
                //            isLoading: false,
                //            firstLoad: false,
                //        });
                //    });

                this.setState({
                    isDirectMode: false,
                });
            });

            this.setState({
                isLoading: false,
                firstLoad: false
            });

            //const map = this.props.map;
            //const spinner = this.props.spinner;
            spinner.current.remove();
        } else {
            // do nothing
        }
    }

    handleClickedItem(name, arrLngLat) {
        //const map = this.props.map;
        //const destMarkerScan = this.props.destMarkerScan;        
        //const geo = this.props.geo;
        //const geoScan = this.props.geoScan;
        this.setState({
            isLoading: true
        });
        this.props.initSpinner(true);
        //try {
        //    map.removeControl(geo);
        //    map.removeControl(geoScan);
        //}
        //catch { }
        this.setState({
            isDirectMode: false,
            isDirectPoint: false,
        });
        this.redrawMap(name); 
        this.props.handleSelection(arrLngLat);

        //if (destMarkerScan) {
        //    destMarkerScan.remove();
        //    destMarkerScan.setLngLat(arrLngLat).addTo(map);
        //}

        const gaEventTracker = this.props.useGAEventTracker('Map');
        //gaEventTracker(`Shop Selection [${name}]`);
        gaEventTracker(`Shop Selection`);
    }

    handleDirections(name, arrLngLat) {
        //const map = this.props.map;
        //const destMarkerScan = this.props.destMarkerScan;       
        //const geo = this.props.geo;
        //const geoScan = this.props.geoScan;
        this.setState({
            isLoading: true
        });
        this.props.initSpinner(true);
        //try {
        //    //map.removeControl(geo);
        //    //map.removeControl(geoScan);
        //}
        //catch { }
        this.setState({
            isDirectMode: false,
            isDirectPoint: false,
        });
        //console.log(window.screen.width <= 500);
        if (window.screen.width <= 500)
            this.handleListToggler();

        this.redrawMap(name);
        this.setState({
            destLng: arrLngLat[0],
            destLat: arrLngLat[1],
            //startLng: p.Longitude,
            //startLat: p.Latitude,
        });
        this.props.handleSelection(arrLngLat);
        this.props.handleDirection(false, this.state.startLng, this.state.startLat);        
        getDirection(`${this.state.startLng},${this.state.startLat}`, `${arrLngLat[0]},${arrLngLat[1]}`)
            .then((item) => {
                this.setState({
                    directions: item.data,
                    firstLoad: false,
                });
                //console.log(item.data);
                //this.redrawMap(this.state.query);
                this.redrawDirections(name, arrLngLat);
                //this.setMapCenter([this.state.startLng, this.state.startLat]);
                //this.props.handleZoom(this.state.currentZoom);
                this.setState({
                    isLoading: false,
                    firstLoad: false
                });
                this.props.initSpinner(false);

                //const map = this.props.map;
                //const spinner = this.props.spinner;
                //spinner.current.remove();
                //srcMarkerScan.current.setLngLat([map.getCenter().lng.toFixed(4), map.getCenter().lat.toFixed(4)]);
            })
            .catch(() => {
                //setErrorMessage("Unable to fetch list");
                this.setState({
                    isLoading: false,
                    firstLoad: false,
                });
                this.props.initSpinner(false);
            });

        //getDirection(`${this.state.startLng},${this.state.startLat}`, `${this.state.destLng},${this.state.destLat}`)
        //        .then((item) => {
        //            this.setState({
        //                directions: item.data,
        //                firstLoad: false,
        //                isDirectInit: true,
        //            });
        //            //this.redrawMap(this.state.query);
        //            this.redrawDirections(name);
        //            this.setState({
        //                isLoading: false,
        //                firstLoad: false
        //            });

        //            //const map = this.props.map;
        //            //const spinner = this.props.spinner;
        //            //TODO: stop spinner
        //            //spinner.current.remove();
        //        })
        //        .catch(() => {
        //            //setErrorMessage("Unable to fetch list");
        //            this.setState({
        //                isLoading: false,
        //                firstLoad: false
        //            });
        //        });

        //if (destMarkerScan) {
        //    destMarkerScan.remove();
        //    destMarkerScan.setLngLat(arrLngLat).addTo(map);
        //}

        const gaEventTracker = this.props.useGAEventTracker('Map');
        //gaEventTracker(`Shop Selection [${name}]`);
        gaEventTracker(`Shop Selection`);
    }

    //handleDirections(name) {
    //    this.initDirections(name);
    //    const gaEventTracker = this.props.useGAEventTracker('Map');
    //    //gaEventTracker(`Shop [${name}]`);
    //    gaEventTracker(`Shop Direction`);      //this.plotOnMap(name);
    //}

    handleCalls(type, name) {
        const gaEventTracker = this.props.useGAEventTracker('Map');
        //gaEventTracker(`${type} [${name}]`);
        gaEventTracker(`${type}`);
    }

    handleListToggler() {        
        if (this.state.isExpanded) {
            this.setState({ isExpanded: false });
            //localStorage.setItem('is-sidebar-collapsed', true);
            return;
        }
        this.setState({ isExpanded: true });
        //localStorage.removeItem('is-sidebar-collapsed');
      
    }

    componentDidMount() {        
        this.handleScan();
    }

    render() {
        const txtLabel = "Hiển thị các Shop gần nhất";
        const filteredMarkers = this.state.mapSource; //this.state.filteredList;  
        const resultsCount = filteredMarkers.length || 0;        
        const results = filteredMarkers || [];
        //console.log("Count  : " + results.length);
        const isLoading = this.state.isLoading;
        //const keyword = this.state.query;
        const _isExpanded = this.state.isExpanded;
        
        
        //const renderedResults = results.map((item, i) => (         
        //    <li id={i} key={i} style={{ padding: "5px 5px", fontFamily: "Tahoma", color: "#FFF" }}>
        //        <div id="li-container">
        //            <div id="container" onClick={this.handleClickedItem}>
        //            {/*<div id="container" onClick={this.handleHover(item.properties['name'])}>*/}
        //            {/*<div id="container">*/}
        //                <div className="shopTitleBG" style={{ clear: "both", height: '68px', padding: "0px 6px 0px 0px", backgroundColor: "cornsilk", opacity: '0.8', fontSize: "9pt" }}> {item.properties['address']}</div>
        //                <div className="shopTitle" style={{ clear: "both", padding: "5px 5px 5px 5px", color: "#514925", fontSize: "11pt", fontWeight: "bold", margin: "0px 0px 5px 0px", float: "left" }}>{ item.properties['displayName']} </div>                        
        //                <div className="shopAddr" style={{ clear: "both", padding: "5px 10px 5px 5px", color: "#514925", fontSize: "9pt" }}> {item.properties['displayAddress']}</div>
        //                <div style={{ alignContent: "center", textAlign: "center", height: "250px" }}>
        //                    <div style={{ alignContent: "center", textAlign: "center", height: "250px", backgroundColor: "#FFF" }}>
        //                        <img src={banner2} alt="Banner" width="95%" style={{ margin: "8px 0px 5px 0px", alignContent: "center" }} />
        //                        </div>
        //                </div>
        //            </div>
        //            <div id="icon-container">
        //                <img className="iconAct" src={directions} alt="Directions"
        //                    style={{ margin: "7px 0px 5px 20px", float: "left" }} />
        //                <a href={"https://zalo.me/" + item.properties['phoneNumber']}>
        //                    <img className="iconAct" src={zalo} alt="Phone" style={{ margin: "7px 20px 5px 0px", float: "right" }} />
        //                </a>
        //                <a href={"tel:" + item.properties['phoneNumber']}>                          
        //                    <img className="iconAct" src={phone} alt="Zalo" style={{ margin: "7px 40px 5px 0px", float: "right" }} />
        //                </a>
        //            </div>
        //        </div>
        //    </li>
        //));

        const renderedResults = results.map((item, i) => (          
            <ScannedShop key={i} data={item.properties} location={item.geometry} handleClickedItem={this.handleClickedItem} handleDirections={this.handleDirections} handleCalls={this.handleCalls} />
        ));
        return (
            <div>
             <div className="viewModeContainer">
                    <img src={_isExpanded ? mapViewMode : listViewMode} className="viewMode" alt="View Mode" width="90px" onClick={this.handleListToggler} />
                </div>
            <div className="custom-ctrl">
                {/*<FcFinePrint className="iconViewMode" onClick={this.handleListToggler} />       */}
                {/*<img src={_isExpanded ? mapViewMode : listViewMode} className="viewMode" alt="View Mode" width="90px" onClick={this.handleListToggler} />*/}
                <div className="text-container">
                    <div className="custom-ctrl-block">
                            <span className="text-container" onClick={this.refreshScan}>                       
                                <form>
                                        <div className="scanLabel">
                                            {txtLabel}
                                            <img id="imgScan" src={mapRadarBlue} alt="Scan" border="0" className="iconScanImg" />
                                        </div>                                                         
                                </form>
                            </span>
                    </div>
                </div>
            {
                    (resultsCount > 0) ? (                        
                        <div className={_isExpanded ? "result-area-expanded" : "results-area-collapsed"}>
                        <div>
                            <List
                                sx={{
                                    position: "relative",
                                    overflow: "auto",
                                    width: "100%",                                  
                                    maxWidth: 350,
                                    maxHeight: 455,
                                    marginTop: 0.5,
                                    marginBottom: 0,
                                    padding: 0,
                                    "& ul": { padding: 0 },
                                }}
                                >
                                    {renderedResults}
                                   {/* {{isLoading ? <LoadingSpinner /> : renderedResults}}*/}
                            </List>
                        </div>
                        </div>
                ) :
                (
                    <div id="results-area">
                            <div className={_isExpanded ? "result-area-expanded" : "results-area-collapsed"}>
                                <MessageInfo msg1="Hiện không có Shop nào trong danh sách tìm kiếm." msg2="Xin vui lòng thử lại!" />
                                {/*{isLoading ? <LoadingSpinner /> : <MessageInfo msg1="Hiện không có Shop nào trong danh sách tìm kiếm." msg2="Xin vui lòng thử lại!" />}*/}
                        </div>
                    </div>
                )
                }
                </div> </div>
        )
    }

}

export default Scanner;