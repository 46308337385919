import ReactDOM from "react-dom";
import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import logo from "./../img/iflower-logo.png";
import markerImg from "./../img/marker-1.png";
import markerImg1 from "./../img/marker-1.png";
//import locMarker from "./../img/pointer-marker.png";
//import homeMarker from "./../img/home-marker.png";
import homeMarkerBlue from "./../img/home-marker-blue.png";
import pointerMarkerRed from "./../img/pointer-marker-red.png";
import mapRadarBlue from "./../img/map-radar-blue.png";
import shopMarkerBlue from "./../img/shop-marker-blue.png";

//import { RiMenuLine } from "react-icons/ri";
//import { FcFinePrint } from "react-icons/fc";
//import Sidebar from "./Sidebar";

//import Result from "./Result";
//import Scanner from "./Scanner";
//import ResultCtrl from "./ResultCtrl";

import SearcherCtrl from "./SearcherCtrl";
import ScannerCtrl from "./ScannerCtrl";
import SearchButton from "./SearchButton";
import ScannerButton from "./ScannerButton";

import Popup from "./Popup";
import "./index.css";
import LoadingSpinner from "./LoadingSpinner";
import useGAEventTracker from './utils/useGAEventTracker';

import "./main.css";



export default function Map() {
    const mapContainer = useRef(null);
    const popUpRef = useRef(new mapboxgl.Popup({ offset: 15 }));
    const spinnerRef = useRef(new mapboxgl.Popup({ offset: 0 }));

    // Initialize the geolocate control.
    const geoLocateRef = useRef(new mapboxgl.GeolocateControl({
        positionOptions: {
            enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: true,      
    }));

    const elAppSpinner = document.createElement("div");
    const elSpinner = document.createElement("div");
    const el = document.createElement("div");
    const elMapRadar = document.createElement("div");
    const elSrc = document.createElement("div"); //selected home
    const elDest = document.createElement("div"); //selected desc
    
    var directionModeRef = useRef(false);
    var btnSearcher = useRef(new SearchButton());
    var btnScanner = useRef(new ScannerButton());
   
    //const gaEventTracker = useGAEventTracker('Map');
    //const map = useRef(null);
    
    const [map, setMap] = useState(null);
    const [geo, setGeo] = useState(null);
    const [geoScan, setGeoScan] = useState(null);
    const [searcher, setSearcher] = useState(null);
    const [scanner, setScanner] = useState(null);

    //const [btnSearcher, setBtnSearcher] = useState(null);
    //const [btnScanner, setBtnScanner] = useState(null);

    const [spinner, setSpinner] = useState(null);
    const [appSpinner, setAppSpinner] = useState(null);
    const [popup, setPopup] = useState(null);

    const [isSearchActivated, setIsSearchActivated] = useState(true);
  
    //const [isScanInit, setIsScanInit] = useState(null);
    //const [isSearchInit, setIsSearchInit] = useState(null);

    const [mapResult, setMapResult] = useState(null);
    const [selectMarker, setSelectMarker] = useState(null);
    const [selectMarkerScan, setSelectMarkerScan] = useState(null);
    const [srcMarker, setSrcMarker] = useState(null);
    const [srcMarkerScan, setSrcMarkerScan] = useState(null);
    const [destMarkerScan, setDestMarkerScan] = useState(null);
    const isCollapsed = localStorage.getItem('is-sidebar-collapsed');
    const [isExpanded, setIsExpanded] = useState(isCollapsed ? false : true);
    
    let mapMarkers = [];
    const [mapData, setMapData] = useState([]);
    const [mapSource, setMapSource] = useState([]);
    const [lng, setLng] = useState(105.8388);
    const [lat, setLat] = useState(21.0191);
    const [zoom, setZoom] = useState(12.5);

    //let currentCoords = useRef({ lng: 0, lat: 0 });

    const initGUI = (map, isSearch) => {        
        setIsSearchActivated(isSearch);
        if (isSearch) {            
            try {
                map.removeControl(btnSearcher);
            }
            catch { }
            map.addControl(btnScanner, "top-right");
        }
        else {          
            try {
                map.removeControl(btnScanner);
            }
            catch { }
            map.addControl(btnSearcher, "top-right");
        }
    }

    useEffect(() => {
        if (process.env.REACT_APP_MAP_ACCESS_TOKEN == null) {
            throw new Error("You need to have API_ACCESS_TOKEN (env), see README");
        }
        mapboxgl.accessToken = process.env.REACT_APP_MAP_ACCESS_TOKEN;

        const initializeMap = ({ setMap,  mapContainer }) => {
            //if (map) return; // Initialize map only once
            console.log("Initializing...");
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/mapbox/streets-v12", // stylesheet location
                //style: 'mapbox://styles/mapbox/light-v11', // style URL
                //style: "mapbox://styles/prakhar/cij2cpsn1004p8ykqqir34jm8",
                center: [lng, lat],
                zoom: 12.5,
                maxZoom: 19,
                minZoom: 6
            });

            if (!map) return; // Wait for map to initialize

            elAppSpinner.className = "loadSpinner";
            //elAppSpinner.style.backgroundImage = `url(${spinnerBlue})`;
            elAppSpinner.style.width = `0px`;
            elAppSpinner.style.height = `0px`;
            elAppSpinner.style.background = 'none';
            ReactDOM.render(<LoadingSpinner style={{
                zIndex: 6000, borderRadius: 50, width: 200
            }} />, elAppSpinner);

            const appSpinner = new mapboxgl.Marker(elAppSpinner);
            setAppSpinner(appSpinner);

            //Show Loading Spinner
            // TODO: determine init loc!
            //const { lng, lat } = map.getCenter();
            
            appSpinner.setLngLat([lng, lat]).addTo(map);

            //const spinner = new mapboxgl.Popup({ offset: 0 });
            const spinner = new mapboxgl.Marker(elSpinner);
            const selectMarker = new mapboxgl.Marker(elSrc);
            const srcMarker = new mapboxgl.Marker(el); //pointerMarkerRed
            const srcMarkerScan = new mapboxgl.Marker(elMapRadar); //pointerScannerBlue
            const destMarkerScan = new mapboxgl.Marker(elDest); // shop Marker
            
            const popup = new mapboxgl.Popup({ offset: 15 });
            const geo = new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true,
                showUserHeading: true,
            });

            const geoScan = new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true,
                showUserHeading: true,
            });

            const searcher = new SearcherCtrl({ map: map, geo: geo, geoScan: geoScan, selectMarker: selectMarker, srcMarker: srcMarker, srcMarkerScan: srcMarkerScan, destMarkerScan: destMarkerScan, spinner: spinner, popup: popup, handleRadar: handleRadar, directionModeRef: directionModeRef, geoLocate: geoLocateRef, handleZoom: handleZoom, handleToggler: handleToggler, useGAEventTracker: useGAEventTracker });

            const scanner = new ScannerCtrl({ map: map, geo: geo, geoScan: geoScan, selectMarker: selectMarker, srcMarker: srcMarker, srcMarkerScan: srcMarkerScan, destMarkerScan: destMarkerScan, spinner: spinner, popup: popup, handleRadar: handleRadar, directionModeRef: directionModeRef, geoLocate: geoLocateRef, handleZoom: handleZoom, handleToggler: handleToggler, useGAEventTracker: useGAEventTracker });

            btnSearcher = new SearchButton({ map: map, geo: geo, geoScan: geoScan, searcher: searcher, scanner: scanner, initGUI: initGUI, selectMarker: selectMarker, srcMarker: srcMarker, srcMarkerScan: srcMarkerScan, destMarkerScan: destMarkerScan, handleRadar: handleRadar, spinner: spinnerRef, directionModeRef: directionModeRef, geoLocate: geoLocateRef, handleZoom: handleZoom, handleToggler: handleToggler, useGAEventTracker: useGAEventTracker });

            btnScanner = new ScannerButton({ map: map, geo: geo, geoScan: geoScan, searcher: searcher, scanner: scanner, initGUI: initGUI, selectMarker: selectMarker, srcMarker: srcMarker, srcMarkerScan: srcMarkerScan, destMarkerScan: destMarkerScan, handleRadar: handleRadar, spinner: spinnerRef, directionModeRef: directionModeRef, geoLocate: geoLocateRef, handleZoom: handleZoom, handleToggler: handleToggler, useGAEventTracker: useGAEventTracker });

            //spinner.remove();
            map.on("load", () => {                
                if (!map) return;
                //spinner.setLngLat([lng, lat]).addTo(map);
                // create spinner node
                //const spinner = document.createElement('div');
                //spinner.style.cssText = 'width:0px; height:0px; margin: 0px 0px 0px 0px;border-radius:10px; border:0px solid #ddd; box-shadow: 0px 0px 5px 4px rgba(67,60,67,.16)';
                //spinner.style.zIndex = 1005;
                //ReactDOM.render(<LoadingSpinner style={{
                //    zIndex: 1000, borderRadius: 50, width: 200
                //}} />, spinner);
                //spinnerRef.current.setLngLat([lng, lat]).setDOMContent(spinner).addTo(map);
                //setSpinner(spinner);

                //Show loading spinner
                //const { lng, lat } = map.getCenter();
                //spinner.setLngLat([lng, lat]).addTo(map);

                setSpinner(spinner);
                setPopup(popup);
                setSrcMarker(srcMarker);
                setSrcMarkerScan(srcMarkerScan);
                setDestMarkerScan(destMarkerScan);
                setSelectMarker(selectMarker);
                setMap(map);
                setGeo(geo);
                setGeoScan(geoScan);               
                setSearcher(searcher);
                setScanner(scanner);

                //setBtnSearcher(btnSearcher);
                //setBtnScanner(btnScanner);

                map.addControl(searcher, "top-left");
                //map.addControl(scanner, "top-left");
                //map.addControl(btnSearcher, "top-right");
                //map.addControl(btnScanner, "top-right");
                initGUI(map, true);
                
                // DIRECT
                map.addSource('route-data', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'properties': {},
                        'geometry': {
                            'type': 'LineString',
                            'coordinates': []
                        }
                    }
                });

                map.addLayer({
                    'id': 'route',
                    'type': 'line',
                    'source': 'route-data',
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round',
                    },
                    'paint': {
                        'line-color': '#0A95FF',
                        'line-width': 5,
                        'line-opacity': 0.75
                    }
                });

                // Confirmed shop landing sites.
                map.addSource("confirmed-points-data", {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [],
                    },
                });

                map.loadImage(
                    markerImg1,
                    (error, image) => {
                        if (error) throw error;
                        // Add the image to the map style.
                        map.addImage('mapMarker1', image);
                        // Add the layer, and reference the data source above by name
                        map.addLayer({
                            id: "confirmed-points-layer",
                            source: "confirmed-points-data",
                            type: "symbol",
                            layout: {
                                // full list of icons here: https://labs.mapbox.com/maki-icons
                                "icon-image": 'mapMarker1', // this will put little croissants on our map
                                "icon-padding": 0,
                                "icon-allow-overlap": true,
                                'icon-size': 0.3
                            },
                            //type: "circle",
                            //interactive: true,
                            //paint: {
                            //    "circle-radius": 12,
                            //    "circle-color": "#F98C0D",
                            //},
                        });
                    }
                );
                //map.addLayer({
                //    id: 'shop-confirmed',
                //    source: "confirmed-points-data",
                //    //type: 'symbol',
                //    //layout: {
                //    //    'icon-image': 'rocket-11',
                //    //    'icon-size': 1,
                //    //    'icon-allow-overlap': true
                //    //}
                //    type: "circle",
                //    interactive: true,
                //    paint: {
                //        "circle-radius": 8,
                //        //"circle-color": "#F2A400",
                //        "circle-color": "#D33B37",
                //        //"circle-color": "rgba(253,237,57,1)",
                //        //"circle-color": "rgba(164,65,99,1)",
                //    },
                //});                

                map.addSource("radius-points-data", {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [],
                    },
                });

                // Draw the shop search radius on the map
                map.addLayer({
                    id: 'search-radius',
                    source: "radius-points-data",
                    //source: {
                    //    type: 'geojson',
                    //    data: { "type": "FeatureCollection", "features": [] }
                    //},
                    type: 'fill',
                    paint: {
                        'fill-color': '#D33B37',
                        'fill-opacity': 0.1
                    }
                });

                // CUSTOM
                // Add the data source for new a feature collection with no features
                map.addSource("custom-points-data", {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [],
                    },
                });
                // Add the layer, and reference the data source above by name
                map.addLayer({
                    id: "custom-points-layer",
                    source: "custom-points-data",
                    //   type: "symbol",
                    //   layout: {
                    //     // full list of icons here: https://labs.mapbox.com/maki-icons
                    //     "icon-image": "bakery-15", // this will put little croissants on our map
                    //     "icon-padding": 0,
                    //     "icon-allow-overlap": true,
                    //   },
                    type: "circle",
                    interactive: true,
                    paint: {
                        "circle-radius": 10,
                        //"circle-color": "#F2A400",
                        "circle-color": "#F98C0D",
                        //"circle-color": "rgba(253,237,57,1)",
                        //"circle-color": "rgba(164,65,99,1)",
                    },
                });

                //Add layer to show all shop sighting locations
                //map.addLayer({
                //    id: 'shops',
                //    source: "custom-points-data",
                //    type: 'circle',
                //    paint: {
                //        'circle-color': '#5555f6',
                //        'circle-radius': 8,
                //        'circle-opacity': .5
                //    }
                //});

                //USUAL
                // Add the data source for new a feature collection with no features
                map.addSource("usual-points-data", {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [],
                    },
                });
                // Add the layer, and reference the data source above by name
                map.addLayer({
                    id: "usual-points-layer",
                    source: "usual-points-data",
                    //   type: "symbol",
                    //   layout: {
                    //     // full list of icons here: https://labs.mapbox.com/maki-icons
                    //     "icon-image": "bakery-15", // this will put little croissants on our map
                    //     "icon-padding": 0,
                    //     "icon-allow-overlap": true,
                    //   },
                    type: "circle",
                    interactive: true,
                    paint: {
                        "circle-radius": 10,
                        "circle-color": "#F98C0D",
                    },
                });

                //HIGHTLIGHT
                // Add the data source for new a feature collection with no features
                map.addSource("hightlight-points-data", {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [],
                    },
                });
                map.loadImage(
                    markerImg,
                    (error, image) => {
                        if (error) throw error;
                        // Add the image to the map style.
                        map.addImage('mapMarker', image);
                        // Add the layer, and reference the data source above by name
                        map.addLayer({
                            id: "hightlight-points-layer",
                            source: "hightlight-points-data",
                            type: "symbol",
                            layout: {
                                // full list of icons here: https://labs.mapbox.com/maki-icons
                                "icon-image": 'mapMarker', // this will put little croissants on our map
                                "icon-padding": 0,
                                "icon-allow-overlap": true,
                                'icon-size': 0.3
                            },
                            //type: "circle",
                            //interactive: true,
                            //paint: {
                            //    "circle-radius": 12,
                            //    "circle-color": "#F98C0D",
                            //},
                        });
                    }
                );

                //CUSTOMIZE 
                // Add the layer, and refer
                // Empty Source
                //const text_points_data_GeoJsonSource = {
                //    type: 'geojson',
                //    data: textfeatureCollection //Initially this is empty
                //  };

                // Add the data source for new a feature collection with no features
                map.addSource("text-points-data", {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [],
                    },
                });

                //Create Text Layer with textGeoJsonSource
                const sectionTextLayer = {
                    id: "sectionTextLayer",
                    type: "symbol",
                    source: "text-points-data",
                    interactive: true,
                    paint: {
                        "text-color": "rgba(0,0,0,255)", //Color of your choice
                        //"text-color": "rgba(164,65,99,1)", //Color of your choice
                        //"text-halo-blur": textHaloBlur,
                        //"text-halo-color": textHaloColor,
                        //"text-halo-width": textHaloWidth,
                        //"text-opacity": 1
                    },
                    layout: {
                        "text-field": ['get', 't'], //This will get "t" property from your geojson
                        //"text-font": ['Calibri'],
                        "text-size": ['get', 's'],
                        "text-rotation-alignment": "auto",
                        "text-allow-overlap": true,
                        "text-anchor": "top"
                    }
                };


                //Populate Data to Source
                const textFeatureList = [];
                //const objJson = JSON.parse(textGeoJSONFeature);
                textFeatureList.push(
                    {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [parseFloat(114.9061), parseFloat(14.2901)]
                        },
                        properties: {
                            t: "BIỂN ĐÔNG", //Text display on Map
                            s: 17
                        }
                    },
                    {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [parseFloat(112.8046), parseFloat(16.6072)]
                        },
                        properties: {
                            t: "Quần đảo Hoàng Sa (Việt Nam)", //Text display on Map
                            s: 12
                        }
                    },
                    {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [parseFloat(115.7708), parseFloat(10.6287)]
                        },
                        properties: {
                            t: "Quần đảo Trường Sa (Việt Nam)", //Text display on Map
                            s: 12
                        }
                    },
                    {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [parseFloat(108.0835), parseFloat(20.4965)]
                        },
                        properties: {
                            t: "VỊNH BẮC BỘ", //Text display on Map
                            s: 15
                        }
                    },
                    {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [parseFloat(108.016256), parseFloat(21.195290)]
                        },
                        properties: {
                            t: "Đ. Bồ Cát", //Text display on Map
                            s: 11
                        }
                    },
                    {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [parseFloat(107.728831), parseFloat(20.132233)]
                        },
                        properties: {
                            t: "Đ. Bạch Long Vỹ", //Text display on Map
                            s: 11
                        }
                    },
                    {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [parseFloat(103.4884), parseFloat(9.3097)]
                        },
                        properties: {
                            t: "Đ. Thổ Chu (Việt Nam)", //Text display on Map
                            s: 11
                        }
                    }
                );

                const textFeatureCollection = {
                    type: "FeatureCollection",
                    features: textFeatureList
                }

                map.getSource("text-points-data").setData(textFeatureCollection);

                //Override base map with Occean Polygon layer
                // Add a data source containing GeoJSON data.
                map.addSource('eastern-sea-polygon', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            // These coordinates outline
                            'coordinates': [
                                [
                                    [115.6548, 16.3424],
                                    [115.3419, 16.3424],
                                    [114.0761, 11.7272],
                                    [114.4546, 11.7272],
                                    [115.6548, 16.3424]

                                ]
                            ]
                        }
                    }
                });
                map.addSource('gulf-sea-polygon', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            // These coordinates outline
                            'coordinates': [
                                [
                                    [102.5184, 9.8762],
                                    [101.7436, 9.8762],
                                    [101.4207, 9.0768],
                                    [102.7949, 9.0768],
                                    [102.5184, 9.8762]
                                ]
                            ]
                        }
                    }
                });
                map.addSource('gulf-northern-polygon', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            // These coordinates outline
                            'coordinates': [
                                [
                                    [108.3600, 20.6863],
                                    [107.2271, 20.6863],
                                    [107.6586, 20.1768],
                                    [108.9433, 20.1768],
                                    [108.3600, 20.6863]
                                ]
                            ]
                        }
                    }
                });

                map.addSource('gulf-northern-polygon-2', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            // These coordinates outline
                            'coordinates': [
                                [
                                    [108.8100, 20.1204],
                                    [107.2177, 20.1204],
                                    [106.5206, 19.1002],
                                    [107.9526, 19.1002],
                                    [108.8100, 20.0105]
                                ]
                            ]
                        }
                    }
                });

                // Add a new layer to visualize the polygon.
                map.addLayer({
                    'id': 'easternSea',
                    'type': 'fill',
                    'source': 'eastern-sea-polygon', //reference the data source
                    'layout': {},
                    'paint': {
                        'fill-color': '#66CBFF', // occean blue color fill
                        'fill-opacity': 1
                    }
                });
                map.addLayer({
                    'id': 'gulf-northern',
                    'type': 'fill',
                    'source': 'gulf-northern-polygon', //reference the data source
                    'layout': {},
                    'paint': {
                        'fill-color': '#99DDFF', // occean blue color fill
                        'fill-opacity': 1
                    }
                });
                map.addLayer({
                    'id': 'gulf-northern-2',
                    'type': 'fill',
                    'source': 'gulf-northern-polygon-2', //reference the data source
                    'layout': {},
                    'paint': {
                        'fill-color': '#99DDFF', // occean blue color fill
                        'fill-opacity': 1
                    }
                });
                map.addLayer({
                    'id': 'gulf',
                    'type': 'fill',
                    'source': 'gulf-sea-polygon', //reference the data source
                    'layout': {},
                    'paint': {
                        'fill-color': '#99DDFF', // occean blue color fill
                        'fill-opacity': 1
                    }
                });

                //// Add a outline around the polygon to test
                //map.addLayer({
                //    'id': 'outline2',
                //    'type': 'line',
                //    'source': 'gulf-northern-polygon',
                //    'layout': {},
                //    'paint': {
                //        'line-color': '#FFF',
                //        'line-width': 1
                //    }
                //});

                //End Override Occean

                map.addLayer(sectionTextLayer);

                //Set language
                const language = "vi";
                // Use setLayoutProperty to set the value of a layout property in a style layer.
                // The three arguments are the id of the layer, the name of the layout property,
                // and the new property value.
                map.setLayoutProperty('country-label', 'text-field', ['get', `name_${language}`]);

                elSpinner.className = "loadSpinner";
                //elSpinner.style.backgroundImage = `url(${spinnerBlue})`;
                elSpinner.style.width = `0px`;
                elSpinner.style.height = `0px`;
                elSpinner.style.background = 'none';
                ReactDOM.render(<LoadingSpinner style={{
                    zIndex: 1000, borderRadius: 50, width: 200
                }} />, elSpinner);

                //elSrc.addEventListener('click', () => {
                //    //window.alert(`Vị trí hiện tại của bạn: [${currentCoords.lng}, ${currentCoords.lat}]`);
                //    //console.log(`Vị trí hiện tại của bạn: [${currentCoords.lng}, ${currentCoords.lat}] `);
                //});

                // Add marker to map
                //const el = document.createElement("div");
                el.className = "marker";
                el.style.backgroundImage = `url(${pointerMarkerRed})`;
                el.style.width = `40px`;
                el.style.height = `40px`;
                el.style.backgroundSize = '100%';
                //el.addEventListener('click', () => {
                //    window.alert(`LOC: [${lng}, ${lat}]`);
                //});

                elSrc.className = "marker";
                elSrc.style.backgroundImage = `url(${homeMarkerBlue})`;
                elSrc.style.width = `40px`;
                elSrc.style.height = `40px`;
                elSrc.style.backgroundSize = '100%';
                //elSrc.addEventListener('click', () => {
                //    //window.alert(`Vị trí hiện tại của bạn: [${currentCoords.lng}, ${currentCoords.lat}]`);
                //    //console.log(`Vị trí hiện tại của bạn: [${currentCoords.lng}, ${currentCoords.lat}] `);
                //});

                elDest.className = "marker";
                elDest.style.backgroundImage = `url(${shopMarkerBlue})`;
                elDest.style.width = `40px`;
                elDest.style.height = `40px`;
                elDest.style.backgroundSize = '100%';
                //elSrc.addEventListener('click', () => {
                //    //window.alert(`Vị trí hiện tại của bạn: [${currentCoords.lng}, ${currentCoords.lat}]`);
                //    //console.log(`Vị trí hiện tại của bạn: [${currentCoords.lng}, ${currentCoords.lat}] `);
                //});

                elMapRadar.className = "marker";
                elMapRadar.style.backgroundImage = `url(${mapRadarBlue})`;
                elMapRadar.style.width = `40px`;
                elMapRadar.style.height = `40px`;
                elMapRadar.style.backgroundSize = '100%';
                //elMapRadar.addEventListener('click', () => {
                //    window.alert(`Vị trí hiện tại của bạn: [${currentCoords.lng}, ${currentCoords.lat}]`);
                //    //console.log(`Vị trí hiện tại của bạn: [${currentCoords.lng}, ${currentCoords.lat}] `);
                //});
                //map.add(mapRadar, 'top-right');
                //mapRadar.addTo(map);
               
                //var topPane = map._createPane('leaflet-top-pane', map.getPanes().mapPane);
                //var topLayer = mblabels.addTo(map);
                //topPane.appendChild(topLayer.getContainer());

                //var MyControl = mapboxgl.Control.extend({
                //    options: {
                //        position: 'bottomleft'
                //    },

                //    onAdd: function (map) {
                //        // create the control container with a particular class name
                //        // ** you can add the image to the div as a background image using css
                //        //var container = L.DomUtil.create('div', 'my-custom-control');

                //        // ... initialize other DOM elements, add listeners, etc.
                //        //return container;

                //        return mapRadar;
                //    }
                //});

                //map.addControl(new MyControl());
                //TODO:remove
                //currentMarkerRef.current.setLngLat([lng, lat]);
                //spinnerRef.current.remove();

                appSpinner.remove();
                console.log("Loaded!!!");
            });

            //if (!map) return; // wait for map to initialize
            map.on("move", () => {
                if (!map) return;
                setLng(map.getCenter().lng.toFixed(4));
                setLat(map.getCenter().lat.toFixed(4));
                //if (map.getZoom().toFixed(1) >= 6) {
                //    //setZoom(map.getZoom().toFixed(1));
                //}                    
                //else
                //    map.setZoom(6.0);               
            });

            //map.on("moveend", async () => {
            //    if (!map) return;
            //    // Get new center coordinates
            //    const { lng, lat } = map.getCenter();

            //    currentMarkerRef.current.setLngLat([lng, lat]);
            //    //console.log(`${curCoords.lng}, ${curCoords.lat}`);
            //    //console.log(`GRef Current location: [${currentCoords.lng}, ${currentCoords.lat}] `);
            //});

            // Change cursor to pointer when user hovers over a clickable feature
            map.on("mouseenter", ["custom-points-layer", "confirmed-points-layer"], (e) => {
                if (!map) return;
                if (e.features.length) {
                    map.getCanvas().style.cursor = "pointer";
                }
            });

            // Reset cursor to default when user is no longer hovering over a clickable feature
            map.on("mouseleave", ["custom-points-layer", "confirmed-points-layer"], () => {
                if (!map) return;
                map.getCanvas().style.cursor = "";
                //popUpRef.current.remove();
            });

            //map.on("mouseenter", currentMarkerRef.current.id, () => {
            //    map.getCanvas().style.cursor = "pointer";
            //});

            //map.on("mouseleave", currentMarkerRef.current.id, () => {
            //    map.getCanvas().style.cursor = "default";
            //});

            //map.on('sourcedata', (e) => {
            //    //if (e.sourceId === "confirmed-points-data" && e.isSourceLoaded) {
            //    if (e.sourceId === "confirmed-points-data") {
            //        const joinedPoints = map.querySourceFeatures("confirmed-points-data");
            //        console.log("Count: " +  joinedPoints.length);
            //        joinedPoints.map((item, i) => { console.log("Props: " + item.properties['name']); });
            //        setMapSource(joinedPoints);

            //        //const renderedResults = this.props.mapSource.map((item, i) => (<div>{item.properties['name']}</div>
            //        //    /*<Shop key={i} data={item} handleHover={this.handleHover} handleDirections={this.handleDirections} handleCalls={this.handleCalls} />*/
            //        //));
                    
            //        //const mapResult = new ResultCtrl({ map: map, geo: geo, mapSource: mapSource, selectMarker: selectMarker, srcMarker: srcMarker, getListData: getListData, handleRadar: handleRadar, spinner: spinnerRef, directionModeRef: directionModeRef, marker: currentMarkerRef, geoLocate: geoLocateRef, handleZoom: handleZoom, handleDirection: handleDirection, handleToggler: handleToggler, useGAEventTracker: useGAEventTracker });
            //        //setMapResult(mapResult);
            //        //try {
            //        //    map.removeControl(mapResult);
            //        //}
            //        //catch { }
            //        //map.addControl(mapResult, "top-left");
            //    }
            //});

            //map.on('click', function (e) {               
            //    // handle Toggler
            //    if (isExpanded) {
            //        setIsExpanded(false);
            //        localStorage.setItem('is-sidebar-collapsed', true);
            //    }
            //});

            // setup popup display on the marker
            map.on("click", ["custom-points-layer", "confirmed-points-layer"], function (e) {
                if (!map) return;
          
                // var features = map.queryRenderedFeatures(e.point, {
                //   layers: ["trucks", "trucks-highlight"],
                //   radius: 10,
                //   includeGeometry: true,
                // });
                // if (!features.length) return;
                // var feature = features[0];
                // new mapboxgl.Popup()
                //   .setLngLat(feature.geometry.coordinates)
                //   .setHTML(formatHTMLforMarker(feature.properties))
                //   .addTo(map);

                //console.log("App Message: Selected Object");
                if (e.features.length) {
                    const feature = e.features[0];                   
                    // create popup node
                    const popupNode = document.createElement('div');
                    //popupNode.style.cssText = 'width:200px; height:200px; margin: 10px 0px 0px 0px;border-radius:10px; border:1px solid #2296F4; box-shadow: 0px 0px 5px 4px rgba(67,60,67,.16), z-index:3000';
                    popupNode.style.zIndex = 2005;
                    ReactDOM.render(<Popup feature={feature} style={{
                        zIndex: 3000, borderRadius: 50, width: 200
                    }} />, popupNode);
                    // set popup on map
                    popUpRef.current
                        .setLngLat(feature.geometry.coordinates)
                        .setDOMContent(popupNode)
                        .addTo(map);
                    //popUpRef.addClassName('classPopup');
                }
            });

            //spinner.remove();
        };

        if (!map) {
            initializeMap({ setMap, mapContainer });            
        }
    }, [map]);

    //const setListData = (data) => {
    //    setMapData(data);
    //    console.log("setMapData: " + data);
    //}

    //const getListData = () => {
    //    console.log("MapData: " + this.props.map.getSource("confirmed-points-data")._option.data.features);
    //    return this.props.map.getSource("confirmed-points-data")._option.data.features;
    //}

    const handleToggler = () => {
        if (isExpanded) {
            setIsExpanded(false);
            localStorage.setItem('is-sidebar-collapsed', true);  
            return;
        }
        setIsExpanded(true);        
        localStorage.removeItem('is-sidebar-collapsed');
    }

    const handleZoom = (size) => {
        //map.setZoom(13.4);
        map.flyTo(map.getCenter(), 15.4);
        console.log("Handle Current zoom: " + size);
    }

    //const handleDirection = (isStart = true, lng = 0, lat = 0) => {        
    //    if (lng === 0 || lat === 0) {
    //        currentMarkerRef.current.remove();          
    //        destMarker.current.remove();            
    //        return;
    //    }

    //    if (!isStart) {
    //        currentMarkerRef.current.remove();
    //        // Remove previous markers        
    //        //mapMarkers.forEach((marker) => marker.remove());
    //        //mapMarkers = [];
    //        destMarker.current.remove();
    //        destMarker.current.setLngLat([lng, lat]).addTo(map);
    //    }
    //    else {
    //        //geoLocateRef.current.trigger();
    //        currentMarkerRef.current.remove();
    //        currentMarkerRef.current.setLngLat([lng, lat]).addTo(map);
    //        destMarker.current.remove();            
    //    }
    //}

    const handleRadar = (map, size) => {
        map.setZoom(size);
        map.flyTo(map.getCenter(), size);
        console.log("Handle Current zoom: " + size);
    }

    return (       
        <div>
            <div className="app-header" >
                <a href="/">
                    <img id="logo" src={logo} alt="iFlower" width="90px" />
                </a>
                
                {/*<FcFinePrint className="icon" onClick={handleToggler} />*/}
                {/*<RiMenuLine className="icon" onClick={handleToggler} />  */}
                {/*<div className={isExpanded ? "Sidebar" : "SidebarCollapsed"}>*/}
                {/*    <Sidebar map={map} scanner={scanner} curCoords={currentCoords} popup={popUpRef} spinner={spinnerRef} directionModeRef={directionModeRef} marker={currentMarkerRef} geoLocate={geoLocateRef} handleZoom={handleZoom} handleDirection={handleDirection} handleToggler={handleToggler} useGAEventTracker={useGAEventTracker} />*/}
                   
                {/*</div>*/}
            </div>
            
            <div ref={mapContainer} className="map-container" />
            <div className="footer">
                {/*Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}*/}
                <div style={{ margin: "5px 0px 0px 15px" }}>
                    <span style={{ marginTop: "8px", }}>Phát triển bởi © iFlower.vn</span>
                    <a href="/" className="watermark" style={{ margin: "5px 15px 5px 5px", cursor: "pointer", float: "right" }}>
                        <img src={logo} alt="iFlower" width="64px" />
                    </a>
                </div>
            </div>   
        </div>
    );
}


