import React from "react";
import "./index.css";

export default function LoadingSpinner() {
    return (
       //<div id="init-layer"><img src="https://vinflower.com/wp-content/uploads/2023/06/5539763.png" />
       //       <div className="spinner"></div>             
       //   </div>
        <div className="spinner-container">       
            <div className="loading-spinner"></div>
        </div>

  );
}