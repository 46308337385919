import React, { useEffect, Component } from 'react';
//import ReactDOM from 'react-dom';
import { Route } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
//import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from 'react-ga';

//import { Home } from './components/pages/Home';
//import { Layout } from './components/pages/Layout';
//import { FetchData } from './components/pages/FetchData';
//import { Counter } from './components/pages/Counter';
import Map from './components/Map';
//import logo from "./img/six-logo-orange.png";
//import six_logo_orange from "./img/six-logo-orange.png";
//import iflower_logo_blue from "./img/iflower-logo-blue.png";
//import './custom.css'

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

//export default class App extends Component {
//    static displayName = App.name;   

//    render() {
//        return (
//            //<Layout>
//            //    <Route exact path='/' component={Home} />
//            //    <Route path='/counter' component={Counter} />
//            //    <Route path='/fetch-data' component={FetchData} />
//            //    <Route path='/contacts' component={Contact} />
//            //    <Route path='/map' component={Map} />
//            //</Layout>

//            <div>
//                <div className="bodyStyle">
//                    <a href="/" className="watermark">
//                        <img src={logo} alt="six logo" width="35px" style={{ margin:"-15px 0px 0px 15px" }} />
//                    </a>
//                    <Router>
//                        <main>
//                            {/*<nav>*/}
//                            {/*    <ul>*/}
//                            {/*        <li><a href="/">Click to access your Mapped Shops</a></li>*/}
//                            {/*        */}{/*<li><a href="/counter">About</a></li>*/}
//                            {/*        */}{/*<li><a href="/contacts">Contacts</a></li>*/}
//                            {/*        <li><a href="/map">iFlower</a></li>*/}
//                            {/*        <li><a href="/map">iFood</a></li>*/}
//                            {/*        <li><a href="/map">iFashion</a></li>*/}
//                            {/*    </ul>*/}
//                            {/*</nav>*/}                            

//                            <Route exact path='/' component={Map} />
//                            {/*<Route path='/counter' component={Counter} />*/}
//                            {/*<Route path="/contacts" component={Contacts} />*/}
//                            {/*<Route path="/six" component={Home} />*/}
//                        </main>
//                    </Router>
//                </div>             
             
//                {/*<div className="footer-right1">Empowered by SIX</div>*/}
//            </div>
//        );
//    }
//}

//const Home = () => (
//    <div>
//        <h2>Home</h2>
//    </div>
//);

//const ContactUs = () => (
//    <Fragment>
//        <h1>Contact</h1>
//        <FakeText />
//    </Fragment>
//);

//const AboutUs = () => (
//    <div>
//        <h2>About</h2>
//    </div>
//);

//const FakeText = () => (
//    <p>
//        Welcome Contact Us
//    </p>
//);

const App = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Router>
           
                {/*<Route path="/" element={<Home />} />*/}
                {/*<Route path="/aboutus" element={<AboutUs />} />*/}
                {/*<Route path="/contactus" element={<ContactUs />} />*/}
            
                <Route path='/' component={Map} />
                {/*<Route path="/six" component={Home} />*/}
          
        </Router>
    );
}

export default App;
