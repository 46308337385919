import React from "react";
import ReactDOM from "react-dom";
import Searcher from "./Searcher";
import LoadingSpinner from "./LoadingSpinner";
import "./index.css";

class SearcherCtrl extends React.Component {
    constructor(props) {
        super(props);        
        this.onClick = this.onClick.bind(this);
        this.initGeoLoc = this.initGeoLoc.bind(this);
        this.initSearcher = this.initSearcher.bind(this);
        this.initMarker = this.initMarker.bind(this);
        this.initSpinner = this.initSpinner.bind(this);
        this.handleDirection = this.handleDirection.bind(this);
    }

    onClick(e) {
        console.log('clicked');
    }

    initGeoLoc() {
        const map = this.props.map;
        const geo = this.props.geo;
        const geoScan = this.props.geoScan;
        //const mapResult = this.props.mapResult;
        //const mapSource = this.props.mapSource;
        try {
            map.removeControl(geo);
            map.removeControl(geoScan);
        }
        catch { }

        map.addControl(geo, 'top-right');
    }

    initSearcher(arrLngLat) {
        console.log("Ctrl: initSearch");
        //const srcMarker = this.props.marker;
        //if (srcMarker)
        //    srcMarker.current.setLngLat([lng, lat]);
        const map = this.props.map;
        const srcMarker = this.props.srcMarker;
        const srcMarkerScan = this.props.srcMarkerScan;
        if (!arrLngLat) {
            const { lng, lat } = map.getCenter();

            if (srcMarkerScan)
                srcMarkerScan.remove();
            srcMarker.remove();
            //srcMarker.setLngLat([lng, lat]).addTo(map);
        }
        else {
            if (srcMarkerScan)
                srcMarkerScan.remove();

            //TODO: recheck            
            srcMarker.remove();
            //srcMarker.setLngLat(arrLngLat).addTo(map);

            //Reset Map Layer & Source
            if (map.getLayer("search-radius")) {
                map.removeLayer("search-radius");
            }
            // Reset Map Source
            if (map.getSource("radius-points-data")) {
                map.removeSource("radius-points-data");
            }

            map.addSource("radius-points-data", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [],
                },
            });

            map.addLayer({
                id: 'search-radius',
                source: "radius-points-data",
                //source: {
                //    type: 'geojson',
                //    data: { "type": "FeatureCollection", "features": [] }
                //},
                type: 'fill',
                paint: {
                    'fill-color': '#D33B37',
                    'fill-opacity': 0.1
                }
            });
        }
    }

    initMarker(arrLngLat) {
        console.log("Ctrl: initMarker");
        //const srcMarker = this.props.marker;
        //if (srcMarker)
        //    srcMarker.current.setLngLat([lng, lat]);
        const map = this.props.map;
        const srcMarker = this.props.srcMarker;
        const srcMarkerScan = this.props.srcMarkerScan;
        if (!arrLngLat) {            
            const { lng, lat } = map.getCenter();
            
            if (srcMarkerScan)
                srcMarkerScan.remove();

            srcMarker.remove();
            srcMarker.setLngLat([lng, lat]).addTo(map);
        }
        else {    
            if (srcMarkerScan)
                srcMarkerScan.remove();

            //TODO: recheck            
            srcMarker.remove();
            srcMarker.setLngLat(arrLngLat).addTo(map);

            //Reset Map Layer & Source
            if (map.getLayer("search-radius")) {
                map.removeLayer("search-radius");
            }
            // Reset Map Source
            if (map.getSource("radius-points-data")) {
                map.removeSource("radius-points-data");
            }

            map.addSource("radius-points-data", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [],
                },
            });

            map.addLayer({
                id: 'search-radius',
                source: "radius-points-data",
                //source: {
                //    type: 'geojson',
                //    data: { "type": "FeatureCollection", "features": [] }
                //},
                type: 'fill',
                paint: {
                    'fill-color': '#D33B37',
                    'fill-opacity': 0.1
                }
            });
        }
    }

    initSpinner(isDisplayed) {
        const map = this.props.map;
        const spinner = this.props.spinner;
        const { lng, lat } = map.getCenter();
        if (isDisplayed) {
            //const spinnerNode = document.createElement('div');
            //spinnerNode.style.cssText = 'width:0px; height:0px; margin: 0px 0px 0px 0px;border:0px solid #FFF;background-color: transparent;margin-top:200;';
            //spinnerNode.style.zIndex = 1005;
            //ReactDOM.render(<LoadingSpinner style={{
            //    zIndex: 1000, borderRadius: 50, width: 200
            //}} />, spinnerNode);
            //spinner.setLngLat([lng, lat]).setDOMContent(spinnerNode).addTo(map);   
            spinner.setLngLat([lng, lat]).addTo(map);
        } else {
            spinner.remove();
        }
    }

    handleDirection(isStart, lng, lat) {        
        const map = this.props.map;
        // Revert
        const selectMarker = this.props.selectMarker;
        const srcMarker = this.props.srcMarker;
        const srcMarkerScan = this.props.srcMarkerScan;
        const destMarkerScan = this.props.destMarkerScan;
        if (lng === 0 || lat === 0) {
            if (selectMarker)
                selectMarker.remove();
            if (srcMarker)
                srcMarker.remove();
            if (srcMarkerScan)
                srcMarkerScan.remove();
            if (destMarkerScan)
                destMarkerScan.remove();
            return;
        }

        if (!isStart) {
            srcMarker.remove();
            srcMarkerScan.remove();
            // Remove previous markers        
            //mapMarkers.forEach((marker) => marker.remove());
            //mapMarkers = [];
            selectMarker.remove();
            selectMarker.setLngLat([lng, lat]).addTo(map);

            if (map.getLayer("search-radius")) {
                map.removeLayer("search-radius");
            }
            // Reset Map Source
            if (map.getSource("radius-points-data")) {
                map.removeSource("radius-points-data");
            }

            map.addSource("radius-points-data", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [],
                },
            });

            map.addLayer({
                id: 'search-radius',
                source: "radius-points-data",
                //source: {
                //    type: 'geojson',
                //    data: { "type": "FeatureCollection", "features": [] }
                //},
                type: 'fill',
                paint: {
                    'fill-color': '#D33B37',
                    'fill-opacity': 0.1
                }
            });
        }
        else {
            srcMarker.remove();
            srcMarkerScan.remove();
            srcMarker.setLngLat([lng, lat]).addTo(map);
            selectMarker.remove();
        }
    }

    onAdd() {
        this.container = document.createElement("div");
        //this.container.className = 'custom-control';
        //this.map = map;
        ReactDOM.render(<Searcher map={this.props.map} geo={this.props.geo} geoScan={this.props.geoScan} selectMarker={this.props.selectMarker} srcMarker={this.props.srcMarker} srcMarkerScan={this.props.srcMarkerScan} initGeoLoc={this.initGeoLoc} initMarker={this.initMarker} initSpinner={this.initSpinner} curCoords={this.props.currentCoords} spinner={this.props.spinner} popup={this.props.popup} handleRadar={this.props.handleRadar} directionModeRef={this.props.directionModeRef} marker={this.props.currentMarkerRef} geoLocate={this.props.geoLocateRef} handleZoom={this.props.handleZoom} resetSource={this.resetSource} handleDirection={this.handleDirection} handleToggler={this.props.handleToggler} useGAEventTracker={this.props.useGAEventTracker} />, this.container);
        return this.container;
    }

    onRemove() {
        this.container.parentNode.removeChild(this.container);
        //this.map = undefined;
    }
}

export default SearcherCtrl;