import React from "react";
//import logo from "./../img/iflower-logo.png";
import bg from "./../img/iflower-intro-bg.png";

export default function Intro() {
    return (
        <div id="container">
            <div className="appBannerIntro">
                <img src={bg} alt="App Intro" className="bannerAppIntro"/>
            </div>
            <div className="appIntroBG"></div>
            <div className="appIntroTitle"> <h4>Welcome to <a href="https://iflower.vn">iFlower.vn</a> </h4></div>
            <div className="appIntroDesc"> <h4>Nền tảng tìm kiếm Shop hoa tươi toàn quốc</h4></div>
            <div className="textIntro">
                <div className="appIntroTextBlock" style={{ backgroundColor: "#F1F3F4", color: "dimgray" }}>
                    Dễ dàng tìm kiếm địa điểm chính xác, ưng ý nhất trên mạng lưới hàng nghìn Shop hoa toàn quốc.
                </div>
            </div>
            <div className="textIntro">
                <div className="appIntroTextBlock" style={{ backgroundColor: "#E8E8E8", color: "dimgray" }}>
                    Shop hoa có cơ hội tiếp cận hàng triệu Khách hàng trong và ngoài nước có nhu cầu tặng hoa.
                  </div>                 
            </div>
            <div className="textIntro">
                <div className="appIntroTextBlock" style={{ backgroundColor: "#DBCFC8", color: "dimgray" }}>
                    Phát triển, định vị Thương hiệu và Sản phẩm Shop hoa trên nền tảng Bản đồ số.
                  </div>
            </div>
        </div>

        //<div className="intro">
        //    <h4>
        //        <a href="https://iflower.vn">iFlower.vn</a> - Nền tảng tìm kiếm địa điểm Shop hoa tươi toàn quốc
        //    </h4>
        //    <a href="/" className="watermark">
        //        <img src={bg} alt="iFlower" className="landing-image"/>
        //        {/*<img src={logo} alt="iFlower" width="90px" style={{ margin: "5px 15px 0px 105px", cursor: "pointer" }} />*/}
        //    </a>
        //    <p>
        //        Người dùng dễ dàng tìm kiếm địa điểm ưng ý nhất thông qua mạng lưới hàng nghìn Shop hoa toàn quốc tại iFlower.vn.
        //  </p>
          
        //    <p>
        //        Shop hoa có cơ hội tiếp cận hàng triệu khách hàng trong nước và nước ngoài có nhu cầu tặng hoa tại Việt Nam.
        //  </p>
        //    <p>
        //        Phát triển, định vị Thương hiệu và Sản phẩm Shop hoa trên nền tảng BẢN ĐỒ SỐ thông minh cùng nhiều trải nghiệm thú vị.
        //  </p>
        //</div>
    );
}
