import React from "react";
//import { RiMenuLine } from "react-icons/ri";
//import { setTimeout } from 'timers/promises'
import * as turf from '@turf/turf';
import Intro from "./Intro";
import Shop from "./Shop";
import { getAllShops, getDirection } from "./../api/apiService";
import { removeVieTones, removeSpecName, removeSpecAddress} from "./utils/strConverter";
import { addSpecAddressRule, onlyUnique, aggregateKeyList, aggregateKey } from "./utils/strConverter";
import List from "@mui/material/List";
//import { FcFinePrint } from "react-icons/fc";
//import ListItem from "@mui/material/ListItem";
//import ListItemText from "@mui/material/ListItemText";
//import ListSubheader from "@mui/material/ListSubheader";
//import LoadingSpinner from "./LoadingSpinner";
import MessageInfo from "./MessageInfo";
//import search from "./../img/search-blue-1.png";
import searchBlue from "./../img/search-blue.png";
import listViewMode from "./../img/show-list-blue.png";
import mapViewMode from "./../img/show-map-blue.png";
import "./index.css";
import "./main.css";

class Searcher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            results: [],
            directions: [],
            isDirectMode: false,
            isDirectInit: false,
            isDirectLocateInit: true,
            isDirectPoint: false,
            currentCoords: { lng: 0, lat: 0 },
            currentZoom: 12.5,
            currentLng: 0,
            currentLat: 0,
            query: "",
            firstLoad: true,
            isLoading: false,
            filteredList: [],
            //isCollapsed: localStorage.getItem('is-sidebar-collapsed'),
            //isExpanded: isCollapsed ? false : true,    
            isCollapsed: false,
            isExpanded: true,
            destLng: 0,
            destLat: 0,
            startLng: 0,
            startLat: 0,
            
        };
        this.onChange = this.onChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleClickedItem = this.handleClickedItem.bind(this);
        this.handleDirections = this.handleDirections.bind(this);
        this.handleCalls = this.handleCalls.bind(this);
        this.handleToggler = this.handleToggler.bind(this);
        this.handleListToggler = this.handleListToggler.bind(this);
        //this.state.isExpanded = this.state.isCollapsed ? false : true;
    }
   
    handleToggler = () => {
        if (this.state.isExpanded) {
            this.state.isExpanded = false;
            localStorage.setItem('is-sidebar-collapsed', true);
            console.log(localStorage.getItem('is-sidebar-collapsed'));
            console.log("isExpanded: " + this.state.isExpanded);
            return;
        }
        this.state.isExpanded = true;
        console.log("isExpanded: " + this.state.isExpanded);
        localStorage.removeItem('is-sidebar-collapsed');
    }

    async fetchShops() {
        //const map = this.props.map;
        //const spinner = this.props.spinner;       
        //spinner.setLngLat([map.getCenter().lng.toFixed(4), map.getCenter().lat.toFixed(4)]).addTo(map);
        //this.setState({
        //    isLoading: true
        //});        
        await getAllShops("shops?q=" + this.state.query)
            .then((item) => {              
                this.setState({
                    results: item.data,
                    firstLoad: false
                });              
                this.drawMap(this.state.query);

                //this.initSearcher();
                this.props.initSearcher();
                this.setState({
                    isLoading: false,
                    firstLoad: false
                });                
                this.props.initSpinner(false);              
            })
            .catch(() => {               
                this.setState({
                    isLoading: false,
                    firstLoad: false
                });              
                this.props.initSpinner(false);
            });
    }

    initSearcher() {
        const map = this.props.map;
        const geo = this.props.geo;
        const geoScan = this.props.geoScan;
        const selectMarker = this.props.selectMarker;
        const srcMarkerScan = this.props.srcMarkerScan;
        try {
            map.removeControl(geo);
        }
        catch { }
        try {
            map.removeControl(geoScan);
        }
        catch { }
        //this.props.initGeoLoc();
        const { lng, lat } = map.getCenter();
        /*this.props.initMarker(lng, lat);*/
        this.setState({
            startLng: lng,
            startLat: lat,
            //isDirectInit: (this.state.startLng === this.state.destLng && this.state.startLat === this.state.destLat)?true:false,
        });
        //this.props.initMarker([0, 0]);
        //selectMarker.remove();
        //srcMarkerScan.remove();
        this.props.initMarker([lng, lat]);
        map.setZoom(13.4);
    }

    async initDirections(name) {
        const map = this.props.map;
        const spinner = this.props.spinner;
        //const geoLocate = this.props.geoLocate;       
        const geo = this.props.geo;
        const results = this.state.results;
        //const directions = this.state.directions;
        var highlightMarkers;

        this.props.handleToggler();       
        //spinner.setLngLat([map.getCenter().lng.toFixed(4), map.getCenter().lat.toFixed(4)]).addTo(map);

        this.setState({
            isLoading: true
        });
        this.props.initSpinner(true);

        if (!this.state.isDirectInit) {
            try {
                map.removeControl(geo);
            }
            catch { }
            map.addControl(geo, 'top-right');
            this.setState({ isDirectInit: true, isDirectLocateInit: true});
        }
        if (!this.state.isDirectLocateInit) {
            //geo.trigger();
            //console.log(this.state.isDirectLocateInit);
        }

        this.setState({ isDirectMode: true, });

        ////Init Marker
        //const { lng, lat } = map.getCenter();
        ///*this.props.initMarker(lng, lat);*/
        //this.setState({
        //    startLng: lng,
        //    startLat: lat,
        //    //isDirectInit: (this.state.startLng === this.state.destLng && this.state.startLat === this.state.destLat)?true:false,
        //});
        //this.props.initMarker([lng, lat]);
        //this.props.initGeoLoc();
        //map.setZoom(13.4);

        //geoLocate.trigger();

        const jsonMarkers = JSON.stringify(results);
        var _destLng, _destLat = "";
        if (name && name.trim() !== "") {
            highlightMarkers = this.getSuggestions(jsonMarkers, name);

            if (highlightMarkers.length === 1) {               
                //console.log("* Dest Marker: " + JSON.stringify(highlightMarkers));                
            } else {
                highlightMarkers = this.getSuggestions(jsonMarkers, name + ' ' + this.state.query);
            }

            var selectLoc = JSON.parse(JSON.stringify(highlightMarkers));
          
            selectLoc.map((p) => {
                _destLng = p.Longitude;
                _destLat = p.Latitude;
                this.setState({
                    destLng: p.Longitude,
                    destLat: p.Latitude,
                    startLng: p.Longitude,
                    startLat: p.Latitude,
                });             
            });          
            await getDirection(`${_destLng},${_destLat}`, `${_destLng},${_destLat}`)
                .then((item) => {
                    this.setState({
                        directions: item.data,
                        firstLoad: false,
                        //isDirectInit: true,
                    });
                    //this.redrawMap(this.state.query);
                    this.redrawDirections(name);
                    this.setState({
                        isLoading: false,
                        firstLoad: false
                    });

                    //const map = this.props.map;
                    //const spinner = this.props.spinner;                   
                    //spinner.remove();                   
                    this.props.initSpinner(false);
                })
                .catch(() => {
                    //setErrorMessage("Unable to fetch list");
                    this.setState({
                        isLoading: false,
                        firstLoad: false
                    });
                    this.props.initSpinner(false);
                });
            
            this.props.handleDirection(true, map.getCenter().lng.toFixed(4), map.getCenter().lat.toFixed(4));
            const srcMarker = this.props.srcMarker;
            geo.on('trackuserlocationstart', (e) => {                
                this.setState({                  
                    isDirectLocateInit: true
                });
            });

            geo.on('trackuserlocationend', (e) => {
                this.setState({
                    isDirectLocateInit: false
                });
            });
            // Set an event listener that fires when a geolocate event occurs.       
            geo.on('geolocate', (e) => {                
                //const srcMarker = this.props.srcMarker;
                let lng = e.coords.longitude;
                let lat = e.coords.latitude;
                let updatedValue = {};
                updatedValue = { lng: lng, lat: lat };
                this.setState({
                    currentCoords: updatedValue,
                    currentLng: lng,
                    currentLat: lat,
                    //isDirectLocateInit: true
                });               
               
                //console.log("isDirectMode: " + this.state.isDirectMode);
                //if (this.state.isDirectMode) {
                //    const srcMarker = this.props.srcMarker;
                //    srcMarker.remove();
                //    srcMarker.setLngLat([lng, lat]);
                //}
                if (this.state.isDirectMode)
                    this.props.handleDirection(true, lng, lat);

                //this.setState({
                //    startLng: lng,
                //    startLat: lat,
                //    //isDirectInit: (this.state.startLng === this.state.destLng && this.state.startLat === this.state.destLat)?true:false,
                //});
                ////srcMarker.setLngLat([lng, lat]);
                //this.props.initMarker([lng, lat]);

                //console.log("Direction Mode: " + this.state.isDirectMode);
                //if (this.state.isDirectMode) {
                //const srcMarker = this.props.selectMarker;
                ////const srcMarker = this.props.selectMarker;
                //srcMarker.remove();
                //srcMarker.setLngLat([lng, lat]).addTo(map);
                //this.props.initMarker([lng, lat]);              
                //}
            });
            
            map.on("moveend", async () => {
                //if (!map) return;               
                this.setState({ currentZoom: map.getZoom().toFixed(1)});                            
                // Get new center coordinates             
                const { lng, lat } = map.getCenter();                
                srcMarker.setLngLat([lng, lat]);
                this.setState({
                        startLng: lng,
                        startLat: lat,
                        //isDirectInit: (this.state.startLng === this.state.destLng && this.state.startLat === this.state.destLat)?true:false,
                });
                //srcMarker.setLngLat([lng, lat]);
                //this.props.initMarker([lng, lat]);

                var from = [this.state.startLng, this.state.startLat];
                var to = [this.state.destLng, this.state.destLat];
                //TODO: get distance
                //var distance = this.getDistance(from, to);                
                //console.log(`Distance: ${distance} km`);
            });
        
            srcMarker.getElement().addEventListener('click', (e) => {               
                let isDirInit = (this.state.startLng === this.state.destLng && this.state.startLat === this.state.destLat) ? true : false;              
                if (isDirInit) {
                    return;
                }
                this.setState({ isDirectPoint: true });

                this.props.handleDirection(false, this.state.startLng, this.state.startLat);   

                getDirection(`${this.state.startLng},${this.state.startLat}`, `${this.state.destLng},${this.state.destLat}`)
                    .then((item) => {
                        this.setState({
                            directions: item.data,
                            firstLoad: false,
                        });
                        //this.redrawMap(this.state.query);
                        this.redrawDirections(name);
                        //this.setMapCenter([this.state.startLng, this.state.startLat]);
                        //this.props.handleZoom(this.state.currentZoom);
                        this.setState({
                            isLoading: false,
                            firstLoad: false
                        });

                        //const map = this.props.map;
                        //const spinner = this.props.spinner;                        
                        //spinner.remove();
                        //srcMarker.setLngLat([map.getCenter().lng.toFixed(4), map.getCenter().lat.toFixed(4)]);
                    })
                    .catch(() => {
                        //setErrorMessage("Unable to fetch list");
                        this.setState({
                            isLoading: false,
                            firstLoad: false,                           
                        });
                    });

                this.setState({                   
                    isDirectMode: false,                  
                });
            });
           
            this.setState({
                isLoading: false,
                firstLoad: false
            });
            this.props.initSpinner(false);
            //const map = this.props.map;
            //const spinner = this.props.spinner;            
            //spinner.remove();
        } else {
            // do nothing
        }
    }

    //async getDirections(name) {
    //    const map = this.props.map;
    //    const spinner = this.props.spinner;
    //    const results = this.state.results;
    //    //const directions = this.state.directions;
    //    var highlightMarkers;        

    //    spinner.setLngLat([map.getCenter().lng.toFixed(4), map.getCenter().lat.toFixed(4)]).addTo(map);

    //    this.setState({
    //        isLoading: true
    //    });
    //    const jsonMarkers = JSON.stringify(results);
    //    var destLng, destLat = "";
    //    if (name && name.trim() !== "") {
    //        highlightMarkers = this.getSuggestions(jsonMarkers, name);

    //        if (highlightMarkers.length === 1) {               
    //            //console.log("* Dest Marker: " + JSON.stringify(highlightMarkers));                
    //        } else {
    //            highlightMarkers = this.getSuggestions(jsonMarkers, name + ' ' + this.state.query);
    //        }

    //        var selectLoc = JSON.parse(JSON.stringify(highlightMarkers));
    //        selectLoc.map((p) => {
    //            destLng = p.Longitude;
    //            destLat = p.Latitude;              
    //        });

    //        await getDirection('105.8409111,21.0240492', `${destLng},${destLat}`)
    //            .then((item) => {
    //                this.setState({
    //                    directions: item.data,
    //                    firstLoad: false
    //                });
    //                //this.redrawMap(this.state.query);
    //                this.redrawDirections(name);
    //                this.setState({
    //                    isLoading: false,
    //                    firstLoad: false
    //                });

    //                //const map = this.props.map;
    //                //const spinner = this.props.spinner;
    //                //spinner.remove();
    //            })
    //            .catch(() => {
    //                //setErrorMessage("Unable to fetch list");
    //                this.setState({
    //                    isLoading: false,
    //                    firstLoad: false
    //                });
    //            });
    //        const srcMarker = this.props.marker;
    //        srcMarker.setLngLat([105.8409111, 21.0240492]).addTo(map);
           
    //    } else {
    //        // do nothing
    //    }
    //}

    genFeatureListFromGeoData(json) {
        //const results = null;
        const newFeaturesList = [];
        try {
            //TODO: Optimize          
            const markers = JSON.parse(json);
            markers.map((p) => {
                newFeaturesList.push({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [parseFloat(p.Longitude), parseFloat(p.Latitude)],
                        // coordinates: [
                        //   parseFloat(p.location.longitude),
                        //   parseFloat(p.location.latitude),
                        // ],
                    },
                    properties: {
                        id: p.ShopId,
                        name: p.ShopName,
                        displayName: p.DisplayName,
                        //code: p.shopCode,
                        address: p.Address,
                        displayAddress: p.DisplayAddress,
                        phoneNumber: p.PhoneNumber,
                        banner: p.Banner,
                        //"point-color": "253,237,57,1",
                    },
                });
                //End push
            });
        } catch (e) {
            //console.log("App Message (Data): " + e);
            return {
                type: "FeatureCollection",
                features: [],
            };
            // expected output: SyntaxError: Unexpected token o in JSON at position 1
        }

        return {
            type: "FeatureCollection",
            features: newFeaturesList,
        };
    }

    genFeatureListFromGeoList(json) {
        //const results = null;
        const newFeaturesList = [];
        try {           
            const markers = JSON.parse(json);
            this.setState({
                //results: res.body,
                firstLoad: false,
                filteredList: markers,
            });
          
            var isChangedLocation = false;
            //markers.List.map((p) => {            
            markers.map((p) => {
            ////TODO: fly to loc - recheck
                if (!isChangedLocation && !this.state.isDirectPoint) {
                    //console.log("App Message: Find by Condition.");
                    this.setMapCenter([parseFloat(p.Longitude), parseFloat(p.Latitude)]);
                    isChangedLocation = true;
                    this.setState({ isDirectPoint: false });
                }

                newFeaturesList.push({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [parseFloat(p.Longitude), parseFloat(p.Latitude)],
                        // coordinates: [
                        //   parseFloat(p.location.longitude),
                        //   parseFloat(p.location.latitude),
                        // ],
                    },
                    properties: {
                        id: p.ShopId,
                        name: p.ShopName,
                        displayName: p.DisplayName,
                        //code: p.ShopCode,
                        address: p.Address,
                        displayAddress: p.DisplayAddress,
                        phoneNumber: p.PhoneNumber,
                        banner: p.Banner,
                        //"point-color": "253,237,57,1",
                    },
                });
                //End push
            });
        } catch (e) {
            //console.log("App Message: Find All");
            return {
                type: "FeatureCollection",
                features: [],
            };
            // expected output: SyntaxError: Unexpected token o in JSON at position 1
        }

        return {
            type: "FeatureCollection",
            features: newFeaturesList,
        };
    }

    generateGeoJSON(markers) {
        return {
            type: "FeatureCollection",
            features: markers.map((p) => ({
                type: "Feature",
                properties: {
                    name: p.name,
                    hours: p.hours,
                    address: p.address,
                    "point-color": "253,237,57,1",
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        parseFloat(p.location.longitude),
                        parseFloat(p.location.latitude),
                    ],
                },
            })),
        };
    }

    getFilterCentersSuggestions(jsonMarkers, termsString) {
        const markers = JSON.parse(jsonMarkers);        
        //const terms = termsString.trim().toLowerCase().replace(",", " ").split(" ");   
        const terms = removeVieTones(termsString.trim().toLowerCase()).split(" ");        
        return markers.List.filter((p) =>
            terms.every((term) =>
                [p.ShopName, p.District, p.Province, p.Address].some((f) =>
                    removeVieTones(f).trim().toLowerCase().includes(term)
                )
            )
        );
    }

    getSuggestions(jsonMarkers, termsString) {       
        const markers = JSON.parse(jsonMarkers);
        termsString = removeSpecName(termsString.trim().toLowerCase());        
        var key = addSpecAddressRule(termsString.trim().toLowerCase());   
        const terms = removeVieTones(key).split(" ");
        const aggrFilterList = aggregateKeyList(terms);
        //for (let i = 0; i < aggrFilterList.length; i++) {
        //    console.log(aggrFilterList[i]);
        //}

        var data = markers.filter((p) =>
            aggrFilterList.every((term) => [
                //removeVieTones(p.ShopName).toLowerCase().trim(),
                //removeVieTones(p.ShopName).split(" "),
                //removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim(),
                removeVieTones(p.Province).toLowerCase().trim(),
                removeVieTones(p.District).toLowerCase().trim(),
                removeVieTones(p.Ward).toLowerCase().trim(),
                removeVieTones(p.Ward).toLowerCase().trim() + " " + removeVieTones(p.Province).toLowerCase().trim(),                
                removeVieTones(p.Street).toLowerCase().trim(),
                removeVieTones(p.Street).toLowerCase().trim() + " " + removeVieTones(p.Province).toLowerCase().trim(),
                removeVieTones(p.Street).toLowerCase().trim() + " " + removeVieTones(p.District).toLowerCase().trim(),
                removeVieTones(p.Street).toLowerCase().trim() + " " + removeVieTones(p.Ward).toLowerCase().trim(),
                removeVieTones(p.Address).toLowerCase().trim()
                //removeVieTones(removeSpecAddress(p.ShopAddress)).toLowerCase().trim()
            ]
                .some((f) => removeVieTones(f).toLowerCase().trim().match(term))
            ));

        //const key1 = removeVieTones(removeSpecName(key));
        //console.log(key1);
        //const terms1 = removeVieTones(key1).split(" ");
        //const aggrFilterList1 = aggregateKeyList(terms1);        

        var data2 = markers.filter((p) =>
            aggrFilterList.every((term) => [
                removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim(),
                removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim() + " " + removeVieTones(p.Province).toLowerCase().trim(),
                removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim() + " " + removeVieTones(p.District).toLowerCase().trim(),
                removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim() + " " + removeVieTones(p.Ward).toLowerCase().trim(),
                removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim() + " " + removeVieTones(p.Street).toLowerCase().trim(),
                //removeVieTones(aggregateKey(p.ShopName)).toLowerCase().trim() + " " + removeVieTones(p.Address).toLowerCase().trim()
            ]
                .some((f) => removeVieTones(f).toLowerCase().trim().includes(term))
            ));

        var data3 = markers.filter((p) =>
            aggrFilterList.every((term) => [
                //removeVieTones(p.Name).toLowerCase().trim(),                
                //removeVieTones(removeSpecAddress(p.Address)).toLowerCase().trim(),
                removeVieTones(removeSpecAddress(p.ShopAddress)).toLowerCase().trim()
            ]
                .some((f) => removeVieTones(f).toLowerCase().trim().includes(term))
            ));
        

        //var filterdShops = [];
        ////var shopsByProvince = markers.filter((p) =>
        ////    aggrFilterList.every((key) => [              
        ////        removeVieTones(p.Province).toLowerCase().trim()]
        ////        .some((item) => removeVieTones(item != null && item.trim() != "" ? item.toLowerCase().trim() : "").match(key))
        ////    ));

        ////var shopsByProvince = markers.filter((p) =>
        ////    aggrFilterList.every((key) => removeVieTones(p.Province != null && p.Province.trim() != "" ? p.Province.toLowerCase().trim() : "").toLowerCase().trim().match(key)));

        //var shopsByProvince = markers.filter((p) =>
        //    aggrFilterList.every((term) => [
        //        removeVieTones(p.Province).toLowerCase().trim()
        //    ].some((f) => removeVieTones(f).trim().toLowerCase().match(term))
        //    ));

        //console.log("Total Count: " + markers.length);
        //console.log("Province Count: " + shopsByProvince.length);
        //var sJsonProvince = JSON.stringify(shopsByProvince);
        ////console.log(sJsonProvince);
        //var oJsonProvince = JSON.parse(sJsonProvince);
        //var shopsByDistrict = markers.filter((p) =>
        //    aggrFilterList.every((term) => [
        //        removeVieTones(p.District).toLowerCase().trim()
        //    ].some((f) => removeVieTones(f).trim().toLowerCase().match(term))
        //    ));
        //console.log("District Count: " + shopsByDistrict.length);
        //var sJsonDistrict = JSON.stringify(shopsByDistrict);
        //var oJsonDistrict = JSON.parse(sJsonDistrict);
        //var shopsByWard = markers.filter((p) =>
        //    aggrFilterList.every((key) =>
        //        removeVieTones(p.Ward != null && p.Ward.trim() != "" ? p.Ward.toLowerCase().trim() : "").toLowerCase().trim().match(key)
        //    ));
        //console.log("Ward Count: " + shopsByWard.length);
        //var sJsonWard = JSON.stringify(shopsByWard);
        //var oJsonWard = JSON.parse(sJsonWard);
        //var shopsByStreet = markers.filter((p) =>
        //    aggrFilterList.every((key) =>
        //        removeVieTones(p.Street != null && p.Street.trim() != "" ? p.Street.toLowerCase().trim() : "").toLowerCase().trim().match(key)
        //    ));
        //console.log("Street Count: " + shopsByStreet.length);
        ////var shopsByProvince = markers.filter((p) =>
        ////    aggrFilterList.every((key) => [              
        ////        removeVieTones(p.Province).toLowerCase().trim()]
        ////        .some((item) => removeVieTones(item != null && item.trim() != "" ? item.toLowerCase().trim() : "") === key)
        ////    ));

        ////var shopsByDistrict = data.filter((p) =>
        ////    aggrFilterList.every((key) => [
        ////        removeVieTones(p.District).toLowerCase().trim()]
        ////        .some((item) => removeVieTones(item.trim().toLowerCase()).equals(key))
        ////    ));

        ////var shopsByWard = data.filter((p) =>
        ////    aggrFilterList.every((key) => [
        ////        removeVieTones(p.Ward).toLowerCase().trim()]
        ////        .some((item) => removeVieTones(item.trim().toLowerCase()).equals(key))
        ////    ));

        ////var shopsByStreet = data.filter((p) =>
        ////    aggrFilterList.every((key) => [
        ////        removeVieTones(p.Street).toLowerCase().trim()]
        ////        .some((item) => removeVieTones(item.trim().toLowerCase()).equals(key))
        ////    ));

        //filterdShops = filterdShops.concat(shopsByStreet, shopsByWard, shopsByDistrict, shopsByProvince );
        ////filterdShops = filterdShops.concat(shopsByProvince);

        ////const data = markers.filter((p) =>
        ////    aggrFilterList.every((term) => [
        ////        removeVieTones(p.ShopName).toLowerCase().trim(),
        ////        removeVieTones(p.Province).toLowerCase().trim(),
        ////        removeVieTones(p.District).toLowerCase().trim(),
        ////        removeVieTones(p.Ward).toLowerCase().trim(),
        ////        removeVieTones(p.Street).toLowerCase().trim(),
        ////        //removeVieTones(removeSpecAddress(p.Address)).toLowerCase().trim()
        ////    ]
        ////        .some((f) => removeVieTones(f.trim().toLowerCase()).equals(term))
        ////    ));

        ////const data = markers.filter((p) =>
        ////    aggrFilterList.every((term) => [
        ////        removeVieTones(p.Province).toLowerCase().trim()
        ////    ]
        ////        .some((f) => removeVieTones(f.trim().toLowerCase()).includes(term))
        ////    ));

        ////const data = markers;

        ////const dataByWard = markers.filter((p) =>
        ////    aggrFilterList.every((term) => [ removeVieTones(p.Ward).toLowerCase().trim()]
        ////        .some((f) => removeVieTones(f.trim().toLowerCase()).equals(term))
        ////    ));

        data = data.concat(data2).concat(data3);
        //data = data3.concat(data2).concat(data);
        data = data.filter(onlyUnique);
        return data;
        //return sortData(data, "Province", "asc");
        //return sortData(filterdShops, "Province", "asc");
        //return filterdShops;
    }

    initializeMap() {
        const map = this.props.map;
        //Populate Data Source
        const textFeatureList = [];
        textFeatureList.push(
            {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [parseFloat(114.4250), parseFloat(15.4862)]
                },
                properties: {
                    t: "BIỂN ĐÔNG (Eastern Sea)", //Text you want to display on Map
                    s: 14,
                    //"text-color": "253,237,57,1",
                }
            },
            {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [parseFloat(112.8046), parseFloat(16.6072)]
                },
                properties: {
                    t: "Quần đảo Hoàng Sa (Việt Nam)", //Text you want to display on Map
                    s: 12,
                    //"text-color": "253,237,57,1",
                }
            },
            {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [parseFloat(115.7708), parseFloat(10.6287)]
                },
                properties: {
                    t: "Quần đảo Trường Sa (Việt Nam)", //Text you want to display on Map
                    s: 12,
                    //"text-color": "253,237,57,1",
                }
            }
        );

        const textFeatureCollection = {
            type: "FeatureCollection",
            features: textFeatureList
        }

        map.getSource("text-points-data").setData(textFeatureCollection);

        //textFeaturesList.push({
        //    type: "Feature",
        //    geometry: {
        //        type: "Point",
        //        coordinates: [parseFloat(p.Longitude), parseFloat(p.Latitude)], //TODO: reversion
        //        // coordinates: [
        //        //   parseFloat(p.location.longitude),
        //        //   parseFloat(p.location.latitude),
        //        // ],
        //    },
        //    properties: {
        //        id: p.ShopId,
        //        name: p.ShopName,
        //        //code: p.ShopCode,
        //        address: p.Address,
        //        //"point-color": "253,237,57,1",
        //    },
        //});
        //map.getSource("text-points-data").setData(highlightgeoJSON);

    }

    initMap(vendor, data) {
        const map = this.props.map;      
        var markers, highlightMarkers, usualMarkers, usualgeoJSON, highlightgeoJSON;
        //const jsonMarkers = JSON.stringify(results);
        const jsonMarkers = JSON.stringify(data);
        //console.log("Returned API Results: " + JSON.stringify(results));
        //markers = JSON.parse(jsonMarkers);      

        if (vendor && vendor.trim() !== "") {
            highlightMarkers = this.getFilterCentersSuggestions(jsonMarkers, vendor);         
            this.setState({
                query: vendor,
                //results: res.body,
                firstLoad: false,
                filteredList: highlightMarkers,
            });
            //console.log("Filtered Data: " + filteredData);

            // highlightMarkers = markers.List.filter(
            //   (m) =>
            //     vendor &&
            //     m?.Address.trim().toLowerCase().includes(vendor.trim().toLowerCase())
            // );
            // usualMarkers = markers.filter(
            //   (m) => m.ShopName.toLowerCase() !== vendor.toLowerCase()
            //);

            usualMarkers = markers;
            //console.log(usualMarkers);
        } else {
            usualMarkers = markers;
            //console.log(usualMarkers);
        }

        //console.log(JSON.stringify(jsonMarkers));
        usualgeoJSON = this.genFeatureListFromGeoData(jsonMarkers);
        // usualgeoJSON = this.genFeatureListFromGeoData(usualMarkers);
        const jsonFilteredMarkers = JSON.stringify(highlightMarkers);
        console.log("jsonFilteredMarkers" + JSON.stringify(highlightMarkers));
        highlightgeoJSON = this.genFeatureListFromGeoList(jsonFilteredMarkers);

        map.getSource("custom-points-data").setData(usualgeoJSON);
        // map.getSource("usual-points-data").setData(usualgeoJSON);
        map.getSource("hightlight-points-data").setData(highlightgeoJSON);
    }

    drawMap(vendor) {
        //this.setState({
        //    isLoading: true
        //});
        const map = this.props.map;
        const popup = this.props.popup;
        const marker = this.props.marker;
        //const spinner = this.props.spinner;
        const results = this.state.results;
        //const directions = this.state.directions;
        var highlightMarkers, usualgeoJSON, highlightgeoJSON;        
        //popup.remove();
        //marker.remove();
        //if (popup.isOpen()) popup.remove();

        //TODO: Optimize
        const jsonMarkers = JSON.stringify(results);
        
        //const jsonDirections = JSON.stringify(directions.routes[0].geometry);
     
        if (vendor && vendor.trim() !== "") {
            //highlightMarkers = this.getFilterCentersSuggestions(jsonMarkers, vendor);
            highlightMarkers = this.getSuggestions(jsonMarkers, vendor);            
            this.setState({
                query: vendor,
                //results: res.body,              
                firstLoad: false,
                filteredList: highlightMarkers,
            });
            //console.log("Filtered Data: " + filteredData);

            // highlightMarkers = markers.List.filter(
            //   (m) =>
            //     vendor &&
            //     m?.Address.trim().toLowerCase().includes(vendor.trim().toLowerCase())
            // );
            // usualMarkers = markers.filter(
            //   (m) => m.ShopName.toLowerCase() !== vendor.toLowerCase()
            //);

            //usualMarkers = markers;
            //console.log(usualMarkers);
        } else {
            //usualMarkers = markers;
            //console.log(usualMarkers);
            this.setState({
                //query: vendor,
                //results: res.body,              
                //firstLoad: true,
            });
        }

        usualgeoJSON = this.genFeatureListFromGeoData(jsonMarkers);
        const jsonFilteredMarkers = JSON.stringify(highlightMarkers);
        highlightgeoJSON = this.genFeatureListFromGeoList(jsonFilteredMarkers);
        //highlightgeoJSON = this.genFeatureListFromGeoData(highlightMarkers);

        map.getSource("custom-points-data").setData(usualgeoJSON);
        // map.getSource("usual-points-data").setData(usualgeoJSON);
        map.getSource("hightlight-points-data").setData(highlightgeoJSON);
        //const routeFeature = {
        //    'type': 'Feature',
        //    'properties': {},
        //    'geometry': directions.routes[0].geometry,
        //};
        //map.getSource("route-data").setData(routeFeature);

        map.getSource("route-data").setData({
            'type': 'LineString',
            'coordinates': []
        });

        // // clearing layers
        // if (map.getLayer("shops")) {
        //   map.removeLayer("shops");
        // }
        // if (map.getSource("shops")) {
        //   map.removeSource("shops");
        // }
        // if (map.getLayer("shops-highlight")) {
        //   map.removeLayer("shopss-highlight");
        // }
        // if (map.getSource("shops-highlight")) {
        //   map.removeSource("shops-highlight");
        // }

        // map
        //   .addSource("shops", {
        //     type: "geojson",
        //     data: usualgeoJSON,
        //   })
        //   .addLayer({
        //     id: "shops",
        //     type: "circle",
        //     interactive: true,
        //     source: "trucks",
        //     paint: {
        //       "circle-radius": 8,
        //       "circle-color": "rgba(253,237,57,1)",
        //     },
        //   });

        // if (highlightMarkers) {
        //   map
        //     .addSource("shops-highlight", {
        //       type: "geojson",
        //       data: highlightgeoJSON,
        //     })
        //     .addLayer({
        //       id: "shops-highlight",
        //       type: "circle",
        //       interactive: true,
        //       source: "shops-highlight",
        //       paint: {
        //         "circle-radius": 8,
        //         "circle-color": "rgba(164,65,99,1)",
        //       },
        //     });
        // }

        //this.setState({
        //    isLoading: false
        //});
    }

    redrawMap(vendor) {
        const map = this.props.map;
        const popup = this.props.popup;
        const marker = this.props.marker;
        const results = this.state.results;
        const spinner = this.props.spinner;
        
        //const directions = this.state.directions;
        var highlightMarkers, usualgeoJSON, highlightgeoJSON;

        //popup.remove();
        //marker.remove();      
        //if (popup.isOpen()) popup.remove();  
       
        
        this.setState({
            isLoading: true
        });
        this.props.initSpinner(true);

        const jsonMarkers = JSON.stringify(results);
        //const jsonDirections = JSON.stringify(directions.routes[0].geometry);
        //console.log("Directions: " + jsonDirections);
        try {
            if (vendor && vendor.trim() !== "") {
                usualgeoJSON = this.genFeatureListFromGeoData(jsonMarkers);
                //highlightMarkers = this.getFilterCentersSuggestions(jsonMarkers, vendor);
                highlightMarkers = this.getSuggestions(jsonMarkers, vendor);
                //console.log("highlightMarkers Count: " + highlightMarkers.length);
                //console.log("highlightMarkers: " + JSON.stringify(highlightMarkers));
                if (highlightMarkers.length === 1) {
                    this.setState({
                        query: vendor,
                        //results: res.body,              
                        firstLoad: false,
                        filteredList: highlightMarkers,
                    });
                    //usualgeoJSON = this.genFeatureListFromGeoData(jsonMarkers);
                    const jsonFilteredMarkers = JSON.stringify(highlightMarkers);
                    highlightgeoJSON = this.genFeatureListFromGeoList(jsonFilteredMarkers);
                } else {
                    highlightMarkers = this.getSuggestions(jsonMarkers, vendor + ' ' + this.state.query);
                    this.setState({
                        //query: vendor + ' ' + this.state.query,
                        //results: res.body,              
                        firstLoad: false,
                        filteredList: highlightMarkers,
                    });                    
                    const jsonFilteredMarkers = JSON.stringify(highlightMarkers);
                    highlightgeoJSON = this.genFeatureListFromGeoList(jsonFilteredMarkers);                    
                }

                //TODO: GET Long Latt dest
                //console.log("Filtered Data: " + filteredData);
                // highlightMarkers = markers.List.filter(
                //   (m) =>
                //     vendor &&
                //     m?.Address.trim().toLowerCase().includes(vendor.trim().toLowerCase())
                // );
                // usualMarkers = markers.filter(
                //   (m) => m.ShopName.toLowerCase() !== vendor.toLowerCase()
                //);

                //usualMarkers = markers;
                //console.log(usualMarkers);
            } else {
                //usualMarkers = markers;
                //console.log(usualMarkers);

                usualgeoJSON = this.genFeatureListFromGeoData(jsonMarkers);
                const jsonFilteredMarkers = JSON.stringify(highlightMarkers);
                highlightgeoJSON = this.genFeatureListFromGeoList(jsonFilteredMarkers);
                //highlightgeoJSON = this.genFeatureListFromGeoData(highlightMarkers);
            }

            map.getSource("custom-points-data").setData(usualgeoJSON);
            // map.getSource("usual-points-data").setData(usualgeoJSON);
            map.getSource("hightlight-points-data").setData(highlightgeoJSON);

            //const routeFeature = {
            //    'type': 'Feature',
            //    'properties': {},
            //    'geometry': directions.routes[0].geometry,
            //};
            map.getSource("route-data").setData({
                'type': 'LineString',
                'coordinates': []
            });

            this.setState({
                isLoading: false
            });
            this.props.initSpinner(false);
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.props.initSpinner(false);
        }

        // // clearing layers
        // if (map.getLayer("shops")) {
        //   map.removeLayer("shops");
        // }
        // if (map.getSource("shops")) {
        //   map.removeSource("shops");
        // }
        // if (map.getLayer("shops-highlight")) {
        //   map.removeLayer("shopss-highlight");
        // }
        // if (map.getSource("shops-highlight")) {
        //   map.removeSource("shops-highlight");
        // }

        // map
        //   .addSource("shops", {
        //     type: "geojson",
        //     data: usualgeoJSON,
        //   })
        //   .addLayer({
        //     id: "shops",
        //     type: "circle",
        //     interactive: true,
        //     source: "trucks",
        //     paint: {
        //       "circle-radius": 8,
        //       "circle-color": "rgba(253,237,57,1)",
        //     },
        //   });

        // if (highlightMarkers) {
        //   map
        //     .addSource("shops-highlight", {
        //       type: "geojson",
        //       data: highlightgeoJSON,
        //     })
        //     .addLayer({
        //       id: "shops-highlight",
        //       type: "circle",
        //       interactive: true,
        //       source: "shops-highlight",
        //       paint: {
        //         "circle-radius": 8,
        //         "circle-color": "rgba(164,65,99,1)",
        //       },
        //     });
        // }
    }

    redrawDirections(vendor) {
        const map = this.props.map;
        const popup = this.props.popup;
        const spinner = this.props.spinner;
        const results = this.state.results;
        const directions = this.state.directions;
        const currentZoom = this.state.currentZoom;
        var oriHighlightMarkers, highlightMarkers, usualgeoJSON, highlightgeoJSON, oriHighlightgeoJSON;
        //popup.remove();        

        this.setState({
            isLoading: true
        });
        this.props.initSpinner(true);

        const jsonMarkers = JSON.stringify(results);
        //const jsonDirections = JSON.stringify(directions.routes[0].geometry);
        //console.log("Directions: " + jsonDirections);
        try {
            if (vendor && vendor.trim() !== "") {
                usualgeoJSON = this.genFeatureListFromGeoData(jsonMarkers);               
                highlightMarkers = this.getSuggestions(jsonMarkers, vendor);
                oriHighlightMarkers = highlightMarkers;
                if (highlightMarkers.length === 1) {
                    this.setState({
                        query: vendor,
                        //results: res.body,              
                        firstLoad: false,
                        filteredList: highlightMarkers,
                    });
                    //usualgeoJSON = this.genFeatureListFromGeoData(jsonMarkers);
                    const jsonFilteredMarkers = JSON.stringify(highlightMarkers);
                    highlightgeoJSON = this.genFeatureListFromGeoList(jsonFilteredMarkers);
                } else {
                    highlightMarkers = this.getSuggestions(jsonMarkers, vendor + ' ' + this.state.query);
                    this.setState({
                        //query: vendor + ' ' + this.state.query,
                        //results: res.body,              
                        firstLoad: false,
                        filteredList: highlightMarkers,
                    });
                    const jsonFilteredMarkers = JSON.stringify(highlightMarkers);
                    highlightgeoJSON = this.genFeatureListFromGeoList(jsonFilteredMarkers);
                }

                const jsonOriFilteredMarkers = JSON.stringify(oriHighlightMarkers);
                oriHighlightgeoJSON = this.genFeatureListFromGeoList(jsonOriFilteredMarkers);
               
                //console.log("Filtered Data: " + filteredData);
                // highlightMarkers = markers.List.filter(
                //   (m) =>
                //     vendor &&
                //     m?.Address.trim().toLowerCase().includes(vendor.trim().toLowerCase())
                // );
                // usualMarkers = markers.filter(
                //   (m) => m.ShopName.toLowerCase() !== vendor.toLowerCase()
                //);

                //usualMarkers = markers;
                //console.log(usualMarkers);
            } else {
                //usualMarkers = markers;
                //console.log(usualMarkers);

                usualgeoJSON = this.genFeatureListFromGeoData(jsonMarkers);
                const jsonFilteredMarkers = JSON.stringify(highlightMarkers);
                highlightgeoJSON = this.genFeatureListFromGeoList(jsonFilteredMarkers);
                oriHighlightgeoJSON = highlightgeoJSON;
                //highlightgeoJSON = this.genFeatureListFromGeoData(highlightMarkers);
            }

            map.setZoom(currentZoom);          

            map.getSource("custom-points-data").setData(usualgeoJSON);
            // map.getSource("usual-points-data").setData(usualgeoJSON);
            map.getSource("hightlight-points-data").setData(highlightgeoJSON);

            const routeFeature = {
                'type': 'Feature',
                'properties': {},
                'geometry': directions.routes[0].geometry,
            };
            
            map.getSource("route-data").setData(routeFeature);

            var from = [this.state.startLng, this.state.startLat];
            var to = [this.state.destLng, this.state.destLat];
            //TODO: get distance
            //var distance = this.getDistance(from, to);
            //console.log(`Distance: ${distance} km`);

            this.setState({
                isLoading: false
            });
            this.props.initSpinner(false);
        } catch (e) {
            this.setState({
                isLoading: false
            });
            this.props.initSpinner(false);
        }

        // // clearing layers
        // if (map.getLayer("shops")) {
        //   map.removeLayer("shops");
        // }
        // if (map.getSource("shops")) {
        //   map.removeSource("shops");
        // }
        // if (map.getLayer("shops-highlight")) {
        //   map.removeLayer("shopss-highlight");
        // }
        // if (map.getSource("shops-highlight")) {
        //   map.removeSource("shops-highlight");
        // }

        // map
        //   .addSource("shops", {
        //     type: "geojson",
        //     data: usualgeoJSON,
        //   })
        //   .addLayer({
        //     id: "shops",
        //     type: "circle",
        //     interactive: true,
        //     source: "trucks",
        //     paint: {
        //       "circle-radius": 8,
        //       "circle-color": "rgba(253,237,57,1)",
        //     },
        //   });

        // if (highlightMarkers) {
        //   map
        //     .addSource("shops-highlight", {
        //       type: "geojson",
        //       data: highlightgeoJSON,
        //     })
        //     .addLayer({
        //       id: "shops-highlight",
        //       type: "circle",
        //       interactive: true,
        //       source: "shops-highlight",
        //       paint: {
        //         "circle-radius": 8,
        //         "circle-color": "rgba(164,65,99,1)",
        //       },
        //     });
        // }
    }

    plotOnMap(vendor) {
        const map = this.props.map;
        const results = this.state.results;
        const markers = [].concat.apply(
            [],
            results.trucks.map((t) =>
                t.branches.map((b) => ({
                    location: b.location,
                    name: t.name,
                    schedule: b.schedule,
                    hours: b.hours,
                    address: b.address,
                }))
            )
        );

        var highlightMarkers, usualMarkers, usualgeoJSON, highlightgeoJSON;

        if (vendor) {
            highlightMarkers = markers.filter(
                (m) => m.name.toLowerCase() === vendor.toLowerCase()
            );
            usualMarkers = markers.filter(
                (m) => m.name.toLowerCase() !== vendor.toLowerCase()
            );
        } else {
            usualMarkers = markers;
        }

        usualgeoJSON = this.generateGeoJSON(usualMarkers);
        if (highlightMarkers) {
            highlightgeoJSON = this.generateGeoJSON(highlightMarkers);
        }

        // clearing layers
        if (map.getLayer("trucks")) {
            map.removeLayer("trucks");
        }
        if (map.getSource("trucks")) {
            map.removeSource("trucks");
        }
        if (map.getLayer("trucks-highlight")) {
            map.removeLayer("trucks-highlight");
        }
        if (map.getSource("trucks-highlight")) {
            map.removeSource("trucks-highlight");
        }

        map
            .addSource("trucks", {
                type: "geojson",
                data: usualgeoJSON,
            })
            .addLayer({
                id: "trucks",
                type: "circle",
                interactive: true,
                source: "trucks",
                paint: {
                    "circle-radius": 8,
                    "circle-color": "rgba(253,237,57,1)",
                },
            });

        if (highlightMarkers) {
            map
                .addSource("trucks-highlight", {
                    type: "geojson",
                    data: highlightgeoJSON,
                })
                .addLayer({
                    id: "trucks-highlight",
                    type: "circle",
                    interactive: true,
                    source: "trucks-highlight",
                    paint: {
                        "circle-radius": 8,
                        "circle-color": "rgba(164,65,99,1)",
                    },
                });
        }
    }

    setMapCenter(coords) {
        const map = this.props.map;
        if (map) {
            map.setCenter(coords);
            //map.setZoom(11.5);
        }
    }

    getDistance(fromLngLat, toLngLat) {
        var from = turf.point(fromLngLat);
        var to = turf.point(toLngLat);
        var options = { units: 'kilometers' };
        var distance = turf.distance(from, to, options);        
        return distance.toFixed(2);
    }

    makeRadius(lngLatArray, radiusInMeters) {
        var point = turf.point(lngLatArray);
        var options = { units: 'meters' };
        var buffered = turf.buffer(point, radiusInMeters, options);
        return buffered;
    }

    spatialJoin(sourceGeoJSON, filterFeature) {
        // Loop through all the features in the source geojson and return the ones that 
        // are inside the filter feature (buffered radius) and are confirmed landing sites        
        var joined = sourceGeoJSON.features.filter(function (feature) {
            //return turf.booleanPointInPolygon(feature, filterFeature) && feature.properties.isConfirmed === 'yes';
            //console.log(feature.properties.displayName);
            return turf.booleanPointInPolygon(feature, filterFeature);
        });
        return joined;
    }

    //useGAnalyticsEventTracker(title) {
    //    const eventTracker = useAnalyticsEventTracker(title);
    //    return eventTracker;
    //}

    handleSearch(e) {
        e.preventDefault();
        this.setState({
            isLoading: true
        });

        const map = this.props.map;       
        const geo = this.props.geo;
        const geoScan = this.props.geoScan;        
        try {
            map.removeControl(geo);
        }
        catch { }
        try {
            map.removeControl(geoScan);
        }
        catch { }        
       
        this.props.initSpinner(true);        
        this.props.handleDirection(false, 0, 0);
        
        this.setState({
            isDirectMode: false,
            isDirectPoint: false,
        });

        this.fetchShops();
       
        const gaEventTracker = this.props.useGAEventTracker('Map');
        gaEventTracker('Shop Search');
    }

    handleClickedItem(name) {
        this.setState({
            isLoading: true
        });

        const map = this.props.map;
        const geo = this.props.geo;
        try {
            map.removeControl(geo);
        }
        catch { }

        this.props.initSpinner(true);
        this.props.handleDirection(false, 0, 0);

        this.setState({
            isDirectMode: false,
            isDirectPoint: false,
        });

        this.redrawMap(name);

        const gaEventTracker = this.props.useGAEventTracker('Map');
        //gaEventTracker(`Shop Selection [${name}]`);
        gaEventTracker(`Shop Selection`);
    }

    onChange(e) {
        this.setState({ query: e.target.value });
        if (e.target.value.trim() === "")
            this.setState({ filteredList: [] });
    }

    

    handleDirections(name) {
        this.initDirections(name);
        const gaEventTracker = this.props.useGAEventTracker('Map');        
        //console.log(window.screen.width <= 500);
        if (window.screen.width <= 500)
            this.handleListToggler();
        //gaEventTracker(`Shop [${name}]`);
        gaEventTracker(`Shop Direction`);      //this.plotOnMap(name);
    }

    handleCalls(type, name) {
        const gaEventTracker = this.props.useGAEventTracker('Map');
        //gaEventTracker(`${type} [${name}]`);
        gaEventTracker(`${type}`);
    }

    handleListToggler() {
        if (this.state.isExpanded) {
            this.setState({isExpanded: false });
            //localStorage.setItem('is-sidebar-collapsed', true);
            return;
        }
        this.setState({isExpanded : true });
        //localStorage.removeItem('is-sidebar-collapsed');        
    }

    componentDidMount() { 
        //this.handleSearch();
    }

    render() {
        const hint = "Shop, P/Xã, Q/Huyện, T/Thành";
        const _isExpanded = this.state.isExpanded;

        //if (this.state.firstLoad) {
        //    return (
        //        <div className="custom-ctrl">
        //            <div id="results-area">
        //                <div className={_isExpanded ? "result-area-expanded" : "results-area-collapsed"}><Intro /></div>
        //            </div>
        //        </div>
        //    );
        //}

        //const query = this.state.query;
        // const resultsCount = this.state.results.hits || 0;
        // const locationsCount = this.state.results.locations || 0;
        // //const results = this.state.results.trucks || [];
        // const results = this.state.results.List || [];

        const filteredMarkers = this.state.filteredList;
        //console.log(filteredMarkers);
        const resultsCount = filteredMarkers.length || 0;       
        const results = filteredMarkers || [];        
        const isLoading = this.state.isLoading;
        const isFirstLoad = this.state.firstLoad;
        const keyword = this.state.query;
        //console.log(results);

        const renderedResults = results.map((item, i) => (
            <Shop key={i} data={item} handleClickedItem={this.handleClickedItem} handleDirections={this.handleDirections} handleCalls={this.handleCalls}/>
        ));

        return (
            <div>
                <div className="viewModeContainer">
                    <img src={_isExpanded ? mapViewMode : listViewMode} className="viewMode" alt="View Mode" width="90px" onClick={this.handleListToggler} />
                </div>
                <div className="custom-ctrl">                
                {/*<img src={_isExpanded? mapViewMode: listViewMode } className="viewMode" alt="View Mode" width="90px" onClick={this.handleListToggler} />*/}
                <div className="text-container">
                    <div className="custom-ctrl-block">
                        <span className="text-container">
                            <form onSubmit={this.handleSearch}>                              
                                    <input className="searchTextbox"
                                    type="search"
                                    value={this.state.query}
                                    onChange={this.onChange}
                                    placeholder={hint} />                            
                                    <img src={searchBlue} alt="Search" border="0" className="iconSearchImg" onClick={this.handleSearch}/>                              
                            </form>
                        </span>
                    </div>
                </div>
                

                {(resultsCount > 0 && keyword.trim() !== "") ? (
                    <div className={_isExpanded ? "result-area-expanded" : "results-area-collapsed"}>
                        <List
                            sx={{
                                position: "relative",
                                overflow: "auto",
                                width: "100%",
                                maxWidth: 350,
                                maxHeight: 450,
                                marginTop: 0.5,
                                marginBottom: 0,
                                padding: 0,
                                "& ul": { padding: 0 },
                            }}
                        >
                            {renderedResults}
                            {/* {isLoading ? <LoadingSpinner /> : renderedResults}*/}
                        </List>
                    </div>
                ) :
                    (<div>
                        {
                            isFirstLoad ? (
                            <div id="results-area">
                                <div className={_isExpanded ? "result-area-expanded" : "results-area-collapsed"}><Intro /></div>
                            </div>
                            ) : (
                                <div id="results-area">
                                    <MessageInfo msg1="Hiện không có Shop nào trong danh sách tìm kiếm." msg2="Xin vui lòng thử lại!" />
                                    {/*{isLoading ? <LoadingSpinner /> : <MessageInfo msg1="Hiện không có Shop nào trong danh sách tìm kiếm." msg2="Xin vui lòng thử lại!" />}*/}
                               
                                </div>
                            )
                        }
                    </div>)
            }
                </div>
            </div>
        );
    }
}

export default Searcher;
